import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { Observable, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { DEFAULT_NRC_CITIZEN_STATUS, DEFAULT_NRC_REGION_NO_LIST } from 'src/app/enumeration/nrc';
import { UserOccupations } from 'src/app/enumeration/occupations';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { DEFAULT_STATE_LIST } from 'src/app/enumeration/state-list';
import { CustomerApiService } from 'src/app/service/customer/customer-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';
declare var $: any

@Component({
  selector: 'app-customer-info',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    DragDropModule
  ],
  templateUrl: './customer-info.component.html',
  styleUrl: './customer-info.component.css'
})
export class CustomerInfoComponent {

  login_role: any = UserLoginRole.SUPER_ADMIN;
  haveAccess: any;
  showReasonbtn: any;
  customerdata: any = '';
  profilePhoto: any;
  unverifiedNote: any = '';
  unverifiedBy: any = '';
  topupPhones: any;
  imgSrc: any;
  userProfile: any;
  updateCustomerInfoForm: FormGroup
  isUploading: boolean = false
  userOccupations = UserOccupations
  stateList = DEFAULT_STATE_LIST.map(state => state.mmName)
  stateENGList = DEFAULT_STATE_LIST.map(state => state.engName)
  nrcStatusList = DEFAULT_NRC_CITIZEN_STATUS
  nrcCodeList = DEFAULT_NRC_REGION_NO_LIST
  townShipList: any = []
  nrcCityNameList: any = []
  kbzPayAccount: any = {}
  ayaPayAccount: any = {}
  wavePayAccount: any = {}

  checkboxes = [
    { label: 'Profile Name', value: false },
    { label: 'Profile Photo', value: false },
    { label: 'NRC Front Photo', value: false },
    { label: 'NRC Back Photo', value: false },
    { label: 'NRC Number', value: false },
  ];

  checkboxToBodyMapping: Record<string, string> = {
    'Profile Name': 'profileName',
    'Profile Photo': 'profilePhoto',
    'NRC Front Photo': 'nrcFrontPhoto',
    'NRC Back Photo': 'nrcBackPhoto',
    'NRC Number': 'nrcNumber',
  };

  loginphone: any;
  update: boolean = false;
  isNRCVerifyAccess: any = false

  valueInfoVerified: any = false
  isENG = false
  custeomerType: any = 'CUSTOMER'
  infoRemark: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api_service: CustomerApiService,
    private santizer: DomSanitizer,
    private checkAccessRoles: CheckRolesAccessService,
    private fb: FormBuilder
  ) {
    this.updateCustomerInfoForm = fb.group({
      name: ['', Validators.required],
      profile: [''],
      viberPhone: [''],
      email: [''],
      occupation: [''],
      nrc: [''],
      companyRegisterNo: [''],
      address1: [''],
      address2: [''],
      address3: [''],
      city: [''],
      township: [''],
      divisionState: [''],
    });

  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.loginphone = String(routeParams.get('phone'));

    this.haveAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.ADMIN])
    this.isNRCVerifyAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN])

    this.getCustomerData();

  }

  getCustomerData() {
    this.api_service.getCustomerInfo(this.loginphone).subscribe({
      next: (v) => {
        this.setInitCustomerData(v)
      },
      error: (e) => {
        if (e) {
          alert(e);
          this.router.navigate(['nav/customer-list'])
        }
      },
      complete: () => {
        this.getDownloadUserProfile()
      }
    });
  }

  setInitCustomerData(v: any) {
    this.customerdata = v
    //this.topupPhones = v.billTopUpPhoneLst;
    this.topupPhones = v.paymentProviderInfoRespLst
      .filter((p: { paymentProvider: string; }) => p.paymentProvider === "PHONE_BILL_TOPUP")
      .map((p: { paymentPhone: any; }) => p.paymentPhone);

    this.kbzPayAccount = v.paymentProviderInfoRespLst.find((p: { paymentProvider: string; }) => p.paymentProvider === "KBZPAY") || {}
    this.ayaPayAccount = v.paymentProviderInfoRespLst.find((p: { paymentProvider: string; }) => p.paymentProvider === "AYAPAY") || {}
    this.wavePayAccount = v.paymentProviderInfoRespLst.find((p: { paymentProvider: string; }) => p.paymentProvider === "WAVEPAY") || {}

    this.showReasonbtn = !!v.nrcVerifiedBy;
    this.valueInfoVerified = !!v.upgVerifiedBy;
    this.update = !!v.unverifiedReasonNote;
  }

  getDownloadUserProfile() {
    this.api_service.getUserProfileDownload(this.loginphone).subscribe({
      next: (v) => {
        this.getUserProfile(v)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
      },
    });
  }

  getUserProfile(data: any) {
    const blob = new Blob([data], { type: 'image/jpg' });
    var url = URL.createObjectURL(blob);
    this.userProfile = this.santizer.bypassSecurityTrustUrl(url);
  }

  UnverifyNrc() {
    this.api_service.getUnVerifyNrc(this.loginphone).subscribe({
      next: (v) => {
        this.customerdata.unverifyNrc = v;
      },
      error: (e) => {
        if (e) {
          alert('Unverify Failed!!')
        }
      },
      complete: () => {
        this.getCustomerData()
      },
    });
  }

  getUnVerifyReasonInfo() {
    this.api_service.getCustomerUnverifiedReason(this.loginphone).subscribe({
      next: (v) => {
        this.setData(v)
        this.update = v.unverifiedBy.length > 0;
      },
      error: (e) => {
        if (e) {
          if (e.contain('The customer account phone does not have unverified customer reason!')) {
            this.update = false;
          } else {
            alert(e);
          }
        }
      },
      complete: () => {

      }
    });
  }

  setData(data: any) {
    this.checkboxes = [
      { label: 'Profile Name', value: Boolean(data.profileName) },
      { label: 'Profile Photo', value: Boolean(data.profilePhoto) },
      { label: 'NRC Front Photo', value: Boolean(data.nrcFrontPhoto) },
      { label: 'NRC Back Photo', value: Boolean(data.nrcBackPhoto) },
      { label: 'NRC Number', value: Boolean(data.nrcNumber) },
    ];
    this.unverifiedNote = data.unverifiedNote;
    this.unverifiedBy = data.unverifiedBy;
  }

  submitUnverifyReason() {

    const body: any = {};
    this.checkboxes.forEach(checkbox => {
      const bodyPropertyName = this.checkboxToBodyMapping[checkbox.label];
      if (bodyPropertyName) {
        body[bodyPropertyName] = this.convertBooleanToStringUpperCase(checkbox.value);
      }
    });

    body.unverifiedNote = this.unverifiedNote;
    body.unverifiedBy = this.unverifiedBy;

    if (this.update) {
      this.api_service.getUpdateUnverifyReason(this.loginphone, body)
        .subscribe({
          next: (v) => {
            alert('Success');
          },
          error: (e) => {
            if (e) {
              alert(e)
            }
          },
          complete: () => {
            $('#showUnVerifyReason').modal('hide');
            this.getUnVerifyReasonInfo()
          },
        });
    } else {
      this.api_service.getCreateUnverifyReason(this.loginphone, body)
        .subscribe({
          next: (v) => {
            alert('Success');
          },
          error: (e) => {
            if (e) {
              alert(e)
            }
          },
          complete: () => {
            $('#showUnVerifyReason').modal('hide');
            this.getUnVerifyReasonInfo()
          },
        });
    }
  }

  convertBooleanToStringUpperCase(value: boolean): string {
    return value.toString().toUpperCase();
  }

  updateCustomerInfo() {
    this.updateCustomerInfoForm.patchValue({
      name: this.customerdata?.name || ' ',
      profile: this.customerdata?.profile || ' ',
      viberPhone: this.customerdata?.viberPhone || ' ',
      email: this.customerdata?.email || ' ',
      occupation: this.customerdata?.occupation || ' ',
      nrc: this.customerdata?.nrc || ' ',
      companyRegisterNo: this.customerdata?.companyRegisterNo || ' ',
      address1: this.customerdata?.address1 || ' ',
      address2: this.customerdata?.address2 || ' ',
      address3: this.customerdata?.address3 || ' ',
      city: this.customerdata?.city || ' ',
      township: this.customerdata?.township || ' ',
      divisionState: this.customerdata?.divisionState || ' ',
    });
    this.isENG = DEFAULT_STATE_LIST.some(state => state.engName === this.customerdata.divisionState)
    this.getTownShipList(this.customerdata?.divisionState)
  }

  onStateChange(event: any): void {
    const selectedState = event.target.value;
    this.updateCustomerInfoForm.get('township')?.setValue(' ');
    this.getTownShipList(selectedState)
  }

  onNrcStateCodeChange(event: any): void {
    const selected = event.target.value;
    this.getNRCStateCodeNameList(selected);
    this.updateNrcPart(0, selected);
  }

  onNrcTownShipChange(event: any): void {
    const selected = event.target.value;
    const selectEng = selected.split('(')[0];
    this.updateNrcPart(1, selectEng);
  }

  onNrcCitizenChange(event: any): void {
    const selected = event.target.value;
    this.updateNrcPart(2, selected);
  }

  onNrcNoChange(event: any): void {
    const selected = event.target.value;
    if (selected.length > 0) {
      this.updateNrcPart(3, selected);
    }
  }

  private updateNrcPart(partIndex: number, selectedValue: string): void {
    const a = this.updateCustomerInfoForm.get('nrc')?.value;
    console.log(a);

    const extractedValues = this.extractSubstrings(a);

    // Update the specified part based on the selected value
    extractedValues[partIndex] = selectedValue;

    // Join the updated values to form the new NRC
    const updatedNrc = extractedValues.join('');
    this.updateCustomerInfoForm.get('nrc')?.setValue(updatedNrc);
  }

  extractSubstrings(str: string): string[] {
    const substrings = [];

    // Extracting values based on the provided format
    const regex = /([^/]+\/)([^()]+)(\([^)]+\))(\d+)/;
    const matches = str.match(regex);

    if (matches) {
      // Index 0 is the full match, start from index 1 for captured groups
      substrings.push(matches[1], matches[2], matches[3], matches[4]);
    }

    return substrings;
  }

  getTownShipList(mmName: any) {
    let engName = mmName
    if (!this.isENG) {
      engName = DEFAULT_STATE_LIST.find((state) => state.mmName === mmName)?.engName
    }
    if (engName) {
      this.api_service.getTownShipList(engName)
        .subscribe({
          next: (v) => {
            this.townShipList = v
          },
          error: (e) => {
            if (e) {
              alert(e)
            }
          },
          complete: () => {

          },
        });
    }
  }

  getNRCStateCodeNameList(code: any) {

    const int = parseInt(code, 10)

    this.api_service.getNRCStateCodeNameList(int)
      .subscribe({
        next: (v) => {
          this.nrcCityNameList = v.townshipCodes
        },
        error: (e) => {
          if (e) {
            alert(e)
          }
        },
        complete: () => {

        },
      });
  }

  searchList = (text$: Observable<string>): Observable<string[]> => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.townShipList.filter((item: string) => item.toLowerCase().startsWith(term.toLowerCase()))
      )
    );
  }

  updateCustomerInfoBy() {
    this.api_service.getUpdateCustomerInfoByAdmin(this.loginphone, this.updateCustomerInfoForm.value)
      .subscribe({
        next: (v) => {
          alert('Success');
        },
        error: (e) => {
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          $('#updateInfoModel').modal('hide');
          this.getCustomerData()
        },
      });
  }

  UpdateRedeemLimit() {
    let inputvalue = prompt('Enter new daily redeem limit');
    let bar = confirm('Confirm: ' + inputvalue);
    if (bar == true) {
      this.api_service.getUpdateRedeemLimitService(this.loginphone, String(inputvalue)).subscribe({
        next: (v) => {
          alert('Success')
        },
        error: (e) => {
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getCustomerData()
        },
      });
    }
  }

  goToQRClaimPage() {
    this.router.navigate(['nav/qr-code-history', this.loginphone]);
  }

  goToExChangePage() {
    this.router.navigate(['nav/customer-redeem-list', this.loginphone]);
  }

  CustomerLoginEnableDisable(type: any) {
    if (confirm('Update customer access')) {
      this.api_service.getCustomerEnableDisableService(type, this.loginphone).subscribe({
        next: (v) => {
          alert("Success")
        },
        error: (e) => {
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getCustomerData()
        },
      });
    }
  }

  showNrc(type: any) {
    this.api_service.getDownloadNrcPhoto(this.loginphone, type).subscribe({
      next: (v) => {
        this.showFile(v, type)
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      },
    });
  }

  showFile(data: any, type: any) {
    const blob = new Blob([data], { type: 'image/jpg' })
    var url = URL.createObjectURL(blob);
    this.imgSrc = this.santizer.bypassSecurityTrustUrl(url);
  }

  verifyNrc() {
    this.api_service.getVerifyNrc(this.loginphone).subscribe({
      next: (v) => {
        this.customerdata.verifyNrc = v;
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.getCustomerData()
        $('#verifyNrcConfirm').modal('hide');
      },
    });
  }

  verifiedInfoBy() {
      this.api_service.getCustomerInfoVerifiedBy(this.loginphone, !this.valueInfoVerified, this.custeomerType, this.infoRemark).subscribe({
        next: (v) => {
          alert("Success")
          this.valueInfoVerified = !this.valueInfoVerified
        },
        error: (e) => {
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          $('#showInfoVerifyDialog').modal('hide');
          this.getCustomerData()
        },
      });
  }

  goBack() {
    window.history.back();
  }
}
