<div class="text-left mt-2">
  <button class="btn btn-primary" (click)="goBack()">Back</button>
  &nbsp;
  <button type="button" class="btn btn-outline-primary" (click)="listQrCodes('claim')">
    Normal claim
  </button>
  &nbsp;
  <button type="button" class="btn btn-outline-danger" (click)="listQrCodes('illegal')">
    Illegal claim
  </button>
</div>

<div class="alert alert-primary mt-2">{{ textalert }}</div>

<div class="table-responsive mt-2 me-2">
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Sr no</th>
        <th>Lucky draw</th>
        <th>Serial number</th>
        <th>QR code</th>
        <th>Points</th>
        <th>Enabled</th>
        <th>Customer phone</th>
        <th>Claim time</th>
        <th>Has illegal claim</th>
        <th>Illegal claim type</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let qr of qrcodes | paginate : {
        itemsPerPage: pageSize,
        currentPage: page + 1,
        totalItems: allTotal } ">
        <td>{{ qr.srNo }}</td>
        <td>{{ qr.luckyDrawName }}</td>
        <td>{{ qr.serialNumber }}</td>
        <td>{{ qr.qrCode }}</td>
        <td>{{ qr.points }}</td>
        <td>
          <i class="bi bi-check-circle-fill" style="color: green" *ngIf="qr.enabled == true"></i>
          <i class="bi bi-x-circle-fill" style="color: red" *ngIf="qr.enabled == false"></i>
        </td>
        <td>{{ qr.claimCustomerLoginPhone }}</td>
        <td>{{ qr.claimTimeStamp | dateFormat }}</td>
        <td>
          <i class="bi bi-exclamation-circle-fill" style="color: red" *ngIf="qr.hasIllegalClaims == true"></i>
          <i class="bi bi-check-circle-fill" style="color: blue" *ngIf="qr.hasIllegalClaims == false"></i>
        </td>
        <td>{{ qr.illegalClaimType }}</td>
      </tr>
    </tbody>
  </table>
</div>

<footer>
  <div class="text-center">
    <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
      (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>
</footer>

<div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
  *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
