import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { BlockPhoneType } from 'src/app/enumeration/block-phone-type';
import { PageSize } from 'src/app/enumeration/pageSize';
import { BlockPhoneApiService } from 'src/app/service/block-phone/block-phone-api.service';

@Component({
  selector: 'app-block-phone',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule
  ],
  templateUrl: './block-phone.component.html',
  styleUrl: './block-phone.component.css'
})
export class BlockPhoneComponent {

  results: any;
  pageSize: any = PageSize.BLOCKPHONE_LIST
  BPTypeKBZ = BlockPhoneType.KBZ
  BPTypeBlueOcean = BlockPhoneType.BLUE_OCEAN
  page: any = 0
  allTotal: any = 0
  loading: any = false
  type: any = BlockPhoneType.KBZ

  isUnBlockBtnLoading: boolean[] = []

  constructor(
    private api_service: BlockPhoneApiService
  ) {


  }

  ngOnInit(): void {
    this.getAllList()
  }

  getAllList() {
    this.results = []
    this.loading = true
    this.api_service.getBlockPhoneLists(this.type, this.page).subscribe({
      next: (data) => {
        if (this.type === BlockPhoneType.BLUE_OCEAN) {
          this.results = data.billTopUpBlockPhoneRespLst;
        } else {
          this.results = data.kbzpayBlockPaymentRespLst;
        }
        this.allTotal = data.allTotal;
      },
      error: (error) => {
        this.loading = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.loading = false
      },
    });
  }

  onRadioChange(event: Event) {
    const selectedRadio = event.target as HTMLInputElement;
    if (selectedRadio.checked) {
      this.type = selectedRadio.value
      this.getAllList()
    }
  }

  unBlockPhone(data: any, index: any) {
    if (this.type === BlockPhoneType.KBZ) {
      this.unBlockPhoneKBZ(data.paymentPhone, index)
    } else {
      this.unBlockPhoneBO(data.billTopUPhone, index)
    }

  }

  unBlockPhoneKBZ(phone: any, index: any) {
    let reason = prompt('Please Enter Reason');
    let bar = confirm('Are you sure!');
    if (bar == true) {
      this.isUnBlockBtnLoading[index] = true
      this.api_service.getunBlockPhoneKBZService(phone, reason).subscribe({
        next: (v) => {
          alert("Success")
        },
        error: (e) => {
          this.isUnBlockBtnLoading[index] = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getAllList()
          this.isUnBlockBtnLoading[index] = false
        }
      })
    }
  }

  unBlockPhoneBO(phone: any, index: any) {
    let reason = prompt('Please Enter Reason');
    let bar = confirm('Are you sure!');
    if (bar == true) {
      this.isUnBlockBtnLoading[index] = true
      this.api_service.getUnBlockPhoneBOService(phone, reason).subscribe({
        next: (v) => {
          alert("Success")
        },
        error: (e) => {
          this.isUnBlockBtnLoading[index] = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getAllList()
          this.isUnBlockBtnLoading[index] = false
        }
      })
    }
  }


  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllList()
    }
  }
}
