import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  constructor() { }

  private secretKey = 'rw-upg-key';
  private authKey = 'auth-key'
  private roleKey = 'role-key'
  private loginNameKey = 'login-key'

  setAuth(auth: any) {
    const encryptedToken = CryptoJS.AES.encrypt(auth, this.secretKey).toString();
    sessionStorage.setItem(this.authKey, encryptedToken);
  }

  getAuth(): string {
    const encryptedData = sessionStorage.getItem(this.authKey);
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
      const decryptedDataString = decryptedData.toString(CryptoJS.enc.Utf8);
      return decryptedDataString;
    } else {
      return ''
    }
  }

  setLoginName(login: any) {
    const encryptedToken = CryptoJS.AES.encrypt(login, this.secretKey).toString();
    sessionStorage.setItem(this.loginNameKey, encryptedToken);
  }

  getLoginName(): string {
    const encryptedData = sessionStorage.getItem(this.loginNameKey);
    const decryptedDataString = CryptoJS.AES.decrypt(encryptedData!, this.secretKey).toString(CryptoJS.enc.Utf8);
    return decryptedDataString
  }

  setRole(role: any) {
    const encryptedToken = CryptoJS.AES.encrypt(role, this.secretKey).toString();
    sessionStorage.setItem(this.roleKey, encryptedToken);
  }

  getRole(): string {
    const encryptedData = sessionStorage.getItem(this.roleKey);
    const decryptedDataString = CryptoJS.AES.decrypt(encryptedData!, this.secretKey).toString(CryptoJS.enc.Utf8);
    return decryptedDataString
  }
}
