import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageSize } from 'src/app/enumeration/pageSize';
import { CustomerApiService } from 'src/app/service/customer/customer-api.service';
import { DownloadService } from 'src/app/utils/download/download.service';

@Component({
  selector: 'app-customer-unverify-list',
  templateUrl: './customer-unverify-list.component.html',
  styleUrl: './customer-unverify-list.component.css'
})
export class CustomerUnverifyListComponent {
  
  unverifiedList: any;
  pageSize: any = PageSize.CUSTOMER_UNVERIFY_LIST
  page: any = 0
  allTotal: any = 0
  loading: any;

  constructor(
    private router: Router,
    private api_service: CustomerApiService,
    private download: DownloadService
  ) {
  }

  ngOnInit(): void {
    this.GetCustomerUnverifiedData()
  }

  GetCustomerUnverifiedData() {
    this.loading = true

    this.api_service.getCustomerUnverifiedReasonAll(this.page).subscribe({
      next: (v) => {
        this.unverifiedList = v.unverifiedCustomerInfoRespLst
        this.allTotal = v.allTotal
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
        this.loading = false
      },
      complete: () => {
        this.loading = false
      }
    });
  }

  handlePageChange(event: number): void {
    if (!this.loading) {
      this.page = event - 1;
      this.GetCustomerUnverifiedData()
    }

  }
}
