import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { QrApiService } from 'src/app/service/qr/qr-api.service';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';

@Component({
  selector: 'app-qr-illegalclaim',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DateFormatPipe
  ],
  templateUrl: './qr-illegalclaim.component.html',
  styleUrl: './qr-illegalclaim.component.css'
})
export class QrIllegalclaimComponent {

  textalert: any;
  users: any;
  qrcode: any

  constructor(
    private route: ActivatedRoute,
    private api_service: QrApiService) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.qrcode = String(routeParams.get('qrcode'));
    this.Qrcodeillegalclaim();
  }

  Qrcodeillegalclaim() {
    this.api_service.getQrcodeillegalclaimHistoryService(this.qrcode).subscribe({
      next: (v) => {
        this.textalert = 'List of illegal claim customers by QR code: ' + this.qrcode
        this.users = v.customerInfoLst;
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      },
    });
  }
}
