import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiCallServiceService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: DataStoreService
  ) {

  }

  getApiCall(url: string, responseType: string, contentType: string): Observable<any> {

    const httpOptions = this.getHeaders(responseType, contentType)

    return this.http.get<any>(environment.baseUrl + url, httpOptions)
  }

  postApiCall(url: string, body: any, responseType: string, contentType: string): Observable<any> {

    const httpOptions = this.getHeaders(responseType, contentType)

    return this.http.post<any>(environment.baseUrl + url, body, httpOptions)
  }

  refreshTokenApiCall(): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.store.getAuth(),
    });

    return this.http.post<any>(environment.baseUrl + 'api/rwadmin/refreshtoken', null, { headers, withCredentials: true })

  }

  getHeaders(isText: string, isContentType: String): Object {
    var result = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': this.store.getAuth(),
      }),
      withCredentials: true,
      responseType: 'json'
    }
    if (isContentType === ResponseTypeCheck.CONT_JSON) {
      result.headers = result.headers.set('Content-Type', 'application/json');
    }
    if (isText === ResponseTypeCheck.TEXT) {
      result.responseType = 'text'
    } else if (isText === ResponseTypeCheck.ARRAY_BUFFER) {
      result.responseType = 'arraybuffer'
    }

    return result;
  }

}
