import { DatePipe } from '@angular/common';
import { name } from './../../../../../node_modules/@leichtgewicht/ip-codec/types/index.d';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { delay } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { LuckydrawApiService } from 'src/app/service/luckydraw/luckydraw-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';
import { DownloadService } from 'src/app/utils/download/download.service';
declare var $: any


@Component({
  selector: 'app-lucky-draw-list',
  templateUrl: './lucky-draw-list.component.html',
  styleUrls: ['./lucky-draw-list.component.css']
})
export class LuckyDrawListComponent {

  auditAccess: any = true;
  partOfLuckyDrawName: any;
  startDate: any = '';
  endDate: any = '';
  selectedName: any;
  loading: any;
  newDataForm: FormGroup;
  updateDataForm: FormGroup<any>;
  downloadFileName: any;
  total: any;
  results: any;
  pageItemsSize: any = PageSize.LUCKY_DRAW_LIST
  page: any = 0
  allTotal: any = 0
  apiLoading: any = false;
  apiDownloadLoading: any = false;
  isEnableDisableAccess: any = false
  isEnableBtnLoading: boolean[] = []

  constructor(private fb: FormBuilder,
    private router: Router,
    private api_service: LuckydrawApiService,
    private checkAccessRoles: CheckRolesAccessService,
    private download: DownloadService,
    private datePipe: DatePipe) {

    this.newDataForm = this.fb.group({
      name: ['', Validators.required],
      materialName: ['', Validators.required],
      productionOrderNumber: ['', Validators.required],
      batchNumer: ['', Validators.required],
      quantity: [''],
      qrCodeStartSerialNumber: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]],
      qrCodeLastSerialNumber: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]],
    })

    this.updateDataForm = this.fb.group({
      name: ['', Validators.required],
      materialName: ['', Validators.required],
      productionOrderNumber: ['', Validators.required],
      batchNumer: ['', Validators.required],
      quantity: [''],
      qrCodeStartSerialNumber: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]],
      qrCodeLastSerialNumber: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]],
    })

  }

  ngOnInit(): void {
    this.isEnableDisableAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.LUCKY_DRAW_ADMIN])
    this.auditAccess = this.checkAccessRoles.check([UserLoginRole.AUDIT_ADMIN])

    this.listAll();
  }

  listAll() {
    this.loading = true
    this.api_service.getLuckyDrawList(this.page).subscribe({
      next: (v) => {
        this.total = v.total;
        this.allTotal = v.allTotal;
        this.results = v.luckyDrawInfoLst;
      },
      error: (error) => {
        this.loading = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.loading = false
      }
    });
  }

  enabledDisabled(type: any, name: any, index: any) {
    this.isEnableBtnLoading[index] = true
    this.api_service.getEnabledDisabled(type, name).subscribe({
      next(value) {
        alert("Success")
      },
      error: (error) => {
        this.isEnableBtnLoading[index] = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.isEnableBtnLoading[index] = false
        this.listAll()
      }
    })
  }

  addNewLucky() {
    this.apiLoading = true
    this.api_service.getAddNewLuckyDraw(this.newDataForm.value).subscribe({
      next: (v) => {
        alert("Success " + v.name + v.materialName)
      },
      error: (error) => {
        this.apiLoading = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.apiLoading = false
        this.newDataForm.reset();
        this.listAll();
      }
    });
  }

  listAllWithPartOfName(filterPage: number) {
    if (filterPage == 0) {
      this.page = 0;
    }

    var start = null
    var end = null
    if (this.startDate.length < 1 && this.endDate.length > 0) {
      alert('Please select start date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length < 1) {
      alert('Please select end date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length > 0) {
      start = this.datePipe.transform(this.startDate, 'dd/MM/yyyy');
      end = this.datePipe.transform(this.endDate, 'dd/MM/yyyy');
    }

    this.loading = true
    this.api_service.getlistLuckyDrawItemsWithPartOfNameDate(
      start, end, this.partOfLuckyDrawName, this.page).subscribe({
        next: (v) => {
          this.total = v.total;
          this.allTotal = v.allTotal;
          this.results = v.luckyDrawInfoLst;
        },
        error: (error) => {
          this.loading = false
          if (error) {
            alert(error)
          }
        },
        complete: () => {
          this.loading = false
        }
      });
  }

  ResetPage() {
    this.startDate = '';
    this.endDate = '';
    this.partOfLuckyDrawName = '';
    this.page = 0;
    this.listAll();
  }

  GetSingleView(name: string): void {
    this.apiLoading = true
    this.api_service.getLuckyDrawInfo(name).subscribe({
      next: (data) => {
        this.selectedName = data.name;
        this.updateDataForm.controls['name'].setValue(data.name);
        this.updateDataForm.controls['materialName'].setValue(data.materialName);
        this.updateDataForm.controls['productionOrderNumber'].setValue(data.productionOrderNumber);
        this.updateDataForm.controls['batchNumer'].setValue(data.batchNumer);
        this.updateDataForm.controls['qrCodeStartSerialNumber'].setValue(data.qrCodeStartSerialNumber);
        this.updateDataForm.controls['qrCodeLastSerialNumber'].setValue(data.qrCodeLastSerialNumber);
      },
      error: (error) => {
        this.apiLoading = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.apiLoading = false
      }
    });
  }

  UpdateData() {
    this.apiLoading = true
    this.api_service.getUpdateLuckyDraw(this.updateDataForm.value).subscribe({
      next: (v) => {
        alert('Success')
      },
      error: (e) => {
        this.apiLoading = false
        if (e) {
          alert(e)
        }
      }
      ,
      complete: () => {
        this.apiLoading = false
        this.listAll()
      },
    });
  }

  Activate(name: string) {
    this.apiLoading = true
    if (confirm('You cannot undo this action after activation. Are you sure?') == true) {
      this.api_service.getActivateLuckyDraw(name).subscribe({
        next: (v) => {
          alert('Success')
        },
        error: (e) => {
          this.apiLoading = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.listAll()
          this.apiLoading = false
        },
      });
    }
  }


  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.listAll();
    }
  }

  downloadFile() {

    var start = null
    var end = null
    if (this.startDate.length < 1 && this.endDate.length > 0) {
      alert('Please select start date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length < 1) {
      alert('Please select end date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length > 0) {
      start = this.datePipe.transform(this.startDate, 'dd/MM/yyyy');
      end = this.datePipe.transform(this.endDate, 'dd/MM/yyyy');
    }

    this.apiDownloadLoading = true
    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }
    this.api_service.getDownloadFile(this.downloadFileName, start, end, this.partOfLuckyDrawName).subscribe({
      next: (value) => {
        this.download.downloadFileInLocal(value, this.downloadFileName)
      },
      error: (e) => {
        this.downloadFileName = ''
        this.apiDownloadLoading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.downloadFileName = ''
        this.apiDownloadLoading = false
        $('#downloadCSVModal').modal('hide');
      },
    })
  }
}
