import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteLinksByRolesService {

  constructor() { }

  public links: any = [
    { icon: 'bi-house-door-fill', link: '/nav/home', name: 'Home' },
    { icon: 'bi-person-lines-fill', link: '/nav/admin-list', name: 'Admin' },
    { icon: 'bi-qr-code-scan', link: '/nav/qr-code-list', name: 'QR code' },
    { icon: 'bi-upload', link: '/nav/qr-upload', name: 'QR upload' },
    { icon: 'bi-star-fill', link: '/nav/lucky-draw-list', name: 'Lucky draw' },
    { icon: 'bi-shop', link: '/nav/redeem-item-list', name: 'Exchange' },
    { icon: 'bi-gift', link: '/nav/customer-redeem-list/0', name: 'Redeem' },
    { icon: 'bi-ui-checks', link: '/nav/transaction-list', name: 'Transaction' },
    { icon: 'bi-people', link: '/nav/customer-list', name: 'Customer' },
    { icon: 'bi-phone', link: '/nav/block-phone-list', name: 'Block Phone' },
  ];

  public super_links: any = [
    { icon: 'bi-house-door-fill', link: '/nav/home', name: 'Home' },
    { icon: 'bi-person-lines-fill', link: '/nav/admin-list', name: 'Admin' },
    { icon: 'bi-qr-code-scan', link: '/nav/qr-code-list', name: 'QR code' },
    { icon: 'bi-upload', link: '/nav/qr-upload', name: 'QR upload' },
    { icon: 'bi-star-fill', link: '/nav/lucky-draw-list', name: 'Lucky draw' },
    { icon: 'bi-shop', link: '/nav/redeem-item-list', name: 'Exchange' },
    { icon: 'bi-gift', link: '/nav/customer-redeem-list/0', name: 'Redeem' },
    { icon: 'bi-ui-checks', link: '/nav/transaction-list', name: 'Transaction' },
    { icon: 'bi-people', link: '/nav/customer-list', name: 'Customer' },
    { icon: 'bi-phone', link: '/nav/block-phone-list', name: 'Block Phone' },
  ];

  public admin_links: any = [
    { icon: 'bi-house-door-fill', link: '/nav/home', name: 'Home' },
    { icon: 'bi-qr-code-scan', link: '/nav/qr-code-list', name: 'QR code' },
    { icon: 'bi-shop', link: '/nav/redeem-item-list', name: 'Exchange' },
    { icon: 'bi-gift', link: '/nav/customer-redeem-list/0', name: 'Redeem' },
    { icon: 'bi-ui-checks', link: '/nav/transaction-list', name: 'Transaction' },
    { icon: 'bi-people', link: '/nav/customer-list', name: 'Customer' },
    { icon: 'bi-phone', link: '/nav/block-phone-list', name: 'Block Phone' },
  ];

  public lucky_links: any = [
    { icon: 'bi-house-door-fill', link: '/nav/home', name: 'Home' },
    { icon: 'bi-qr-code-scan', link: '/nav/qr-code-list', name: 'QR code' },
    { icon: 'bi-star-fill', link: '/nav/lucky-draw-list', name: 'Lucky draw' },
  ];

  public redeem_links: any = [
    { icon: 'bi-house-door-fill', link: '/nav/home', name: 'Home' },
    { icon: 'bi-gift', link: '/nav/customer-redeem-list/0', name: 'Redeem' },
  ];

  // audit admin
  public audit_links: any = [
    { icon: 'bi-house-door-fill', link: '/nav/home', name: 'Home' },
    { icon: 'bi-star-fill', link: '/nav/lucky-draw-list', name: 'Lucky draw' },
    { icon: 'bi-gift', link: '/nav/customer-redeem-list/0', name: 'Redeem' },
    { icon: 'bi-ui-checks', link: '/nav/transaction-list', name: 'Transaction' },
  ];
}
