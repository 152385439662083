import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { environment } from 'src/environments/environment';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';

@Injectable({
  providedIn: 'root'
})
export class HomeApiService {

  constructor(
    private http: HttpClient,
    private store: DataStoreService,
    private api: ApiCallServiceService
  ) { }

  getBoomBalance(): Observable<any> {

    const url = 'api/customer/boom/balance'

    return this.api.getApiCall(url, ResponseTypeCheck.TEXT, ResponseTypeCheck.CONT_JSON)

  }

}
