import { createReducer, on } from '@ngrx/store';
import * as TransactionActions from 'src/app/action/transaction.actions';

export interface TransactionState {
  transactionResults: any;
  transactionValidateTransaction: any;
  transactionStartDate: any;
  transactionEndDate: any;
  transactionPage: any;
}

export const initialState: TransactionState = {
  transactionResults: 'ALL',
  transactionValidateTransaction: 'ALL',
  transactionStartDate: '',
  transactionEndDate: '',
  transactionPage: 0,
};

export const transactionReducer = createReducer(
  initialState,
  on(TransactionActions.setTransactionResults, (state, { transactionResults }) => ({ ...state,  transactionResults})),
  on(TransactionActions.setTransactionValidateTransaction, (state, { transactionValidateTransaction }) => ({ ...state, transactionValidateTransaction })),
  on(TransactionActions.setTransactionStartDate, (state, { transactionStartDate }) => ({ ...state, transactionStartDate })),
  on(TransactionActions.setTransactionEndDate, (state, { transactionEndDate }) => ({ ...state, transactionEndDate })),
  on(TransactionActions.setTransactionPage, (state, { transactionPage }) => ({ ...state, transactionPage })),
);
