<div class="container-fluid">

  <header class="d-flex align-items-end justify-content-end">
    <nav class="mt-2 me-2">
      <ul class="nav gap-3">
        <li>
          <div>
            <button type="button" class="btn btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
              <span><i class="fa-solid fa-filter"></i></span>
              <span class="mx-1 fw-normal">Fliter</span>
            </button>
            <ul class="dropdown-menu">
              <div class="m-3 p-3">
                <h4>Filter Records</h4>
                <form>
                  <div class="form-group mb-2">
                    <label for="result">Result</label>
                    <select class="form-control" id="result" name="result" [(ngModel)]="results">
                      <option value="ALL">ALL</option>
                      <option value="SUCCESS">SUCCESS</option>
                      <option value="FAIL">FAIL</option>
                      <option value="PENDING">PENDING</option>
                    </select>
                  </div>

                  <div class="form-group mb-2">
                    <label for="validateTransaction">Validate Transaction</label>
                    <select class="form-control" id="validateTransaction" name="validateTransaction"
                      [(ngModel)]="validateTransaction">
                      <option value="ALL">ALL</option>
                      <option value="TRUE">TRUE</option>
                      <option value="FALSE">FALSE</option>
                    </select>
                  </div>

                  <div class="form-group mb-2">
                    <label for="startDate">Start Date</label>
                    <input type="date" class="form-control" id="startDate" name="startDate" [(ngModel)]="startDate">
                  </div>

                  <div class="form-group mb-2">
                    <label for="endDate">End Date</label>
                    <input type="date" class="form-control" id="endDate" name="endDate" [(ngModel)]="endDate">
                  </div>

                  <div class="form-group mb-2">
                    <label for="limit">Limit (Enter 1 to 100000)</label>
                    <input type="number" 
                           class="form-control" 
                           id="limit" 
                           name="limit" 
                           [(ngModel)]="limit" 
                           #limitInput="ngModel"
                           required
                           min="1"
                           max="100000"
                           [ngClass]="{'is-invalid': limitInput.invalid && limitInput.touched, 'is-valid': limitInput.valid && limitInput.touched}">
                    
                    <!-- Error messages with proper access to errors using safe navigation operator -->
                    <div *ngIf="limitInput.invalid && limitInput.touched" class="invalid-feedback">
                      <div *ngIf="limitInput.errors?.['required']">Limit is required.</div>
                      <div *ngIf="limitInput.errors?.['min']">Limit must be at least 1.</div>
                      <div *ngIf="limitInput.errors?.['max']">Limit cannot exceed 100000.</div>
                    </div>
                  </div>
            
                  <div class="pt-2">
                    <button type="button" class="btn btn-primary w-100" (click)="saveFliter()">Search</button>
                  </div>

                </form>
              </div>

            </ul>
          </div>
        </li>
        <li>
          <div>
            <button type="button" class="btn btn-success" [disabled]="apiDownloadLoading && !downloadFile"
              data-bs-toggle="modal" data-bs-target="#downloadCSVModal">
              <span *ngIf="apiDownloadLoading" role="status" aria-hidden="true">Downloading...</span>
              <span *ngIf="!apiDownloadLoading">Download CSV</span>
            </button>
          </div>
        </li>
      </ul>
    </nav>
  </header>

  <div class="table-responsive mt-2 me-2">
    <table class="table table-hover table-sm">
      <thead>
        <tr>
          <th>No</th>
          <th>Payment Phone</th>
          <th>Payment Name</th>
          <!-- <th>Error Response</th> -->
          <th>Response Code</th>
          <th>Result</th>
          <th>Code</th>
          <th>Message</th>
          <!-- <th>Nonce String</th>
              <th>Sign</th>
              <th>Sign Type</th> -->
          <th>Total Amount</th>
          <th>Currency</th>
          <!-- <th>Merchant Order ID</th>
              <th>Payment Order ID</th> -->
          <th>MM Order ID</th>
          <!-- <th>Trade Status</th> -->
          <th>Success Time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of kbzpayPaymentTransactionRespLst | paginate : {
            itemsPerPage: pageSize,
            currentPage: page + 1,
            totalItems: allTotal }">
          <td>{{ data.srNo }}</td>
          <td>{{ data.paymentPhone }}</td>
          <td>{{ data.paymentName }}</td>
          <!-- <td>{{ data.errorResponse }}</td> -->
          <td>{{ data.errorStatusCode }}</td>
          <td>{{ data.result }}</td>
          <td>{{ data.code }}</td>
          <td>{{ data.msg }}</td>
          <!-- <td>{{ data.nonceStr }}</td>
                  <td>{{ data.sign }}</td>
                  <td>{{ data.signType }}</td> -->
          <td>{{ data.totalAmount }}</td>
          <td>{{ data.transCurrency }}</td>
          <!-- <td>{{ data.merchOrderId }}</td>
                  <td>{{ data.paymentOrderId }}</td> -->
          <td>{{ data.mmOrderId }}</td>
          <!-- <td>{{ data.tradeStatus }}</td> -->
          <td>{{ data.paySuccessTime | dateFormat }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div style="text-align: center">
    <pagination-controls [maxSize]="15" previousLabel="Prev" nextLabel="Next" [responsive]="true"
      (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>

  <div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
    *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</div>

<!-- Download CSV Model -->
<div class="modal" id="downloadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Download Transaction CSV File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Enter file name" [(ngModel)]="downloadFileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="downloadFile()"
          [disabled]="!downloadFileName || apiDownloadLoading">
          <span *ngIf="apiDownloadLoading" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <span *ngIf="!apiDownloadLoading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>