import { createReducer, on } from '@ngrx/store';
import * as CustomerActions from 'src/app/action/customer.actions';

export interface CustomerState {
  customerNrcStatus: any;
  customerPage: any;
}

export const initialState: CustomerState = {
  customerNrcStatus: 'ALL',
  customerPage: 0
};

export const customerReducer = createReducer(
  initialState,
  on(CustomerActions.setCustomerNrcStatus, (state, { customerNrcStatus }) => ({ ...state, customerNrcStatus })),
  on(CustomerActions.setCustomerPage, (state, { customerPage }) => ({ ...state, customerPage })),
);
