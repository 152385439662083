import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateFormatPipe } from '../../date-format.pipe';

@NgModule({
  declarations: [DateFormatPipe],
  imports: [CommonModule],
  providers: [DatePipe, DateFormatPipe],
  exports: [DateFormatPipe]
})
export class ShareModule { }
