export const DEFAULT_NRC_REGION_NO_LIST: string[] = [
    "1/",
    "2/",
    "3/",
    "4/",
    "5/",
    "6/",
    "7/",
    "8/",
    "9/",
    "10/",
    "11/",
    "12/",
    "13/",
    "14/"
];

export const DEFAULT_NRC_CITIZEN_STATUS: string[] = [
    "(နိုင်)",
    "(ဧည့်)",
    "(သ)",
    "(ပြု)",
    "(သီ)"
];
