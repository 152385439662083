import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TransactionState } from 'src/app/reducer/transaction.reducer';

export const selectTransactionState = createFeatureSelector<TransactionState>('transaction');

export const selectResults = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.transactionResults
);

export const selectValidateTransaction = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.transactionValidateTransaction
);

export const selectStartDate = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.transactionStartDate
);

export const selectEndDate = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.transactionEndDate
);

export const selectPage = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.transactionPage
);


