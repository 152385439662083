export const RedeemStatusList: string[] = [
    "ALL",
    "CUSTOMER_REDEEM",
    "AUTO_PROCESSED",
    "REEDEMED_ADMIN_PROCESSED",
    "PROCESSED",
    // "AUTO_PROCESSED_ERROR_1",
    // "AUTO_PROCESSED_ERROR_2",
    "AUTO_PROCESSED_ERROR_3",
    "AUTO_KBZPAY_SUCCESS",
    "AUTO_PROCESSED_REFUND",
    "AUTO_KBZPAY_ERROR_NOCUSTOMER",
    "AUTO_KBZPAY_ERROR_NOREDEEMABLE",
    "AUTO_KBZPAY_ERROR_FAILED",
    "AUTO_KBZPAY_ERROR_UNKNOWN",
    "AUTO_KBZPAY_ERROR_PENDING",
    "AUTO_KBZPAY_ERROR_BLOCK",
    "AUTO_KBZPAY_REFUND"
];

export const RedeemStatusListWC: string[] = [
    "ALL",
    "CUSTOMER_REDEEM",
    "REEDEMED_ADMIN_PROCESSED",
    "AUTO_KBZPAY_SUCCESS",
    "AUTO_KBZPAY_ERROR_NOCUSTOMER",
    "AUTO_KBZPAY_ERROR_NOREDEEMABLE",
    "AUTO_KBZPAY_ERROR_FAILED",
    "AUTO_KBZPAY_ERROR_UNKNOWN",
    "AUTO_KBZPAY_ERROR_PENDING",
    "AUTO_KBZPAY_ERROR_BLOCK",
    "AUTO_KBZPAY_REFUND"
];

export const RedeemStatusListPB: string[] = [
    "ALL",
    "CUSTOMER_REDEEM",
    "AUTO_PROCESSED",
    "AUTO_PROCESSED_ERROR_3",
    "AUTO_PROCESSED_REFUND",
];

export const RedeemStatusListForRedeemAdmin: string[] = [
    "ALL",
    "CUSTOMER_REDEEM",
    "AUTO_PROCESSED",
    "REEDEMED_ADMIN_PROCESSED",
    "PROCESSED",
    "AUTO_PROCESSED_ERROR_3"
];

export const RedeemStatusListWCForRedeemAdmin: string[] = [
    "ALL",
    "CUSTOMER_REDEEM",
    "REEDEMED_ADMIN_PROCESSED"
];