import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoginApiService } from 'src/app/service/login/login-api.service';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  loginform: FormGroup;
  isLoading: any;
  isLoginError: any;
  txtLoginError: any;
  today = new Date().toLocaleString();
  apptitle: string = environment.defaultTitle;
  version = environment.version
  changePasswordDisplayStyle = 'none'
  changePasswordForm: FormGroup<any>;
  showNewPassword: any = false
  showConfirmPassword: boolean = false
  isUpdatePasswordError: any = false
  isUploading: any = false

  constructor(
    private service: LoginApiService,
    private router: Router,
    private fb: FormBuilder,
    private storeData: DataStoreService
  ) {
    this.loginform = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    })

    this.changePasswordForm = new UntypedFormGroup({
      newPassword: new UntypedFormControl('', Validators.required),
      confirmPassword: new UntypedFormControl('', Validators.required),
    });
  }

  ngOnInit(): void {

  }

  submitAdminLogin() {
    this.isLoading = true;
    var username = this.loginform.get('username')?.value.toLowerCase();
    var password = this.loginform.get('password')?.value;
    var authorization = 'Basic ' + window.btoa(username + ':' + password);

    this.service.getLogin(authorization).subscribe({
      next: (response) => {
        this.storeData.setLoginName(username)
        this.storeData.setAuth(authorization)
        this.storeData.setRole(String(response.adminRole))

        if (response.passwordChanged) {
          this.openUpdatePasswordPopup()
        } else {
          this.goToNav()
        }
      },
      error: (e) => {
        this.isLoading = false;
        this.isLoginError = true;
        this.txtLoginError = String(e);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  openUpdatePasswordPopup() {
    this.changePasswordDisplayStyle = 'block';
  }

  closePopup() {
    this.isUpdatePasswordError = false
    this.txtLoginError = ''
    this.changePasswordDisplayStyle = 'none'
  }

  submitNewPassword() {
    this.isUploading = true
    const newPass = this.changePasswordForm.get('newPassword')?.value
    const confirmPass = this.changePasswordForm.get('confirmPassword')?.value
    if (newPass !== confirmPass) {
      this.isUpdatePasswordError = true
      this.txtLoginError = 'Password does not match'
      this.isUploading = false
    } else {
      this.isUpdatePasswordError = false
      this.updatePassword(confirmPass)
    }
  }

  updatePassword(newPassword: any) {
    console.log(this.storeData.getLoginName());

    this, this.service.getUpdatePassword(this.storeData.getLoginName(), newPassword).subscribe({
      next: (value) => {
        const loginName = this.storeData.getLoginName()
        var authorization = 'Basic ' + window.btoa(loginName + ':' + newPassword);
        this.storeData.setAuth(authorization)

        alert("Success")
      },
      error: (e) => {
        if (e) {
          this.isUpdatePasswordError = true
          this.txtLoginError = e
        }
        this.isUploading = false
      },
      complete: () => {
        this.closePopup()
        this.isUpdatePasswordError = false
        this.txtLoginError = ''
        this.isUploading = false
        this.goToNav()
      },
    })
  }

  goToNav() {
    this.router.navigate(['/nav/home'])
  }
}
