import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { HomeApiService } from 'src/app/service/home/home-api.service';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  username: any;
  login_role: any;
  smsbalance: any;
  isBalanceAccess: any = false;

  constructor(
    private api_call: HomeApiService,
    private store: DataStoreService,) {

  }

  ngOnInit(): void {
    this.username = this.store.getLoginName()
    this.login_role = this.store.getRole()
    if (this.login_role === 'SUPER_ADMIN' || this.login_role == 'ADMIN') {
      this.isBalanceAccess = true
      this.callBoomBalance()
    }

  }

  callBoomBalance() {
    this.api_call.getBoomBalance().subscribe({
      next: (value) => {
        this.smsbalance = value
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      }
    })
  }
}
