import { createReducer, on } from '@ngrx/store';
import * as RedeemActions from 'src/app/action/redeem.actions';

export interface RedeemState {
  redeemType: any;
  redeemStatus: any;
  redeemStartDate: any;
  redeemEndDate: any;
  redeemPage: any;
  redeemDataList: any[];
  redeemSummaryList: any[]
}

export const initialState: RedeemState = {
  redeemType: 'ALL',
  redeemStatus: 'ALL',
  redeemStartDate: '',
  redeemEndDate: '',
  redeemPage: 0,
  redeemDataList: [],
  redeemSummaryList: []
};

export const redeemReducer = createReducer(
  initialState,
  on(RedeemActions.setRedeemType, (state, { redeemType }) => ({ ...state, redeemType })),
  on(RedeemActions.setRedeemStatus, (state, { redeemStatus }) => ({ ...state, redeemStatus })),
  on(RedeemActions.setRedeemStartDate, (state, { redeemStartDate }) => ({ ...state, redeemStartDate })),
  on(RedeemActions.setRedeemEndDate, (state, { redeemEndDate }) => ({ ...state, redeemEndDate })),
  on(RedeemActions.setRedeemPage, (state, { redeemPage }) => ({ ...state, redeemPage })),
  on(RedeemActions.setRedeemDataList, (state, { redeemDataList }) => ({ ...state, redeemDataList })),
  on(RedeemActions.setRedeemSummaryList, (state, { redeemSummaryList }) => ({ ...state, redeemSummaryList }))
);
