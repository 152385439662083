import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerApiService {

  constructor(
    private http: HttpClient,
    private store: DataStoreService,
    private api: ApiCallServiceService
  ) { }

  getCustomerList(nrcStatus: any, page: any): Observable<any> {

    const url = 'api/customer/registration/listcustomerinfo?filter=' + nrcStatus + '&page=' + page + '&pageSize=' + PageSize.CUSTOMER_LIST

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getCustomerInfo(loginphone: any): Observable<any> {

    const url = 'api/customer/registration/customerinfo?loginPhone=' + loginphone

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUserProfileDownload(loginphone: any): Observable<any> {

    const url = 'api/customer/registration/downloadprofilephoto?loginPhone=' + loginphone

    return this.api.getApiCall(url, ResponseTypeCheck.ARRAY_BUFFER, ResponseTypeCheck.CONT_JSON)

  }

  getUnVerifyNrc(loginphone: any): Observable<any> {

    const url = 'api/customer/registration/unverifynrc?loginPhone=' + loginphone

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getCustomerUnverifiedReason(loginphone: any): Observable<any> {

    const url = 'api/rwadmin/unverified/customer/reason?loginPhone=' + loginphone

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUpdateUnverifyReason(loginphone: any, body: any): Observable<any> {

    const url = 'api/rwadmin/unverified/customer/update?loginPhone=' + loginphone

    return this.api.postApiCall(url, JSON.stringify(body), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getCreateUnverifyReason(loginphone: any, body: any): Observable<any> {

    const url = 'api/rwadmin/unverified/customer/create?loginPhone=' + loginphone

    return this.api.postApiCall(url, JSON.stringify(body), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUpdateRedeemLimitService(loginphone: any, redeem: string): Observable<any> {

    const url = 'api/customer/registration/updatedailyredeemablelimit?loginPhone=' +
      loginphone + '&dailyRedeemableLimit=' + redeem

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getCustomerEnableDisableService(type: any, loginphone: any): Observable<any> {

    const url = 'api/customer/' + type + '?loginPhone=' + loginphone

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getDownloadNrcPhoto(loginphone: any, type: any): Observable<any> {

    const url = 'api/customer/registration/downloadnrc?loginPhone=' + loginphone + '&page=' + type

    return this.api.getApiCall(url, ResponseTypeCheck.ARRAY_BUFFER, ResponseTypeCheck.CONT_JSON)

  }

  getVerifyNrc(loginphone: any): Observable<any> {

    const url = 'api/customer/registration/verifynrc?loginPhone=' + loginphone

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUpdateCustomerInfoByAdmin(loginphone: any, body: any) {

    const url = 'api/customer/registration/update?loginPhone=' + loginphone

    return this.api.postApiCall(url, JSON.stringify(body), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getTownShipList(engName: string) {

    const url = 'api/customer/registration/townshipsbymmstate?state=' + engName

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getNRCStateCodeNameList(code: any) {

    const url = 'api/customer/registration/nrctownshipcodes?stateCode=' + code

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getCustomerInfoVerifiedBy(loginphone: any, type: boolean, custeomerType: any, infoRemark: any) {

    const url = 'api/customer/registration/verifyrecord?loginPhone=' + loginphone + '&verified=' + String(type) + '&customerType=' + custeomerType + '&infoRemark=' + infoRemark

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getCustomerUnverifiedReasonAll(page: any): Observable<any> {

    const url = 'api/rwadmin/unverified/customer/listreasons?status=NOT_CLEAR&page=' + page + '&pageSize=' + PageSize.CUSTOMER_UNVERIFY_LIST

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getStatusForUpdateRedeemLimit(): Observable<any> {

    const url = 'api/customer/registration/updatedailyredeemablelimit/status'

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUpdateRedeemLimitForAllCustomer(phone: any, redeemPoint: any): Observable<any> {

    const url = 'api/customer/registration/updatedailyredeemablelimit?loginPhone=' + phone + '&dailyRedeemableLimit=' + redeemPoint

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

}
