import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { NgxPaginationModule } from 'ngx-pagination';
import { setRedeemDataList, setRedeemDateType, setRedeemEndDate, setRedeemPage, setRedeemStartDate, setRedeemStatus, setRedeemSummaryList, setRedeemType } from 'src/app/action/redeem.actions';
import { PageSize } from 'src/app/enumeration/pageSize';
import { RedeemStatusList, RedeemStatusListForRedeemAdmin, RedeemStatusListPB, RedeemStatusListWC, RedeemStatusListWCForRedeemAdmin } from 'src/app/enumeration/redeem-status';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { selectDataList, selectEndDate, selectPage, selectRedeemDateType, selectRedeemStatus, selectRedeemType, selectStartDate, selectSummary } from 'src/app/selector/redeem.selectors';
import { RedeemApiService } from 'src/app/service/redeem/redeem-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';
import { DownloadService } from 'src/app/utils/download/download.service';
import { isObjectEmpty } from 'src/app/utils/function-check';
declare var $: any

@Component({
  selector: 'app-customer-redeem-list',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DateFormatPipe
  ],
  templateUrl: './customer-redeem-list.component.html',
  styleUrls: ['./customer-redeem-list.component.css']
})
export class CustomerRedeemListComponent {

  redeemType: any = 'ALL';
  redeemStatus: any = 'ALL';

  bolDownloadForRedeem: any = true;
  bolSummay: any = false;
  bolWKBZ: any = false;
  bolPBTU: any = false;

  totalRedeemedAmount: any = '0';
  totalPendingRedeemedAmount: any = '0';
  totalProcessedRedeemedAmount: any = '0';
  totalPaymentNameGroupWaveMon: any = '0';
  totalPaymentNameGroupKBZPay: any = '0';
  totalPaymentNameGroupAYA: any = '0';
  totalPaymentNameGroupPBTU: any = '0';

  results: any;
  pageSize: any = PageSize.REDEEM_LISTS;
  page: any = 0;
  allTotal: any = 0;
  total: any;
  redeemStatusOption: any

  redeemStatusOptionALL = RedeemStatusList
  redeemStatusOptionWCT = RedeemStatusListWC
  redeemStatusOptionPBT = RedeemStatusListPB

  redeemTypesOption = [
    { id: 'a', value: 'ALL', label: 'ALL' },
    { id: 'b', value: 'PHONE_BILL_TOPUP', label: 'PHONE_BILL_TOPUP' },
    { id: 'c', value: 'WALLET_CASH_TRANSFER', label: 'WALLET_CASH_TRANSFER' },
  ]

  loading: any;
  phone: any = '';
  isAccessRedeemAdmin: any = false
  isRetryAccess: any = false
  isSignItemAccess: any = false
  downloadFileName: any;
  apiDownloadLoading: any = false;
  startDate: any = '';
  endDate: any = '';
  isCustomerPage: any = false
  isPagination: any = false
  listDataResponse: any
  summaryDataResponse: any

  isRetryBtnLoading: boolean[] = []
  isBlockBtnLoading: boolean[] = []
  isSignBtnLoading: boolean[] = []

  showRetryButton: boolean = false
  showBlockButton: boolean = false
  dateType: any = 'REDEEMED_TS';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api_service: RedeemApiService,
    private datePipe: DatePipe,
    private downLoad_service: DownloadService,
    private checkAccessRoles: CheckRolesAccessService,
    private store: Store) {

  }

  ngOnInit(): void {

    this.isAccessRedeemAdmin = this.checkAccessRoles.check([UserLoginRole.REDEEM_PAYMENT_ADMIN])
    this.isRetryAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.ADMIN])
    this.isSignItemAccess = this.checkAccessRoles.check([UserLoginRole.REDEEM_PAYMENT_ADMIN])

    if (this.isAccessRedeemAdmin) {
      this.setInitRedeemStatusData()
      this.bolDownloadForRedeem = false
    }

    this.redeemStatusOption = this.redeemStatusOptionALL

    const routeParams = this.route.snapshot.paramMap
    this.phone = routeParams.get('phone')

    if (this.phone !== '0') {
      this.isCustomerPage = true
      this.listCustomers(this.phone)
    } else {
      this.getPreviousDataReloadWithAppState()
    }

  }

  getPreviousDataReloadWithAppState() {


    this.store.pipe(select(selectRedeemType)).subscribe(redeemType => {
      this.redeemType = redeemType
    });

    this.store.pipe(select(selectRedeemDateType)).subscribe(redeemDateType => {
      this.dateType = redeemDateType
    });

    this.store.pipe(select(selectStartDate)).subscribe(startDate => {
      this.startDate = startDate
    });

    this.store.pipe(select(selectEndDate)).subscribe(endDate => {
      this.endDate = endDate
    });

    this.store.pipe(select(selectPage)).subscribe(page => {
      this.page = page
    });

    this.onChange('')

    this.store.pipe(select(selectRedeemStatus)).subscribe(redeemStatus => {
      this.redeemStatus = redeemStatus
    });

    this.store.pipe(select(selectDataList)).subscribe(dataList => {
      if (!isObjectEmpty(dataList)) {
        this.listDataResponse = []
        this.listDataResponse = dataList
        this.setListDataResponse()
      }
    });

    this.store.pipe(select(selectSummary)).subscribe(summaryList => {
      if (!isObjectEmpty(summaryList)) {
        this.summaryDataResponse = []
        this.summaryDataResponse = summaryList
        this.setSummaryDataListResponse()
      }
    });

  }

  setInitRedeemStatusData() {
    this.redeemStatusOptionALL = RedeemStatusListForRedeemAdmin
    this.redeemStatusOptionWCT = RedeemStatusListWCForRedeemAdmin
  }

  listCustomers(phone: any) {
    this.loading = true
    this.listDataResponse = []

    this.api_service.getRedeemedByCustomer(phone, this.redeemStatus, this.page).subscribe({
      next: (v) => {
        this.listDataResponse = v
        this.setListDataResponse()
      },

      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

  setListDataResponse() {
    if (this.listDataResponse) {
      this.results = this.listDataResponse.Message.redeemedItemInfoRespLst
      this.total = this.listDataResponse.Message.total
      this.allTotal = this.listDataResponse.Message.allTotal

      if (this.redeemStatus === 'AUTO_PROCESSED_ERROR_3' ||
        this.redeemStatus === 'AUTO_KBZPAY_ERROR_FAILED' ||
        this.redeemStatus === 'AUTO_KBZPAY_ERROR_UNKNOWN' ||
        this.redeemStatus === 'AUTO_KBZPAY_ERROR_BLOCK' ||
        this.redeemStatus === 'AUTO_KBZPAY_ERROR_PENDING') {
        this.showRetryButton = true;
      } else {
        this.showRetryButton = false;
      }

      if (this.redeemStatus === 'AUTO_PROCESSED_ERROR_3' ||
        this.redeemStatus.includes('KBZPAY_ERROR')) {
        this.showBlockButton = true;
      } else {
        this.showBlockButton = false;
      }
    }
  }

  onChange(e: any) {
    if (!this.isCustomerPage) {
      if (this.redeemType === 'PHONE_BILL_TOPUP') {
        this.bolPBTU = true
        this.bolSummay = true
        this.bolWKBZ = false
        if (this.isAccessRedeemAdmin) {
          this.bolDownloadForRedeem = false
        }
        this.redeemStatusOption = this.redeemStatusOptionPBT
        this.resetValue()
      } else if (this.redeemType === 'WALLET_CASH_TRANSFER') {
        this.bolWKBZ = true
        this.bolSummay = true
        this.bolPBTU = false
        if (this.isAccessRedeemAdmin) {
          this.bolDownloadForRedeem = true
        }
        this.redeemStatusOption = this.redeemStatusOptionWCT
        this.resetValue()
        //this.getRedeemItem("WALLET_CASH_TRANSFER")
      } else if (this.redeemType === 'ALL') {
        this.bolSummay = false
        this.bolWKBZ = false
        this.bolPBTU = false
        if (this.isAccessRedeemAdmin) {
          this.bolDownloadForRedeem = false
        }
        this.redeemStatusOption = this.redeemStatusOptionALL
        this.resetValue()
      }
    }
  }

  resetValue() {
    this.totalRedeemedAmount = 0
    this.totalPendingRedeemedAmount = 0
    this.totalProcessedRedeemedAmount = 0
    this.totalPaymentNameGroupWaveMon = 0
    this.totalPaymentNameGroupKBZPay = 0
    this.totalPaymentNameGroupPBTU = 0
    this.totalPaymentNameGroupAYA = 0
    this.redeemStatus = "ALL"
    this.results = []
  }

  searchByCustomer() {
    this.page = 0
    this.getRedeemList(!this.isPagination)
  }

  getRedeemList(isCallSummary: boolean) {

    var start: any = null
    var end: any = null
    if (this.startDate.length < 1 && this.endDate.length > 0) {
      alert('Please select start date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length < 1) {
      alert('Please select end date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length > 0) {
      start = this.datePipe.transform(this.startDate, 'dd/MM/yyyy');
      end = this.datePipe.transform(this.endDate, 'dd/MM/yyyy');
    }

    this.loading = true
    this.listDataResponse = []

    this.api_service.getRedeemList(this.redeemType, this.redeemStatus, this.dateType, start, end, this.page).subscribe({
      next: (v) => {
        this.listDataResponse = v
        this.setListDataResponse()
      },
      error: (e) => {
        if (e) {
          alert(e)
          if (isCallSummary) {
            this.listItemsSummaryDateFiler(this.redeemType, start, end, this.page)
          } else {
            this.loading = false
          }
        }
      },
      complete: () => {
        if (isCallSummary) {
          this.listItemsSummaryDateFiler(this.redeemType, start, end, this.page)
        } else {
          this.loading = false
          this.saveNgrxStore()
        }
      }
    })

  }

  listItemsSummaryDateFiler(redeemType: any, start: any, end: any, page: any) {
    if (this.redeemType != 'ALL') {
      this.summaryDataResponse = []
      this.api_service.RedeemedItemByStatusServiceSummaryWithDateFilter(redeemType, this.dateType, start, end, page).subscribe({
        next: (v) => {
          this.summaryDataResponse = v
          this.setSummaryDataListResponse()
        },
        error: (e) => {
          this.loading = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.loading = false
          this.saveNgrxStore()
        }
      })
    } else {
      this.loading = false
    }
  }

  setSummaryDataListResponse() {
    if (this.summaryDataResponse) {
      this.totalRedeemedAmount = this.summaryDataResponse.totalRedeemedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ", ")
      this.totalProcessedRedeemedAmount = this.summaryDataResponse.totalProcessedRedeemedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.totalPendingRedeemedAmount = this.summaryDataResponse.totalPendingRedeemedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.totalPaymentNameGroupWaveMon = this.summaryDataResponse.totalPaymentNameGroupAmount['Wave Mon']?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.totalPaymentNameGroupKBZPay = this.summaryDataResponse.totalPaymentNameGroupAmount['KBZPay W']?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.totalPaymentNameGroupAYA = this.summaryDataResponse.totalPaymentNameGroupAmount['AYA Pay']?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.totalPaymentNameGroupPBTU = this.summaryDataResponse.totalPaymentNameGroupAmount['Phone Bill Top Up']?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }

  shouldRenderRetryButton(data: any): boolean {
    return (
      this.redeemStatus === 'AUTO_PROCESSED_ERROR_3' && data.redeemableStatus === 'AUTO_PROCESSED_ERROR_3' && !this.isCustomerPage && this.isAccessRedeemAdmin
      && !([1096253, 1083218, 1071351, 1055132, 1055131].includes(data.id))
    );
  }

  shouldRenderAdminRetryButton(data: any): boolean {
    const isProcessedError3 = (
      this.redeemStatus === 'AUTO_PROCESSED_ERROR_3' &&
      data.redeemableStatus === 'AUTO_PROCESSED_ERROR_3'
    );

    const isKBZPayError = (
      ['AUTO_KBZPAY_ERROR_FAILED', 'AUTO_KBZPAY_ERROR_UNKNOWN', 'AUTO_KBZPAY_ERROR_BLOCK', 'AUTO_KBZPAY_ERROR_PENDING'].includes(this.redeemStatus) &&
      ['AUTO_KBZPAY_ERROR_FAILED', 'AUTO_KBZPAY_ERROR_UNKNOWN', 'AUTO_KBZPAY_ERROR_BLOCK', 'AUTO_KBZPAY_ERROR_PENDING'].includes(data.redeemableStatus)
    );

    const isExcludedId = [1096253, 1083218, 1071351, 1055132, 1055131].includes(data.id);

    return (
      (isProcessedError3 || isKBZPayError) &&
      !this.isCustomerPage &&
      this.isRetryAccess &&
      !isExcludedId
    );
  }

  shouldRenderAdminBlockButton(data: any): boolean {
    const isProcessedError3 = (
      this.redeemStatus === 'AUTO_PROCESSED_ERROR_3' &&
      data.redeemableStatus === 'AUTO_PROCESSED_ERROR_3'
    );

    const isKBZPayError = (
      this.redeemStatus.includes('KBZPAY_ERROR') &&
      data.redeemableStatus.includes('KBZPAY_ERROR')
    );

    const isExcludedId = [1096253, 1083218, 1071351, 1055132, 1055131].includes(data.id);

    return (
      (isProcessedError3 || isKBZPayError) &&
      !this.isCustomerPage &&
      this.isRetryAccess &&
      !isExcludedId
    );
  }


  shouldRenderQueryButton(data: any): boolean {
    // return (
    //   ((this.redeemStatus === 'AUTO_KBZPAY_ERROR_PENDING' && data.redeemableStatus === 'AUTO_KBZPAY_ERROR_PENDING')) &&
    //   !this.isCustomerPage && this.isRetryAccess
    // );
    return false
  }

  retryRedeem(value: any, index: any) {
    if (value.redeemableStatus === 'AUTO_PROCESSED_ERROR_3') {
      this.retryBORedeem(value.id, index);
    } else {
      this.retryKBZRedeem(value.id, index);
    }
  }

  retryBORedeem(id: string, index: any) {
    if (confirm("Retry redeem item") == true) {
      this.isRetryBtnLoading[index] = true
      this.api_service.getRetryRedeemService(id).subscribe({
        next: (v) => {
          alert("Success")
        },
        error: (e) => {
          this.isRetryBtnLoading[index] = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getRedeemList(!this.isPagination)
          this.isRetryBtnLoading[index] = false
        }
      })
    }
  }

  retryKBZRedeem(id: string, index: any) {
    if (confirm("Retry redeem item") == true) {
      this.isRetryBtnLoading[index] = true
      this.api_service.getRetryKBZRedeemService(id).subscribe({
        next: (v) => {
          alert("Success")
        },
        error: (e) => {
          this.isRetryBtnLoading[index] = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getRedeemList(!this.isPagination)
          this.isRetryBtnLoading[index] = false
        }
      })
    }
  }

  blockRedeem(value: any, index: any) {
    if (value.redeemableStatus === 'AUTO_PROCESSED_ERROR_3') {
      this.blockBORedeem(value.id, index);
    } else {
      this.blockKBZRedeem(value.id, index);
    }
  }

  blockBORedeem(id: string, index: any) {
    let reason = prompt('Please Enter Reason');
    let bar = confirm('Are you sure!');
    if (bar == true) {
      this.isBlockBtnLoading[index] = true
      this.api_service.getBlockBORedeemService(id, reason).subscribe({
        next: (v) => {
          alert("Success")
        },
        error: (e) => {
          this.isBlockBtnLoading[index] = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getRedeemList(!this.isPagination)
          this.isBlockBtnLoading[index] = false
        }
      })
    }
  }

  blockKBZRedeem(id: string, index: any) {
    let reason = prompt('Please Enter Reason');
    let bar = confirm('Are you sure!');
    if (bar == true) {
      this.isBlockBtnLoading[index] = true
      this.api_service.getBlockKBZRedeemService(id, reason).subscribe({
        next: (v) => {
          alert("Success")
        },
        error: (e) => {
          this.isBlockBtnLoading[index] = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getRedeemList(!this.isPagination)
          this.isBlockBtnLoading[index] = false
        }
      })
    }
  }

  queryBORedeem(id: string, index: any) {
    if (confirm("Query redeem item") == true) {
      this.isRetryBtnLoading[index] = true
      this.api_service.getQueryRedeemService(id).subscribe({
        next: (v) => {
          alert("Not testing , Testing in Prod")
        },
        error: (e) => {
          this.isRetryBtnLoading[index] = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.isRetryBtnLoading[index] = false
        }
      })
    }
  }


  signItem(id: string, index: any) {
    if (confirm("Sign this redeem item") == true) {
      this.isSignBtnLoading[index] = true
      this.api_service.getSignRedeemService(id).subscribe({
        next: (v) => {
          alert("Success")
        },
        error: (e) => {
          this.isSignBtnLoading[index] = false
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getRedeemList(!this.isPagination)
          this.isSignBtnLoading[index] = false
        }
      })
    }
  }

  resetForm() {
    this.redeemType = 'ALL'
    this.redeemStatusOption = this.redeemStatusOptionALL
    this.startDate = ''
    this.endDate = ''
    this.bolSummay = false
    this.bolWKBZ = false
    this.bolPBTU = false
    this.resetValue()
    this.listDataResponse = []
    this.summaryDataResponse = []
    this.saveNgrxStore()
  }

  downloadFile() {

    var start: any = null
    var end: any = null
    if (this.startDate.length < 1 && this.endDate.length > 0) {
      alert('Please select start date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length < 1) {
      alert('Please select end date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length > 0) {
      start = this.datePipe.transform(this.startDate, 'dd/MM/yyyy');
      end = this.datePipe.transform(this.endDate, 'dd/MM/yyyy');
    }

    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }

    this.apiDownloadLoading = true

    this.api_service.getDownloadCSV(this.redeemType, this.redeemStatus, this.downloadFileName, this.dateType, start, end).subscribe({
      next: data => {
        this.downLoad_service.downloadCsvWithModifiedTime(data, this.downloadFileName)
      },
      error: (e) => {
        this.apiDownloadLoading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.apiDownloadLoading = false
        this.downloadFileName = ""
        $('#downloadCSVModal').modal('hide');
      }
    })
  }

  downloadRequestFile() {
    var start: any = null
    var end: any = null
    if (this.startDate.length < 1 && this.endDate.length > 0) {
      alert('Please select start date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length < 1) {
      alert('Please select end date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length > 0) {
      start = this.datePipe.transform(this.startDate, 'dd/MM/yyyy');
      end = this.datePipe.transform(this.endDate, 'dd/MM/yyyy');
    }

    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }

    this.apiDownloadLoading = true

    this.api_service.getInitDownloadCSV(this.redeemType, this.redeemStatus, this.downloadFileName, this.dateType, start, end).subscribe({
      next: data => {
        this.downLoad_service.downloadCsvWithModifiedTime(data, this.downloadFileName)
      },
      error: (e) => {
        this.apiDownloadLoading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.apiDownloadLoading = false
        this.downloadFileName = ""
        $('#downloadCSVModal').modal('hide');
      }
    })
  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.getRedeemList(this.isPagination)
    }
  }

  goToTransactionList(data: any) {
    this.router.navigate(['nav/redeem-transaction-list/', data.id, data.redeemableStatus]);
  }

  goToDownloadListPage() {
    this.router.navigate(['nav/redeem-download-list']);
  }

  saveNgrxStore() {
    // Dispatch actions to update the state in the NgRx Store
    this.store.dispatch(setRedeemType({ redeemType: this.redeemType }));
    this.store.dispatch(setRedeemDateType({ redeemDateType: this.dateType }));
    this.store.dispatch(setRedeemStatus({ redeemStatus: this.redeemStatus }));
    this.store.dispatch(setRedeemStartDate({ redeemStartDate: this.startDate }));
    this.store.dispatch(setRedeemEndDate({ redeemEndDate: this.endDate }));
    this.store.dispatch(setRedeemPage({ redeemPage: this.page }));
    this.store.dispatch(setRedeemDataList({ redeemDataList: this.listDataResponse }));
    this.store.dispatch(setRedeemSummaryList({ redeemSummaryList: this.summaryDataResponse }));

  }

  goBack() {
    window.history.back();
  }

}
