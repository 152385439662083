<div class="d-flex flex-wrap bd-highlight align-items-end mt-3 gap-3" *ngIf="isHeaderAccess">

  <div class="bd-highlight">
    <div>
      Search User By Phone
    </div>
    <div class="input-group">
      <input type="text" name="phone" [(ngModel)]="phone" class="form-control" placeholder="Phone" />
      <div class="input-group-append">
        <button type="submit" class="btn btn-primary rounded-0 rounded-end" (click)="SearchPhone(phone)">
          Search
        </button>
      </div>
    </div>
  </div>

  <div class="bd-highlight">
    <button class="btn btn-primary" (click)="UpdateRedeemLimitStatusForAllCustomer()">
      Update Daily Redeem Limit For All Customer
    </button>
  </div>

  <div class="me-auto bd-highlight">
    <button class="btn btn-primary" (click)="goToCustomerUnVerifyList()">UnVerified Customer List</button>
  </div>

  <div class="me-3 bd-highlight">
    <div>
      Check NRC Status
    </div>
    <div class="input-group">
      <select name="filter" [(ngModel)]="nrcStatus" class="form-control">
        <option value="" disabled selected>Choose a nrc status</option>
        <option [value]="option" *ngFor="let option of filterNRCStatus">
          {{ option }}
        </option>
      </select>
      <div class="input-group-append">
        <button class="btn btn-primary rounded-0 rounded-end" (click)="filterButton()">Search</button>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive mt-2 me-3">
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Sr No</th>
        <th>Phone</th>
        <th>Name</th>
        <th>Email</th>
        <th>Current points</th>
        <th>Total points</th>
        <th>Daily redeem limit</th>
        <th>NRC Verified By</th>
        <th>Enable</th>
        <th>Total: {{ total }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of customerlist| paginate: {
                  itemsPerPage: pageSize,
                  currentPage: page + 1,
                  totalItems: allTotal
                }">
        <td>{{ user.srNo }}</td>
        <td>{{ user.loginPhone }}</td>
        <td>{{ user.name | titlecase }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.currentPoints }}</td>
        <td>{{ user.totalPoints }}</td>
        <td>{{ user.dailyRedeemableLimit }}</td>
        <td>{{ user.nrcVerifiedBy }}</td>
        <td>
          <i class="bi bi-x-lg" style="color: red" *ngIf="user.enabled == false"></i>
          <i class="bi bi-check-lg" style="color: blue" *ngIf="user.enabled == true"></i>
        </td>
        <td>
          <div class="d-flex flex-wrap bd-highlight gap-1">
            <div>
              <button class="btn btn-info btn-sm" title="Profile"
                (click)="goToProfile(user.loginPhone)">Profile</button>
            </div>
            <div>
              <button class="btn btn-info btn-sm" title="QR Code Claim"
                (click)="goToQrHistory(user.loginPhone)">QrCode</button>
            </div>
            <div>
              <button class="btn btn-info btn-sm" title="Exchange list"
                (click)="goToCustomerRedeem(user.loginPhone)">ExList</button>
            </div>
            <div>
              <a class="btn btn-success btn-sm custom-width" title="Verified" *ngIf="user.nrcVerifiedBy"
                data-bs-toggle="modal" data-bs-target="#verifyAlert">
                <i class="fa-solid fa-check"></i></a>

              <a class="btn btn-danger btn-sm custom-width" title="No Nrc Image" *ngIf="
              !user.nrcFrontImage || !user.nrcBackImage " data-bs-toggle="modal" data-bs-target="#imageAlert">
                <i class="bi bi-file-x" style="color: black"></i></a>

              <button class="btn btn-warning btn-sm custom-width" title="Need to verify"
                (click)="goToProfile(user.loginPhone)" *ngIf="
              !user.nrcVerifiedBy &&
              user.nrcBackImage &&
              user.nrcFrontImage ">
                <i class="fa-solid fa-exclamation"></i></button>
            </div>

          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>

<div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
  *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="verifyAlert" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Already Verify</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="imageAlert" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">No Nrc Image</h6>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>
</div>