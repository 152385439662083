import { PageSize } from './../../../enumeration/pageSize';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { RedeemApiService } from 'src/app/service/redeem/redeem-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';

@Component({
  selector: 'app-redeem-items-list',
  templateUrl: './redeem-items-list.component.html',
  styleUrl: './redeem-items-list.component.css'
})
export class RedeemItemsListComponent {

  total: any;
  results: any;
  loading: any;
  htmlstring: any;
  itemTypes: any = ['PHONE_BILL_TOPUP', 'WALLET_CASH_TRANSFER'];;
  newDataForm: FormGroup<any>;
  updateDataForm: FormGroup<any>;
  selectedName: any;
  page: any = 0
  allTotal: any = 0
  pageSize: any = PageSize.REDEEM_ITEMS_LISTS
  apiLoading: any;
  isAddNewUpdateAccess: any = false

  constructor(private fb: FormBuilder,
    private router: Router,
    private api_service: RedeemApiService,
    private checkAccessRoles: CheckRolesAccessService) {
    this.newDataForm = fb.group({
      name: new UntypedFormControl('', Validators.required),
      points: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      mmkValue: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      serviceName: new UntypedFormControl('', Validators.required),
      minutesValue: new UntypedFormControl('', [Validators.pattern('^[0-9]*$')]),
      redeemableType: new UntypedFormControl('', Validators.required),
    })
    this.updateDataForm = fb.group({
      name: new UntypedFormControl('', Validators.required),
      points: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      mmkValue: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      serviceName: new UntypedFormControl('', Validators.required),
      minutesValue: new UntypedFormControl('', [Validators.pattern('^[0-9]*$')]),
    })

  }

  ngOnInit(): void {
    this.isAddNewUpdateAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN])

    this.listAll(0);
  }

  listAll(page: any) {
    this.loading = true
    this.api_service.getRedeemItemsLists(page).subscribe({
      next: (data) => {
        this.total = data.total;
        this.results = data.redeemableItemRespInfoLst;
        this.allTotal = data.allTotal;
      },
      error: (error) => {
        this.loading = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.loading = false
      },
    });
  }

  addNewRedeem() {
    //this.apiLoading = true
    this.api_service.getAddNewRedeemItem(this.newDataForm.value).subscribe({
      next: (data) => {
        alert(data.name + " Success");
        this.listAll(this.page);
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.newDataForm.reset()
      }
    });
  }

  GetSingleView(name: string): void {
    this.updateDataForm.reset();
    this.api_service.getRedeemItemInfo(name).subscribe({
      next: (v) => {
        this.selectedName = v.name;
        this.updateDataForm.controls['name'].setValue(v.name);
        this.updateDataForm.controls['points'].setValue(v.points);
        this.updateDataForm.controls['mmkValue'].setValue(v.mmkValue);
        this.updateDataForm.controls['serviceName'].setValue(v.serviceName);
        this.updateDataForm.controls['minutesValue'].setValue(v.minutesValue);
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => { },
    });
  }

  updateRedeem() {
    this.api_service.getUpdateRedeemItem(this.updateDataForm.value).subscribe({
      next: (v) => {
        alert('Update Success')
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.listAll(this.page)
      },
    });
  }

  redeemEnableDisable(type: string, name: string) {
    this.api_service.getredeemEnableDisable(type, name).subscribe({
      next: (v) => {
        alert('Update success')
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.listAll(this.page)
      },
    });
  }

  ListAllEnabledRedeem() {
    this.loading = true
    this.api_service.getEnabledRedeemableItemList().subscribe({
      next: (data) => {
        this.total = data.total;
        this.results = data.redeemableItemRespInfoLst;
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      },
    });
  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.listAll(this.page);
    }
  }
}
