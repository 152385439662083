<div class="container-fluid">

  <div class="mt-2">
    <button type="button" class="btn btn-outline-primary" (click)="goBack()">
      Back
    </button>
  </div>

  <div class="table-responsive mt-2 me-2">
    <table class="table table-sm">
      <thead>
        <ng-container *ngIf="!type.includes('KBZ')">
          <tr>
            <th>Sr No</th>
            <th>Transaction ID</th>
            <th>Payment Phone</th>
            <th>Service Name</th>
            <th>Result Code</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </ng-container>
        <ng-container *ngIf="type.includes('KBZ')">
          <tr>
            <th>No</th>
            <th>Payment Phone</th>
            <th>Payment Name</th>
            <!-- <th>Error Response</th> -->
            <th>Response Code</th>
            <th>Result</th>
            <th>Code</th>
            <th>Message</th>
            <!-- <th>Nonce String</th>
            <th>Sign</th>
            <th>Sign Type</th> -->
            <th>Total Amount</th>
            <th>Currency</th>
            <!-- <th>Merchant Order ID</th>
            <th>Payment Order ID</th> -->
            <th>MM Order ID</th>
            <!-- <th>Trade Status</th> -->
            <th>Success Time</th>
          </tr>
        </ng-container>
      </thead>
      <tbody>
        <ng-container
          *ngFor="let data of transactionList | paginate: { itemsPerPage: pageSize, currentPage: page + 1, totalItems: allTotal }">
          <tr>
            @if (!type.includes('KBZ')) {
            <td>{{ data.srNo }}</td>
            <td>{{ data.transactionId }}</td>
            <td>{{ data.paymentPhone }}</td>
            <td>{{ data.servicename }}</td>
            <td>{{ data.resultCode }}</td>
            <td>{{ data.description }}</td>
            <td>{{ data.status }}</td>
            }

            @else if (type.includes('KBZ')) {
            <td>{{ data.srNo }}</td>
            <td>{{ data.paymentPhone }}</td>
            <td>{{ data.paymentName }}</td>
            <!-- <td>{{ data.errorResponse }}</td> -->
            <td>{{ data.errorStatusCode }}</td>
            <td>{{ data.result }}</td>
            <td>{{ data.code }}</td>
            <td>{{ data.msg }}</td>
            <td>{{ data.totalAmount }}</td>
            <td>{{ data.transCurrency }}</td>
            <!-- <td>{{ data.merchOrderId }}</td>
            <td>{{ data.paymentOrderId }}</td> -->
            <td>{{ data.mmOrderId }}</td>
            <!-- <td>{{ data.tradeStatus }}</td> -->
            <td>{{ data.paySuccessTime | dateFormat }}</td>
            }

        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
    *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</div>