import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { QrApiService } from 'src/app/service/qr/qr-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';

@Component({
  selector: 'app-qr-code-details',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DateFormatPipe
  ],
  templateUrl: './qr-code-details.component.html',
  styleUrls: ['./qr-code-details.component.css']
})
export class QrCodeDetailsComponent implements OnInit {

  isUpdateEnableDisable: any = false;
  qrcodeitem: any = '';
  serial: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api_service: QrApiService,
    private checkAccessRoles: CheckRolesAccessService
  ) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.serial = String(routeParams.get('qrcode'));

    this.isUpdateEnableDisable = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN])

    this.getQrcodeInfo();
  }

  getQrcodeInfo(): void {
    this.api_service.getQrcodeSingleService(this.serial).subscribe({
      next: (v) => {
        this.qrcodeitem = v
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      },
    });
  }

  updatepoints() {
    let inputvalue = prompt('Enter point value');
    if (inputvalue) {
      let bar = confirm('Confirm: ' + inputvalue);
      if (bar) {
        this.api_service.getUpdateQrcodePointService(this.serial, String(inputvalue)).subscribe({
          next: (v) => {
            alert('Success')
          },
          error: (e) => {
            if (e) {
              alert(e)
            }
          },
          complete: () => {
            this.getQrcodeInfo()
          },
        });
      }
    }
  }

  enableQrcode(type: string) {
    if (confirm(type)) {
      this.api_service.getEnableQrcode(type, this.serial).subscribe({
        next: (v) => {
          alert('Success')
        },
        error: (e) => {
          if (e) {
            alert(e)
          }
        },
        complete: () => {
          this.getQrcodeInfo()
        },
      });
    }
  }
}
