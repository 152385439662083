<a class="btn btn-outline-primary mt-2" routerLink="/nav/qr-code-list">Back</a>

<div class="alert alert-primary mt-2">{{ textalert }}</div>

<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th>Sr no</th>
        <th>User</th>
        <th>Contact</th>
        <th>Points</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users">
        <td>{{ user.srNo }}</td>
        <td>
          Login phone: {{ user.loginPhone }}<br />
          Name: {{ user.name }}<br />
          Profile: {{ user.profile }}<br />
          Email: {{ user.email }}<br />
          Occupation: {{ user.occupation }}<br />
          NRC: {{ user.nrc }}<br />
          Registration no: {{ user.companyRegisterNo }}
        </td>
        <td>
          Address: {{ user.address1 }} {{ user.address2 }} {{ user.address3 }}<br />
          City: {{ user.city }}<br />
          Township: {{ user.township }}<br />
          Division State: {{ user.divisionState }}
        </td>
        <td>
          Current points: {{ user.currentPoints }}<br />
          Timestamp:
          {{ user.currentPointsTs | dateFormat }}<br />
          Total points: {{ user.totalPoints }}<br />
          Legal check: {{ user.hasIllegalClaims }}<br />
          Messages: {{ user.hasMessages }}<br />
          New messages: {{ user.hasNewMessages }}<br />
          Enabled: {{ user.enabled }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
