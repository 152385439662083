import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
  standalone: true
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {

  }
  transform(value: any): any {
    const dateObject = new Date(value)

    const formatDate = this.datePipe.transform(dateObject, 'dd-MM-yyyy H:mm:ss');

    return formatDate;
  }

}
