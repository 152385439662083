import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { NavComponent } from './component/nav/nav.component';
import { HomeComponent } from './component/home/home.component';
import { AdminListComponent } from './component/admin/admin-list/admin-list.component';
import { QrCodeListComponent } from './component/qr/qr-code-list/qr-code-list.component';
import { LuckyDrawListComponent } from './component/luckydraw/lucky-draw-list/lucky-draw-list.component';
import { CustomerRedeemListComponent } from './component/redeem/customer-redeem-list/customer-redeem-list.component';
import { QrHistoryComponent } from './component/qr/qr-history/qr-history.component';
import { QrIllegalclaimComponent } from './component/qr/qr-illegalclaim/qr-illegalclaim.component';
import { QrCodeDetailsComponent } from './component/qr/qr-code-details/qr-code-details.component';
import { RedeemItemsListComponent } from './component/redeem/redeem-items-list/redeem-items-list.component';
import { CustomerListComponent } from './component/customer/customer-list/customer-list.component';
import { CustomerInfoComponent } from './component/customer/customer-info/customer-info.component';
import { CustomerUnverifyListComponent } from './component/customer/customer-unverify-list/customer-unverify-list.component';
import { RedeemTransactionListComponent } from './component/redeem/redeem-transaction-list/redeem-transaction-list.component';
import { QrUploadComponent } from './component/qr/qr-upload/qr-upload.component';
import { RouteGuardService } from './utils/route-guard.service';
import { AuthGuard } from './utils/auth.guard';
import { TransactionComponent } from './component/transaction/transaction.component';
import { BlockPhoneComponent } from './component/block-phone/block-phone.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'nav', component: NavComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'admin-list', component: AdminListComponent },
      { path: 'qr-code-list', component: QrCodeListComponent },
      { path: 'qr-code-history/:phone', component: QrHistoryComponent },
      { path: 'qr-code-illegalclaim/:qrcode', component: QrIllegalclaimComponent },
      { path: 'qr-code-details/:qrcode', component: QrCodeDetailsComponent },
      { path: 'qr-upload', component: QrUploadComponent },
      { path: 'lucky-draw-list', component: LuckyDrawListComponent },
      { path: 'redeem-item-list', component: RedeemItemsListComponent },
      { path: 'redeem-transaction-list/:redeemId/:type', component: RedeemTransactionListComponent },
      { path: 'customer-redeem-list/:phone', component: CustomerRedeemListComponent },
      { path: 'customer-list', component: CustomerListComponent },
      { path: 'customer-info/:phone', component: CustomerInfoComponent },
      { path: 'customer-unverify-list', component: CustomerUnverifyListComponent },
      { path: 'transaction-list', component: TransactionComponent },
      { path: 'block-phone-list', component: BlockPhoneComponent },
      // Add the wildcard route to handle any other routes
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [RouteGuardService],
})
export class AppRoutingModule { }
