import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as Papa from 'papaparse';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private datePipe: DatePipe,
  ) { }

  downloadFileInLocal(data: string, fileNmae: string) {
    const blob = new Blob([data], { type: 'text/csv' });
    const download = document.createElement('a');
    download.href = URL.createObjectURL(blob);
    download.setAttribute('download', fileNmae);
    document.body.appendChild(download);
    download.click();
    document.body.removeChild(download);
  }

  downloadCsvWithModifiedTime(csvData: string, fileName: any): void {

    const updatedCsvData = this.addSixHoursToRedeemedTimePapa(csvData);
    const blob = new Blob([updatedCsvData], { type: 'text/csv' });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  }

  private addSixHoursToRedeemedTimePapa(csvData: string): string {
    const results = Papa.parse(csvData, {
      header: true,
      skipEmptyLines: true,
    });

    if (Array.isArray(results.data) && results.data.length > 0) {
      const rows: any[] = results.data;

      for (let i = 0; i < rows.length; i++) {
        const redeemedTime = rows[i]['Redeemed Time'];
        const processTime = rows[i]['Process Time'];
        const paySuccessTime = rows[i]['Pay Success Time'];

        if (redeemedTime) {
          const originalredeemedDate = new Date(redeemedTime + 'Z');
          const formattedredeemedDate = this.datePipe.transform(originalredeemedDate, 'yyyy-MM-dd H:mm:ss');
          rows[i]['Redeemed Time'] = formattedredeemedDate;
        }

        if (processTime) {
          const originalprocessDate = new Date(processTime + 'Z');
          const formattedprocessDate = this.datePipe.transform(originalprocessDate, 'yyyy-MM-dd H:mm:ss');
          rows[i]['Process Time'] = formattedprocessDate;
        }

        if (paySuccessTime) {
          const originalPaySuccessDate = new Date(paySuccessTime + 'Z');
          const formattedPaySuccessDate = this.datePipe.transform(originalPaySuccessDate, 'yyyy-MM-dd H:mm:ss');
          rows[i]['Pay Success Time'] = formattedPaySuccessDate;
        }

        // for (const key in rows[i]) {
        //   if (rows[i].hasOwnProperty(key)) {
        //     const value = rows[i][key];
        //     if (typeof value === 'string' && !isNaN(parseFloat(value)) && parseFloat(value) > 1e15) {
        //       rows[i][key] = `"${value}"`;
        //     }
        //   }
        // }

      }

      const updatedCsvData = Papa.unparse(rows, { header: true });

      return updatedCsvData;
    }

    console.error("Unexpected format for CSV data");
    return csvData; // or handle the error accordingly
  }

}
