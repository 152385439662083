<div class="d-flex flex-wrap bd-highlight gap-3 mt-3">
  <div>
    <a class="btn btn-primary" routerLink="/nav/qr-code-list">Back</a>
  </div>

  <div class="ms-auto" *ngIf="isUpdateEnableDisable">
    <button class="btn btn-primary" (click)="updatepoints()" *ngIf="qrcodeitem.claimCustomerLoginPhone === null">
      Update Points</button>
  </div>
  <div class="me-3" *ngIf="isUpdateEnableDisable">
    <div>
      <button class="btn btn-warning" (click)="enableQrcode('enabled')"
        *ngIf="qrcodeitem.claimCustomerLoginPhone === null &&!qrcodeitem.enabled && qrcodeitem.luckyDrawName">
        Enable</button>
    </div>
    <div>
      <button class="btn btn-danger" (click)="enableQrcode('disabled')"
        *ngIf="qrcodeitem.claimCustomerLoginPhone === null && qrcodeitem.enabled">
        Disable</button>
    </div>
  </div>
</div>

<div class="table-responsive mt-2 me-3">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" colspan="2">QR code data</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Serial number</td>
        <td>{{ qrcodeitem.serialNumber | uppercase }}</td>
      </tr>
      <tr>
        <td>QR code</td>
        <td>{{ qrcodeitem.qrCode }}</td>
      </tr>
      <tr>
        <td>Points</td>
        <td>{{ qrcodeitem.points }}</td>
      </tr>
      <tr>
        <td>Enabled</td>
        <td>
          <i class="bi bi-check-circle-fill" style="color: green" *ngIf="qrcodeitem.enabled == true"></i>
          <i class="bi bi-x-circle-fill" style="color: red" *ngIf="qrcodeitem.enabled == false"></i>
        </td>
      </tr>
      <tr>
        <td>Lucky draw name</td>
        <td>{{ qrcodeitem.luckyDrawName }}</td>
      </tr>
      <tr>
        <td>Claim time</td>
        <td>
          {{qrcodeitem.claimTimeStamp | dateFormat }}
        </td>
      </tr>
      <tr>
        <td>Customer phone</td>
        <td>{{ qrcodeitem.claimCustomerLoginPhone }}</td>
      </tr>
      <tr>
        <td>Has illegal claim</td>
        <td>{{ qrcodeitem.hasIllegalClaims }}</td>
      </tr>
      <tr>
        <td>Illegal claim type</td>
        <td>{{ qrcodeitem.illegalClaimType }}</td>
      </tr>
    </tbody>
  </table>
</div>
