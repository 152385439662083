import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';

@Injectable({
  providedIn: 'root'
})
export class LoginApiService {


  constructor(
    private http: HttpClient,
    private api: ApiCallServiceService) { }

  getLogin(authorization: string): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': authorization,
      }),
      withCredentials: true,
      // observe: 'response' as 'response',
    }

    return this.http.post(environment.baseUrl + 'api/rwadmin/login', null, httpOptions)

  }

  getLogout(): Observable<any> {
    const url = 'api/rwadmin/logout'

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUpdatePassword(loginName: any, newPassword: any): Observable<any> {
    const url = 'api/rwadmin/registration/updateadminpassword?adminLoginName=' + loginName + '&newPassword=' + newPassword

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getHeaders(a: any) {
    var result = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': a,
      }),
      withCredentials: true
    }
    return result;
  }

}
