<div class="d-flex flex-wrap bd-highlight align-items-end mt-3 gap-3">
  <div>
    <a class="btn btn-primary" (click)="goBack()">Back</a>
  </div>

  <div class="d-flex flex-wrap gap-2 ms-auto me-3">
    <div *ngIf="isNRCVerifyAccess">
      <button
        type="button"
        class="btn btn-info"
        *ngIf="
          customerdata.nrcVerifiedBy &&
          customerdata.nrcFrontImage !== null &&
          customerdata.nrcBackImage !== null
        "
        (click)="UnverifyNrc()"
      >
        Unverify NRC
      </button>
    </div>

    <div>
      <button
        class="btn btn-info"
        (click)="getUnVerifyReasonInfo()"
        *ngIf="haveAccess && !showReasonbtn"
        data-bs-toggle="modal"
        data-bs-target="#showUnVerifyReason"
      >
        Unverify Reason
      </button>
    </div>

    <div>
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span><i class="fa-regular fa-pen-to-square"></i></span>
        <span class="mx-1 fw-normal fst-italic">Edit</span>
      </button>
      <ul class="dropdown-menu">
        <li>
          <button
            class="dropdown-item"
            type="button"
            (click)="updateCustomerInfo()"
            data-bs-toggle="modal"
            data-bs-target="#updateInfoModel"
          >
            Update Info
          </button>
        </li>
        <li>
          <button
            class="dropdown-item"
            type="button"
            (click)="UpdateRedeemLimit()"
          >
            Update Redeem Limit
          </button>
        </li>
        <li>
          <hr class="dropdown-divider" />
        </li>
        <li>
          <button
            class="dropdown-item"
            type="button"
            (click)="goToQRClaimPage()"
          >
            QrCode Claim
          </button>
        </li>
        <li>
          <button
            class="dropdown-item"
            type="button"
            (click)="goToExChangePage()"
          >
            Exchange List
          </button>
        </li>
      </ul>
    </div>

    <div>
      <button
        class="btn btn-warning"
        (click)="CustomerLoginEnableDisable('enabled')"
        *ngIf="!customerdata.enabled"
      >
        Enable
      </button>
      <button
        class="btn btn-danger"
        (click)="CustomerLoginEnableDisable('disabled')"
        *ngIf="customerdata.enabled"
      >
        Disable
      </button>
    </div>
  </div>
</div>

<div class="table-responsive mt-2 me-3">
  <table class="table">
    <thead>
      <tr>
        <th>Current points</th>
        <th>Total points</th>
        <th>Daily redeem limit</th>
        <th>Enabled</th>
        <th>Has illegal claims</th>
        <th>Has messages</th>
        <th>Has new messages</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ customerdata.currentPoints }}</td>
        <td>{{ customerdata.totalPoints }}</td>
        <td>{{ customerdata.dailyRedeemableLimit }}</td>
        <td>
          <i
            class="bi bi-check-circle-fill"
            style="color: green"
            *ngIf="customerdata.enabled == true"
          ></i>
          <i
            class="bi bi-x-circle-fill"
            style="color: red"
            *ngIf="customerdata.enabled == false"
          ></i>
        </td>
        <td>
          <i
            class="bi bi-check-circle-fill"
            style="color: green"
            *ngIf="customerdata.hasIllegalClaims == true"
          ></i>
          <i
            class="bi bi-x-circle-fill"
            style="color: cornflowerblue"
            *ngIf="customerdata.hasIllegalClaims == false"
          ></i>
        </td>
        <td>
          <i
            class="bi bi-check-circle-fill"
            style="color: green"
            *ngIf="customerdata.hasMessages == true"
          ></i>
          <i
            class="bi bi-x-circle-fill"
            style="color: cornflowerblue"
            *ngIf="customerdata.hasMessages == false"
          ></i>
        </td>
        <td>
          <i
            class="bi bi-check-circle-fill"
            style="color: green"
            *ngIf="customerdata.hasNewMessages == true"
          ></i>
          <i
            class="bi bi-x-circle-fill"
            style="color: cornflowerblue"
            *ngIf="customerdata.hasNewMessages == false"
          ></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="table-responsive mt-2 me-3">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" colspan="2">Customer info</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Name</td>
        <td>{{ customerdata.name }}</td>
      </tr>
      <tr>
        <td>Login phone</td>
        <td>{{ customerdata.loginPhone }}</td>
      </tr>
      <tr>
        <td>Wallet Accounts</td>
        <td>
          <ng-template #kbzPopContent>
            <div>Name: {{ kbzPayAccount.paymentName }}</div>
            <div>Phone: {{ kbzPayAccount.paymentPhone }}</div>
          </ng-template>
          <button
            type="button"
            class="btn btn-sm btn-light"
            [ngbPopover]="kbzPopContent"
            popoverTitle="KBZ Account"
            triggers="hover focus"
            placement="top"
          >
            KBZ Pay
          </button>
          <ng-template #ayaPopContent>
            <div>Name: {{ ayaPayAccount.paymentName }}</div>
            <div>Phone: {{ ayaPayAccount.paymentPhone }}</div>
          </ng-template>
          <button
            type="button"
            class="btn btn-sm btn-light ms-2"
            [ngbPopover]="ayaPopContent"
            popoverTitle="AYA Account"
            triggers="hover focus"
            placement="top"
          >
            AYA Pay
          </button>

          <ng-template #wavePopContent>
            <div>Name: {{ wavePayAccount.paymentName }}</div>
            <div>Phone: {{ wavePayAccount.paymentPhone }}</div>
          </ng-template>
          <button
            type="button"
            class="btn btn-sm btn-light ms-2"
            [ngbPopover]="wavePopContent"
            popoverTitle="Wave Account"
            triggers="hover focus"
            placement="top"
          >
            Wave Pay
          </button>
        </td>
      </tr>
      <tr>
        <td>Topup phones</td>
        <td>{{ topupPhones }}</td>
      </tr>
      <tr>
        <td>Profile</td>
        <td>{{ customerdata.profile }}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{ customerdata.email }}</td>
      </tr>
      <tr>
        <td>Occupation</td>
        <td>{{ customerdata.occupation }}</td>
      </tr>
      <tr>
        <td>NRC</td>
        <td>
          {{ customerdata.nrc }}&nbsp;
          <button
            class="btn btn-outline-primary btn-sm"
            (click)="showNrc('front')"
            *ngIf="customerdata.nrcFrontImage !== null"
          >
            Front<i class="icon fa-sharp fa-solid fa-eye"></i>
          </button>
          <button
            class="btn btn-outline-primary btn-sm ms-2"
            (click)="showNrc('back')"
            *ngIf="customerdata.nrcFrontImage !== null"
          >
            Back<i class="icon fa-sharp fa-solid fa-eye"></i>
          </button>
          <button
            class="btn btn-outline-primary btn-sm ms-2"
            data-bs-toggle="modal"
            data-bs-target="#verifyNrcConfirm"
            *ngIf="
              customerdata.nrcFrontImage !== null &&
              !customerdata.nrcVerifiedBy &&
              customerdata.nrcBackImage !== null
            "
          >
            <span>Verify&nbsp;<i class="fa-solid fa-exclamation"></i></span>
          </button>
          <small class="" disabled>
            <span *ngIf="customerdata.nrcVerifiedBy" style="margin-left: 10px"
              >Already Verified<i class="icon fa-sharp fa-solid fa-check"></i
            ></span>
          </small>
        </td>
      </tr>
      <tr>
        <td>NRC verify by</td>
        <td>{{ customerdata.nrcVerifiedBy }}</td>
      </tr>
      <tr>
        <td>Info verify by</td>
        <td>
          <ng-container *ngIf="valueInfoVerified; else notVerifiedBlock">
            Verified By {{ customerdata.upgVerifiedBy }}
            <button
              class="btn btn-sm btn-outline-primary ms-3"
              type="button"
              (click)="verifiedInfoBy()"
            >
              Unverified
            </button>
          </ng-container>
          <ng-template #notVerifiedBlock>
            <!-- <button class="btn btn-sm btn-outline-success" type="button" (click)="verifiedInfoBy()">Verify</button> -->

            <button
              class="btn btn-sm btn-outline-success"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#showInfoVerifyDialog"
            >
              Verify
            </button>
          </ng-template>
        </td>
      </tr>
      <tr>
        <td>Customer Type</td>
        <td>{{ customerdata.customerType }}</td>
      </tr>
      <tr>
        <td>Company register no.</td>
        <td>{{ customerdata.companyRegisterNo }}</td>
      </tr>
      <tr>
        <td>Address</td>
        <td>
          {{ customerdata.address1 }} {{ customerdata.address2 }}
          {{ customerdata.address3 }}
        </td>
      </tr>
      <tr>
        <td>City</td>
        <td>{{ customerdata.city }}</td>
      </tr>
      <tr>
        <td>Township</td>
        <td>{{ customerdata.township }}</td>
      </tr>
      <tr>
        <td>Division / State</td>
        <td>{{ customerdata.divisionState }}</td>
      </tr>
      <tr>
        <td>Unverified Note</td>
        <td>{{ unverifiedNote }}</td>
      </tr>
      <tr>
        <td>Info Remark</td>
        <td>{{ customerdata.infoRemark }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="row">
  <div class="col-md-4"></div>
  <div class="col-md-4">
    <angular-image-viewer
      class="imageView"
      *ngIf="imgSrc"
      [src]="[imgSrc]"
      [(index)]="imageIndex"
      [config]="config"
      [footerTexts]="[]"
      [imageName]="imageName"
    ></angular-image-viewer>
  </div>
  <div class="col-md-4" *ngIf="userProfile">
    <h5 class="imageheader">Customer Profile</h5>
    <img
      *ngIf="userProfile"
      [src]="userProfile"
      alt="User Profile"
      class="imageViewProfile"
    />
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="verifyNrcConfirm" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-header">NRC Verify</div>
      <div class="modal-body">Are you sure you want to Verify?</div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-info"
          data-dismiss="modal"
          (click)="verifyNrc()"
        >
          Yes
        </button>
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" role="dialog" id="showUnVerifyReason" cdkDrag>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Create Unverify Reason</h4>
      </div>
      <div class="modal-body">
        <div *ngFor="let checkbox of checkboxes" class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="checkbox.value"
          />
          {{ checkbox.label }}
        </div>
        <div class="form-group mt-2">
          <label>UnverifiedNote</label>
          <textarea
            class="form-control"
            rows="3"
            [(ngModel)]="unverifiedNote"
          ></textarea>
        </div>
        <div class="form-group mt-2">
          <label>UnverifiedBy</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="unverifiedBy"
            placeholder="Enter Name"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="submitUnverifyReason()"
          [disabled]="!unverifiedBy"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Info Modal -->
<div
  class="modal"
  id="updateInfoModel"
  tabindex="-1"
  aria-hidden="true"
  cdkDrag
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Update Customer Info</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <form [formGroup]="updateCustomerInfoForm">
            <div class="row mb-2">
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingName"
                  placeholder="Name"
                  formControlName="name"
                />
                <label for="floatingName">Name</label>
              </div>
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingProfile"
                  placeholder="Profile"
                  formControlName="profile"
                />
                <label for="floatingProfile">Profile</label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingEmail"
                  placeholder="Email"
                  formControlName="email"
                />
                <label for="floatingEmail">Email</label>
              </div>
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingCompanyRegisterNo"
                  placeholder="CompanyRegisterNo"
                  formControlName="companyRegisterNo"
                />
                <label for="floatingCompanyRegisterNo"
                  >Company Register No</label
                >
              </div>
            </div>

            <div class="row mb-2">
              <div class="form-floating col">
                <select
                  class="form-control form-select"
                  id="floatingOccupation"
                  formControlName="occupation"
                >
                  <option value="" disabled selected>Select Occupation</option>
                  <option
                    *ngFor="let occupation of userOccupations"
                    [value]="occupation"
                  >
                    {{ occupation }}
                  </option>
                </select>
                <label for="floatingOccupation">Occupation</label>
              </div>
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingViberPhone"
                  placeholder="Viber Phone"
                  formControlName="viberPhone"
                />
                <label for="floatingViberPhone">Viber Phone</label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="form-floating col">
                <select
                  class="form-control form-select"
                  id="floatingdivisionState"
                  formControlName="divisionState"
                  (change)="onStateChange($event)"
                >
                  <option value="" disabled selected>Select State</option>
                  <ng-container *ngIf="!isENG; else engOptions">
                    <option *ngFor="let state of stateList" [value]="state">
                      {{ state }}
                    </option>
                  </ng-container>
                  <ng-template #engOptions>
                    <option *ngFor="let state of stateENGList" [value]="state">
                      {{ state }}
                    </option>
                  </ng-template>
                </select>
                <label for="floatingdivisionState">Division State</label>
              </div>
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingTownship"
                  placeholder="Township"
                  formControlName="township"
                  [ngbTypeahead]="searchList"
                />
                <label for="floatingTownship">Township</label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingCity"
                  placeholder="City"
                  formControlName="city"
                />
                <label for="floatingCity">City</label>
              </div>
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingAddress1"
                  placeholder="Address1"
                  formControlName="address1"
                />
                <label for="floatingAddress1">Address 1</label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingAddress2"
                  placeholder="Address2"
                  formControlName="address2"
                />
                <label for="floatingAddress2">Address 2</label>
              </div>
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingAddress3"
                  placeholder="Address3"
                  formControlName="address3"
                />
                <label for="floatingAddress3">Address 3</label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="form-floating col">
                <input
                  type="text"
                  class="form-control"
                  id="floatingNRC"
                  placeholder="NRC"
                  formControlName="nrc"
                  readonly
                />
                <label for="floatingNRC">NRC</label>
              </div>
            </div>
            <div class="d-flex bd-highlight">
              <div class="bd-highlight">
                <select
                  class="form-control form-select"
                  id=""
                  (change)="onNrcStateCodeChange($event)"
                >
                  <option value="" disabled selected>Select State Code</option>
                  <option *ngFor="let code of nrcCodeList" [value]="code">
                    {{ code }}
                  </option>
                </select>
              </div>
              <div class="ms-1 bd-highlight">
                <select
                  class="form-control form-select"
                  id=""
                  (change)="onNrcTownShipChange($event)"
                >
                  <option value="" disabled selected>
                    Select Township Name
                  </option>
                  <option *ngFor="let city of nrcCityNameList" [value]="city">
                    {{ city }}
                  </option>
                </select>
              </div>
              <div class="ms-1 bd-highlight">
                <select
                  class="form-control form-select"
                  id=""
                  (change)="onNrcCitizenChange($event)"
                >
                  <option value="" disabled selected>
                    Select Citizen Status
                  </option>
                  <option *ngFor="let status of nrcStatusList" [value]="status">
                    {{ status }}
                  </option>
                </select>
              </div>
              <div
                class="ms-1 flex-grow-1 bd-highlight"
                (input)="onNrcNoChange($event)"
              >
                <input
                  type="number"
                  class="form-control"
                  id=""
                  placeholder="NRC No"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="updateCustomerInfoBy()"
          [disabled]="!updateCustomerInfoForm.valid"
        >
          <span
            *ngIf="isUploading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Verify Info Dialog -->
<div class="modal" role="dialog" id="showInfoVerifyDialog" cdkDrag>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Info Verify</h4>
      </div>
      <div class="modal-body">
        <div class="form-group mt-2">
          <label for="customer_type" class="form-label">Customer Type</label>
          <select
            class="form-select"
            id="customer_type"
            name="customer_type"
            [(ngModel)]="custeomerType"
          >
            <option value="CUSTOMER">CUSTOMER</option>
            <option value="DEALER">DEALER</option>
          </select>
        </div>
        <div class="form-group mt-2">
          <label>Info Remark</label>
          <textarea
            class="form-control"
            rows="3"
            [(ngModel)]="infoRemark"
            maxlength="128"
          ></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="verifiedInfoBy()"
          [disabled]="!custeomerType"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
