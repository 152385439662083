import { bold } from './../../../../node_modules/@colors/colors/index.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';

@Injectable({
  providedIn: 'root'
})
export class LuckydrawApiService {

  constructor(
    private http: HttpClient,
    private store: DataStoreService,
    private api: ApiCallServiceService
  ) { }

  getLuckyDrawList(page: string): Observable<any> {

    const url = 'api/rwadmin/luckydraw/listitems?page=' + page + '&pageSize=' + PageSize.LUCKY_DRAW_LIST

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getEnabledDisabled(type: any, name: any): Observable<any> {

    const url = 'api/rwadmin/luckydraw/qrcode/' + type + '?luckyDrawName=' + name

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getAddNewLuckyDraw(body: any): Observable<any> {

    const url = 'api/rwadmin/luckydraw/new'

    return this.api.postApiCall(url, JSON.stringify(body), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getlistLuckyDrawItemsWithPartOfNameDate(startDate: any, endDate: any, partOfLuckyDrawName: any, page: any): Observable<any> {

    var url = 'api/rwadmin/luckydraw/listitems?luckyDrawName=' + partOfLuckyDrawName + '&page=' + page + '&pageSize=' + PageSize.LUCKY_DRAW_LIST
    if (startDate && endDate) {
      url = 'api/rwadmin/luckydraw/listitems?luckyDrawName=' + partOfLuckyDrawName +
        '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + PageSize.LUCKY_DRAW_LIST
    }

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getDownloadFile(downloadFileName: any, startDate: any, endDate: any, partOfLuckyDrawName: any): Observable<any> {

    var url = 'api/rwadmin/luckydraw/listitems/downloadcsv?fileName=' + downloadFileName + '&luckyDrawName=' + partOfLuckyDrawName
    if (startDate && endDate) {
      url = 'api/rwadmin/luckydraw/listitems/downloadcsv?fileName=' + downloadFileName + '&luckyDrawName=' + partOfLuckyDrawName +
        '&startDate=' + startDate + '&endDate=' + endDate
    }

    return this.api.getApiCall(url, ResponseTypeCheck.TEXT, ResponseTypeCheck.CONT_JSON)

  }

  getLuckyDrawInfo(name: any): Observable<any> {

    const url = 'api/rwadmin/luckydraw/item?luckyDrawName=' + name

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUpdateLuckyDraw(body: any): Observable<any> {

    const url = 'api/rwadmin/luckydraw/update'

    return this.api.postApiCall(url, JSON.stringify(body), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getActivateLuckyDraw(name: any): Observable<any> {

    const url = 'api/rwadmin/luckydraw/activate?luckyDrawName=' + name

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }


}
