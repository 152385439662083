export const UserOccupations: string[] = [
    'Painting Services',
    'Home Decoration Services',
    'Construction Services',
    'Property Developer',
    'Infrastructure Project Contractor',
    'Architect',
    'Civil Engineer',
    'Purchaser',
    'Industry',
    'Handicraft Workshops',
    'Woodworking and Furniture Workshops',
    'Religious Building Renovation Services',
    'Auto Bodyshops and Refinishing Services',
    'Shipyards and Dockyards',
    'Paint and Hardware Stores',
    'Furniture Industry',
    'Owner',
    'Paint and Hardware Stores',
    'Individual Customer',
    'Other'
  ];
  