import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionApiService {

  constructor(
    private http: HttpClient,
    private store: DataStoreService,
    private api: ApiCallServiceService
  ) { }

  getTransactionLists(results: any, validateTransaction: any, start: any, end: any, page: any): Observable<any> {

    let url = 'api/redeemed/kbzpaypayment/transactions/alllist'
      + '?result=' + results + '&validateTransaction=' + validateTransaction
      + '&page=' + page + '&pageSize=' + PageSize.TRANSACTION_LISTS

    if (start && end) {
      url = url + '&startDate=' + start + '&endDate=' + end
    }

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getRedeemedTransactionBOById(id: any): Observable<any> {

    const url = 'api/redeemed/billtopup/transactions?redeemId=' + id

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getRedeemedTransactionKBZById(id: any): Observable<any> {

    const url = 'api/redeemed/kbzpaypayment/transactions?redeemId=' + id

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getDownloadCSV(downloadFileName: any, results: any, validateTransaction: any, start: any, end: any, limit: any) {

    var url = 'api/redeemed/kbzpaypayment/transactions/downloadcsv?fileName=' + downloadFileName
      + '&result=' + results + '&validateTransaction=' + validateTransaction + '&limit=' + limit

    if (start && end) {
      url = url + '&startDate=' + start + '&endDate=' + end
    }

    return this.api.getApiCall(url, ResponseTypeCheck.TEXT, ResponseTypeCheck.CONT_JSON)
  }

}
