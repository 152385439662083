import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataStoreService } from './utils/data_store/data-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'upg-rewards-admin-r2';

  constructor(
    private router: Router,
    private store: DataStoreService) {
  }

  ngOnInit(): void {

    //this.router.navigate(['/nav']);

    if (!this.store.getAuth()) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/nav']);
    }
  }
}
