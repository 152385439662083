import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';

@Injectable({
  providedIn: 'root'
})
export class QrApiService {

  constructor(
    private http: HttpClient,
    private store: DataStoreService,
    private api: ApiCallServiceService
  ) { }

  getQRCodeList(serial: any, page: string): Observable<any> {

    var url = 'api/qrcode/listitems?page=' + page + '&pageSize=' + PageSize.QR_LIST
    if (serial) {
      url = 'api/qrcode/listitems?serialNumberStartWith=' + serial + '&page=' + page + '&pageSize=' + PageSize.QR_LIST
    }

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getCreateQRCode(body: any): Observable<any> {

    const url = 'api/qrcode/add/item'

    return this.api.postApiCall(url, JSON.stringify(body), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUploadCSVQRCode(file: any): Observable<any> {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const url = 'api/qrcode/uploadcsv'

    return this.api.postApiCall(url, formData, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_MULT)

  }

  getDownloadFile(downloadFileName: any): Observable<any> {

    const url = 'api/qrcode/all/downloadcsv?fileName=' + downloadFileName

    return this.api.getApiCall(url, ResponseTypeCheck.TEXT, ResponseTypeCheck.CONT_JSON)

  }

  getEnableQrcode(type: string, serial: string): Observable<any> {

    const url = 'api/qrcode/' + type + '?serialNumber=' + serial

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getQrCodeIllegalClaimsService(loginphone: any, page: any): Observable<any> {

    const url = 'api/rwadmin/illegalclaim/qrcodes?loginPhone=' + loginphone + '&page=' + page + '&pageSize=' + PageSize.QR_LIST

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getQrCodeClaimsService(loginphone: any, page: any): Observable<any> {

    const url = 'api/rwadmin/claim/qrcodes?loginPhone=' + loginphone + '&page=' + page + '&pageSize=' + PageSize.QR_LIST

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getQrcodeillegalclaimHistoryService(qrcode: any): Observable<any> {

    const url = 'api/rwadmin/illegalclaim/customers?serialNumber=' + qrcode

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getQrcodeSingleService(serial: any): Observable<any> {

    const url = 'api/qrcode/item?serialNumber=' + serial

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUpdateQrcodePointService(serial: any, points: string): Observable<any> {

    const url = 'api/qrcode/update/points?serialNumber=' + serial + '&points=' + points

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getQRUploadList(page: any): Observable<any> {

    const url = 'api/qrcode/registration/upload/list?page=' + page + '&pageSize=' + PageSize.QR_LIST

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUploadFileCF(file: any, startSerial: any, endSerial: any, count: any): Observable<any> {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const url = 'api/qrcode/registration/upload?serialStart=' + startSerial + '&serialEnd=' + endSerial + '&count=' + count

    return this.api.postApiCall(url, formData, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_MULT)

  }

}
