import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';
import { PageSize } from 'src/app/enumeration/pageSize';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';


@Injectable({
  providedIn: 'root'
})
export class AdminApiService {

  constructor(
    private http: HttpClient,
    private store: DataStoreService,
    private api: ApiCallServiceService
  ) { }

  getAdminList(page: string): Observable<any> {

    const url = 'api/rwadmin/registration/listadmininfo?page=' + page + '&pageSize=' + PageSize.ADMIN_LIST

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getCreateAdmin(body: any) {

    const url = 'api/rwadmin/registration/newadminlogin?adminLoginName=' + body.username + '&initialPassword=' + body.password + '&adminRole=' + body.role

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getRegisterAdmin(loginName: any, body: any) {

    const url = 'api/rwadmin/registration/new?adminLoginName=' + loginName

    return this.api.postApiCall(url, body, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUploadCSVAdmin(file: any) {

    var formData = new FormData();
    formData.append("file", file, file.name);

    const url = 'api/rwadmin/registration/uploadcsv'

    return this.api.postApiCall(url, formData, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_MULT)

  }

  getDownloadFile(downloadFileName: any) {

    const url = 'api/rwadmin/registration/all/downloadcsv?fileName=' + downloadFileName

    return this.api.getApiCall(url, ResponseTypeCheck.TEXT, ResponseTypeCheck.CONT_JSON)

  }

  getUpdateAdminData(loginName: any, value: any): Observable<any> {

    const url = 'api/rwadmin/registration/update?adminLoginName=' + loginName

    return this.api.postApiCall(url, JSON.stringify(value), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getResetAdminLoginPasswordService(loginName: any, password: string): Observable<any> {

    const url = 'api/rwadmin/registration/updateadminpassword?adminLoginName=' + loginName + '&newPassword=' + password

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getAdminLoginDisbaleService(loginName: any): Observable<any> {

    const url = 'api/rwadmin/disabled?adminLoginName=' + loginName

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getAdminLoginEnableService(loginName: any): Observable<any> {

    const url = 'api/rwadmin/enabled?adminLoginName=' + loginName

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

}
