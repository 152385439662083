import { Injectable } from '@angular/core';
import { DataStoreService } from '../data_store/data-store.service';

@Injectable({
  providedIn: 'root'
})
export class CheckRolesAccessService {

  constructor(private store: DataStoreService) {
  }

  check(rolesList: any): boolean {
    return rolesList.includes(this.store.getRole());
  }
}
