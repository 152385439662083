import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerState } from 'src/app/reducer/customer.reducer';

export const selectCustomerState = createFeatureSelector<CustomerState>('customer');

export const selectNrcStatus = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.customerNrcStatus
);

export const selectPage = createSelector(
  selectCustomerState,
  (state: CustomerState) => state.customerPage
);
