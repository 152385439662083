<div class="container-fluid">

  <div class="d-flex flex-wrap bd-highlight align-items-end mt-2 me-2 gap-3">
    <div class="bd-highlight" *ngIf="!auditAccess">
      <a class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addNew">Add new</a>
    </div>

    <div class="ms-auto bd-highlight">
      <input type="text" name="partOfLuckyDrawName" [(ngModel)]="partOfLuckyDrawName" class="form-control"
        placeholder="Enter part of the lucky draw name" />
    </div>

    <div class="bd-highlight">
      Start Date
      <input type="date" [(ngModel)]="startDate" class="form-control" />
    </div>

    <div class="bd-highlight">
      End Date
      <input type="date" [(ngModel)]="endDate" class="form-control" />
    </div>

    <div class="bd-highlight me-3">
      <button type="submit" class="btn btn-info" (click)="listAllWithPartOfName(0)">
        Check
      </button>
      &nbsp;
      <button class="btn btn-warning" (click)="ResetPage()">Clear</button>
    </div>

    <div class="bd-highlight me-2">
      <button type="button" class="btn btn-success" [disabled]="apiDownloadLoading && !downloadFile"
        data-bs-toggle="modal" data-bs-target="#downloadCSVModal">
        <span *ngIf="apiDownloadLoading" role="status" aria-hidden="true">Downloading...</span>
        <span *ngIf="!apiDownloadLoading">Download CSV</span>
      </button>
    </div>
  </div>

  <div class="table-responsive mt-2 me-2">
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Material name</th>
          <th scope="col">Production order number</th>
          <th scope="col">Batch number</th>
          <th scope="col">Quantity</th>
          <th scope="col">Start serial</th>
          <th scope="col">Last serial</th>
          <th scope="col">Total points</th>
          <th scope="col">Created TS</th>
          <th scope="col">Updated TS</th>
          <th scope="col">Activate</th>
          <th>Total: {{ total }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of results| paginate: {
                      itemsPerPage: pageItemsSize,
                      currentPage: page + 1,
                      totalItems: allTotal
                    } , index as i">
          <th>{{ data.srNo }}</th>
          <td>{{ data.name }}</td>
          <td>{{ data.materialName }}</td>
          <td>{{ data.productionOrderNumber }}</td>
          <td>{{ data.batchNumer }}</td>
          <td>{{ data.quantity }}</td>
          <td>{{ data.qrCodeStartSerialNumber }}</td>
          <td>{{ data.qrCodeLastSerialNumber }}</td>
          <td>{{ data.totalPoints }}</td>
          <td>{{ data.createdTs | dateFormat }}</td>
          <td>{{ data.updatedTs | dateFormat }}</td>
          <td>
            <i class="bi bi-x-lg" style="color: red" *ngIf="data.activate == false"></i>
            <i class="bi bi-check-lg" style="color: blue" *ngIf="data.activate == true"></i>
            <button class="btn btn-success btn-sm" (click)="Activate(data.name)" *ngIf="data.activate == false"
              title="Activate">
              <i class="bi bi-gift"></i>
            </button>
          </td>
          <td>
            <button class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#updateData"
              (click)="GetSingleView(data.name)" *ngIf="!data.activate">
              <i class="bi bi-pencil"></i>
            </button>
            <div *ngIf="isEnableDisableAccess">
              <button class="btn btn-info btn-sm" (click)="enabledDisabled('enabled',data.name,i)"
                title="QR code enable" *ngIf="data.activate && !data.qrCodeEnabled">
                <span *ngIf="isEnableBtnLoading[i]" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                <span *ngIf="!isEnableBtnLoading[i]"><i class="bi bi-check-lg"></i></span>
              </button>
              <button class="btn btn-info btn-sm" (click)="enabledDisabled('disabled',data.name,i)"
                title="QR code disable" *ngIf="data.activate && data.qrCodeEnabled">
                <span *ngIf="isEnableBtnLoading[i]" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                <span *ngIf="!isEnableBtnLoading[i]"><i class="bi bi-x-lg"></i></span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div style="text-align: center">
    <pagination-controls [maxSize]="15" previousLabel="Prev" nextLabel="Next" [responsive]="true"
      (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>

  <div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
    *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</div>

<!-- Create new lucky draw -->
<div class="modal fade" id="addNew" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header ">
        Add new
      </div>
      <div class="modal-body">
        <form [formGroup]="newDataForm">
          <!-- <div class="col text-center">Add new</div>-->
          <div class="row p-2">
            <div class="col">
              Name
              <span class="text-danger">* must be unique, cannot update after submit</span>
            </div>
            <div class="col">
              <input type="text" class="form-control" formControlName="name" placeholder="Enter value" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">Material name</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="materialName" placeholder="Enter value" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">Production order number</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="productionOrderNumber"
                placeholder="Enter value" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">Batch number</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="batchNumer" placeholder="Enter value" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">Quantity</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="quantity" placeholder="Enter value" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">QR code start serial</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="qrCodeStartSerialNumber"
                placeholder="Enter value" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">QR code last serial</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="qrCodeLastSerialNumber"
                placeholder="Enter value" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col"></div>
            <div class="col">
              <button type="submit" class="btn btn-primary" (click)="addNewLucky()" [disabled]="!newDataForm.valid">
                <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                <span *ngIf="!apiLoading">Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update new lucky draw -->
<div class="modal fade" id="updateData" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <form [formGroup]="updateDataForm" (ngSubmit)="UpdateData()">
          <p>Update lucky draw</p>
          <div class="row p-2">
            <div class="col">Name</div>
            <div class="col">{{ selectedName }}</div>
          </div>

          <div class="row p-2">
            <div class="col">Material name</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="materialName" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">Production order number</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="productionOrderNumber" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">Batch number</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="batchNumer" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">QR code start serial</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="qrCodeStartSerialNumber" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col">QR code last serial</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="qrCodeLastSerialNumber" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col"></div>
            <div class="col">
              <button type="submit" class="btn btn-primary" [disabled]="!updateDataForm.valid">
                OK
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Download CSV Model -->
<div class="modal" id="downloadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Lucky Draw Download CSV File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Enter file name" [(ngModel)]="downloadFileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="downloadFile()"
          [disabled]="!downloadFileName || apiDownloadLoading">
          <span *ngIf="apiDownloadLoading" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <span *ngIf="!apiDownloadLoading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>