<div class="d-flex flex-wrap align-items-end bd-highlight gap-3 mt-2 me-3">

  <div *ngIf="isUploadNewDownloadAccess">
    <button class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#uploadCSVModal">
      <span class="d-none d-sm-inline">Upload </span>
      <span class="d-md-none d-lg-inline" title="Upload"><i class="fa-solid fa-cloud-arrow-up"
          style="color: #fafafa;"></i></span>
    </button>
  </div>
  <div *ngIf="isUploadNewDownloadAccess">
    <a class="btn btn-info" data-bs-toggle="modal" data-bs-target="#newQrCode">New QR code</a>
  </div>
  <div *ngIf="isUploadNewDownloadAccess">
    <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#downloadCSVModal">
      <span class="d-none d-sm-inline">Download </span>
      <span class="d-md-none d-lg-inline" title="Download"><i class="fa-regular fa-circle-down"></i></span>
    </button>
  </div>

  <div>
    <div class="small fw-light">
      Search By
    </div>
    <div class="input-group">
      <!-- <input type="text" class="form-control" placeholder="Enter serial" name="checkSerial" [(ngModel)]="searchSerial"
        (ngModelChange)="textChange($event)"> -->
      <input type="text" class="form-control" placeholder="Enter serial" name="checkSerial" [(ngModel)]="searchSerial">
      <div class="input-group-append">
        <button class="btn btn-primary rounded-0" type="button" (click)="searchQRListByNumber()"
          [disabled]="!searchSerial">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
        <button class="btn btn-danger rounded-0 rounded-end" type="button" (click)="clearSearchQRListByNumber()"
          [disabled]="!searchSerial">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="ms-lg-auto bd-highlight" *ngIf="isClaimRecordAccess">
    <div class="small fw-light">
      QR code illegal claim record
    </div>
    <div class="input-group">
      <input type="search" class="form-control" placeholder="Enter serial" name="checkSerial" [(ngModel)]="checkSerial">
      <div class="input-group-append">
        <button type="button" class="btn btn-primary rounded-0 rounded-end" (click)="checkQrClaimHistory()">
          Search
        </button>
      </div>
    </div>
  </div>

  <div class="bd-highlight" *ngIf="isClaimRecordAccess">
    <div class="small fw-light">
      User claim record
    </div>
    <div class="input-group">
      <input type="text" class="form-control" placeholder="Enter phone" name="checkPhone" [(ngModel)]="checkPhone">
      <div class="input-group-append">
        <button class="btn btn-primary rounded-0 rounded-end" type="button" (click)="checkClaimPhoneHistory()">
          Search
        </button>
      </div>
    </div>
  </div>
</div>

<div class="table-responsive mt-2 me-3">
  <table class="table table-hover table-sm">
    <thead>
      <tr>
        <th>Sr No</th>
        <th>Serial</th>
        <th>Qr Code</th>
        <th>points</th>
        <th>Enabled</th>
        <th>Lucky Draw Name</th>
        <th>Claim time</th>
        <th>Illegal check</th>
        <th>Customer phone</th>
        <!-- <th>Total: {{ total }}<br />Next: {{ nextContinuation }}</th> -->
        <th *ngIf="isQREnableDisableAccess">Disable QR</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="table-default cursor" *ngFor="let qr of qrcodes | paginate : {
        itemsPerPage: pageSize,
        currentPage: page + 1,
        totalItems: allTotal
        } , index as i" (dblclick)="qrCodeDetail(qr.serialNumber)">
        <td>{{ qr.srNo }}</td>
        <td>{{ qr.serialNumber }}</td>
        <td>{{ qr.qrCode }}</td>
        <td>{{ qr.points }}</td>
        <td>
          <i class="bi bi-check-circle-fill" style="color: green" *ngIf="qr.enabled == true"></i>
          <i class="bi bi-x-circle-fill" style="color: red" *ngIf="qr.enabled == false"></i>
        </td>
        <td>{{ qr.luckyDrawName }}</td>
        <td>
          {{ qr.claimTimeStamp | dateFormat }}
        </td>
        <td>
          {{ qr.hasIllegalClaims }}
        </td>
        <td>
          <a>{{ qr.claimCustomerLoginPhone }}</a>
        </td>
        <td>
          <div class="float-end" *ngIf="isQREnableDisableAccess">
            <button class="btn btn-sm btn-info" (click)="enableQrcode(qr.serialNumber,'disabled' , i)"
              *ngIf="qr.claimCustomerLoginPhone===null  && qr.enabled">
              <span *ngIf="isEnableBtnLoading[i]" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span *ngIf="!isEnableBtnLoading[i]">Disable QR</span>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>

<div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
  *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<!-- New Qr code Modal -->
<div class="modal fade" id="newQrCode" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        Add new QR code
      </div>
      <div class="modal-body">
        <form [formGroup]="qrCodeDataFrom" (ngSubmit)="submitNewQrcode()">
          <div class="row p-3">
            <div class="col">Serial number</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Serial number" formControlName="serialNumber" />
            </div>
          </div>
          <div class="row p-3">
            <div class="col">Qr code</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Qr code number" formControlName="qrCode" />
            </div>
          </div>
          <div class="row p-3">
            <div class="col">Points</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Points" formControlName="points" />
            </div>
          </div>
          <div class="row p-3">
            <div class="col"></div>
            <div class="col">
              <button type="submit" class="btn btn-primary" [disabled]="!qrCodeDataFrom.valid">
                <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span>
                <span *ngIf="!apiLoading">Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Upload CSV Model -->
<div class="modal" id="uploadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Qr Code Upload CSV File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label class="form-label">Select File</label>
          <input type="file" class="file-input form-control" enctype="multipart/form-data" accept=".csv"
            (change)="onFileSelected($event)">
        </div>
        <div *ngIf="isApiError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="uploadFile()">
          <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!apiLoading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Download CSV Model -->
<div class="modal" id="downloadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">QR Code Download CSV File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Enter file name" [(ngModel)]="downloadFileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="downloadFile()"
          [disabled]="!downloadFileName || apiDownloading">
          <span *ngIf="apiDownloading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!apiDownloading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>