export interface InternalStateVO {
  engName: string;
  mmName: string;
}

export const DEFAULT_STATE_LIST: InternalStateVO[] = [
  {
    engName: "Ayarwaddy",
    mmName: "ဧရာဝတီတိုင်းဒေသကြီး",
  },
  {
    engName: "Bago",
    mmName: "ပဲခူးတိုင်းဒေသကြီး",
  },
  {
    engName: "Magway",
    mmName: "မကွေးတိုင်းဒေသကြီး",
  },
  {
    engName: "Mandalay",
    mmName: "မန္တလေးတိုင်းဒေသကြီး",
  },
  {
    engName: "Sagaing",
    mmName: "စစ်ကိုင်းတိုင်းဒေသကြီး",
  },
  {
    engName: "Thathintharyi",
    mmName: "တနင်္သာရီတိုင်းဒေသကြီး",
  },
  {
    engName: "Yangon",
    mmName: "ရန်ကုန်တိုင်းဒေသကြီး",
  },
  {
    engName: "Chin",
    mmName: "ချင်းပြည်နယ်",
  },
  {
    engName: "Kachin",
    mmName: "ကချင်ပြည်နယ်",
  },
  {
    engName: "Kayah",
    mmName: "ကယားပြည်နယ်",
  },
  {
    engName: "Kayin",
    mmName: "ကရင်ပြည်နယ်",
  },
  {
    engName: "Mon",
    mmName: "မွန်ပြည်နယ်",
  },
  {
    engName: "Rakhine",
    mmName: "ရခိုင်ပြည်နယ်",
  },
  {
    engName: "Shan",
    mmName: "ရှမ်းပြည်နယ်",
  },
];
