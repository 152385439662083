import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdminApiService } from 'src/app/service/admin/admin-api.service';
import { DownloadService } from 'src/app/utils/download/download.service';
declare var $: any

@Component({
  selector: 'app-admin-list',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [DownloadService], 
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.css'],
})
export class AdminListComponent {

  adminLoginForm: FormGroup;
  adminDataForm: FormGroup;
  registerAdminForm: FormGroup<any>;
  htmlstring: any;
  admins: any;
  login_enable: any;
  selectedAdmin: any = ''
  isCreateAdmin: any = true;
  isError: any;
  apiLoading: any = false
  isUploadError: any;
  errorMessage: any;
  page: any = 0
  total: any = 0;
  downloadFileName: any;
  file!: File;
  loading: any = false;
  isDownloading: any = false

  public roleOptions = [
    'ADMIN',
    'LUCKY_DRAW_ADMIN',
    'REDEEM_PAYMENT_ADMIN',
    'AUDIT_ADMIN',
    'ACCOUNT_ADMIN',
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api_service: AdminApiService,
    private download: DownloadService
  ) {
    this.adminLoginForm = this.fb.group({
      username: ['', [
        Validators.required,
        Validators.pattern(/^\S*$/),
        Validators.minLength(5),
      ]],
      password: [this.getRandomPassowrd(10), Validators.required],
      role: ['', Validators.required],
    })

    this.adminDataForm = this.fb.group({
      name: ['', Validators.required],
      title: ['', Validators.required],
      department: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      email: ['', [Validators.required, Validators.email]],
    })

    this.registerAdminForm = this.fb.group({
      name: ['', Validators.required],
      title: ['', Validators.required],
      department: ['', Validators.required],
      phone: ['959', [Validators.required, Validators.pattern('^[0-9]*$')]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.getAdminList()
  }

  getAdminList() {
    this.loading = true
    this.api_service.getAdminList(this.page).subscribe({
      next: (value) => {
        this.admins = value.adminInfoLst;
        this.total = value.total;
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      }
    })
  }

  createAdmin() {
    this.apiLoading = true
    this.api_service.getCreateAdmin(this.adminLoginForm.value).subscribe({
      next(value) {

      },
      error: (e) => {
        this.apiLoading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.isCreateAdmin = false
        this.isError = false
        this.apiLoading = false
      },
    })
  }

  registerAdmin() {
    this.apiLoading = true

    const loginName = this.adminLoginForm.get('username')?.value
    this.api_service.getRegisterAdmin(loginName, JSON.stringify(this.registerAdminForm.value)).subscribe({
      next: (value) => {
        console.log("Success");
      },
      error: (e) => {
        this.apiLoading = false
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.apiLoading = false
        this.isCreateAdmin = true
        this.isError = false
        $('#createAdminModal').modal('hide');
        this.getAdminList()
      },
    })
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
    //this.fileName = this.fileName.name
  }

  uploadFile() {
    this.apiLoading = true
    this.api_service.getUploadCSVAdmin(this.file).subscribe({
      next: (value) => {
        alert("Success")
      },
      error: (e) => {
        this.apiLoading = false
        if (e) {
          this.isUploadError = true
          this.errorMessage = e
        }
      },
      complete: () => {
        this.apiLoading = false
        $('#uploadCSVModal').modal('hide');
        this.getAdminList()
      },
    })
  }

  downloadFile() {
    this.isDownloading = true
    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }
    this.api_service.getDownloadFile(this.downloadFileName).subscribe({
      next: (value) => {
        this.download.downloadFileInLocal(value, this.downloadFileName)
      },
      error: (e) => {
        this.isDownloading = false
        if (e) {
          this.downloadFileName = ''
          alert(e)
        }
      },
      complete: () => {
        this.downloadFileName = ''
        this.isDownloading = false
        $('#downloadCSVModal').modal('hide');
      },
    })
  }

  AdminSingleView(loginName: any, value: any) {
    this.htmlstring = '';
    this.selectedAdmin = loginName
    this.login_enable = value.enabled;
    this.adminDataForm.patchValue({
      name: value.name,
      title: value.title,
      department: value.department,
      phone: value.phone,
      email: value.email,
    })
  }

  UpdateAdminData() {
    this.apiLoading = true
    this.api_service.getUpdateAdminData(this.selectedAdmin, this.adminDataForm.value).subscribe({
      next: (value) => {
        alert("Success")
      },
      error: (error) => {
        alert(error)
        this.apiLoading = false
      },
      complete: () => {
        this.getAdminList()
        this.apiLoading = false
      }
    })
  }

  ResetAdminPassword() {
    var password = this.getRandomPassowrd(10);
    if (confirm('Reset admin login password.')) {
      this.apiLoading = true
      this.api_service.getResetAdminLoginPasswordService(this.selectedAdmin, password).subscribe({
        next: (value) => {
          (this.htmlstring =
            '<div class="alert alert-success" role="alert">' +
            `Username: ${this.selectedAdmin}<br>New password: ${password}` +
            '</div>')
        },
        error: (e) => {
          this.apiLoading = false
          alert(e)
        },
        complete: () => {
          this.apiLoading = false
        },
      });
    } else {
      return;
    }
  }

  getRandomPassowrd(length: number) {
    var randomChars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  AdminLoginEnable() {
    if (confirm('Admin Login enabled.')) {
      this.apiLoading = true
      this.api_service.getAdminLoginEnableService(this.selectedAdmin).subscribe({
        next: (v) => {
          alert(v.Status + ' ' + v.Message)
        },
        error: (e) => {
          alert(e)
          this.apiLoading = false
        },
        complete: () => {
          this.getAdminList()
          this.apiLoading = false
        },
      });
    } else {
      return;
    }
  }

  AdminLoginDisable() {
    if (confirm('Admin Login disabled.')) {
      this.apiLoading = true
      this.api_service.getAdminLoginDisbaleService(this.selectedAdmin).subscribe({
        next: (v) => {
          alert(v.Status + ' ' + v.Message)
        },
        error: (e) => {
          alert(e)
          this.apiLoading = false
        },
        complete: () => {
          this.getAdminList()
          this.apiLoading = false
        },
      });
    } else {
      return;
    }
  }
}
