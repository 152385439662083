<header>
  <div class="nav bg-default text-white min-vh-20">
    <div class="container-fluid">
      <div class="d-flex align-items-center">
        <div class="col-auto col-md-2 col-lg-2"></div>
        <a class="" href="#">
          <img src="../assets/app_icon_modified.png" class="rounded" alt="UPG logo" width="50" height="50" />
        </a>
        <div class="ms-2 h5">
          {{ login_user }}
        </div>
        <ul class="nav my-2 text-small ms-auto p-2">
          <li>
            <a class="nav-link text-white" type="button" data-bs-toggle="modal" data-bs-target="#changePasswordModal">
              <!-- <i class="bi bi-person-circle"></i><br />Account -->
              Change Password
            </a>
          </li>
          <li>
            <a class="nav-link text-white" type="button" data-bs-toggle="modal" data-bs-target="#logoutModal">
              <!-- <i class="bi bi-box-arrow-right"></i><br />Logout -->
              Logout
            </a>
          </li>
        </ul>

      </div>
    </div>
  </div>
</header>

<aside>
  <div class="d-flex flex-nowrap">
    <div class="col-2 col-md-3 col-lg-2 bg-dark">
      <div class="flex-column pt-3 text-white min-vh-100">
        <ul class="nav flex-column align-items-start">
          <li class="nav-item p-1" *ngFor="let data of links">
            <a routerLink="{{ data.link }}" class="nav-link" routerLinkActive="activelink">
              <i class="fs-4 m-2 {{ data.icon }}"></i>
              <span class="ms-1 d-none d-sm-inline">{{ data.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-10 col-md-9 col-lg-10 bg-high-light ms-2 mb-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</aside>


<!--Logout Modal -->
<article>
  <div class="modal" id="logoutModal" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Logout</h5>
        </div>
        <div class="modal-body">Are you sure you want to logout?</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="submitAdminLogout()">
            <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!isUploading">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</article>

<!--Change Password Modal -->
<div class="modal" id="changePasswordModal" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Change New Password</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="changePasswordForm">
          <div class="mb-3">
            <label class="form-label">New Password</label>
            <div class="input-group">
              <input [type]="showNewPassword ? 'text' : 'password'" class="form-control"
                formControlName="newPassword" />
              <span class="input-group-text">
                <i type="button" [ngClass]="showNewPassword ? 'fa fa-eye-slash' : 'fa fa-fw fa-eye'"
                  (click)="showNewPassword = !showNewPassword"></i>
              </span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Confirm Password</label>
            <div class="input-group">
              <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control"
                formControlName="confirmPassword" />
              <span class="input-group-text">
                <i type="button" [ngClass]="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-fw fa-eye'"
                  (click)="showConfirmPassword = !showConfirmPassword"></i>
              </span>
            </div>
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!changePasswordForm.valid"
          (click)="submitChangePassword()">
          <span *ngIf="isUploading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isUploading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
