export enum PageSize {
  ADMIN_LIST = 100,
  QR_LIST = 500,
  LUCKY_DRAW_LIST = 200,
  REDEEM_ITEMS_LISTS = 200,
  TRANSACTION_LISTS = 200,
  REDEEM_LISTS = 500,
  CUSTOMER_LIST = 200,
  CUSTOMER_UNVERIFY_LIST = 200,
  BLOCKPHONE_LIST = 100
}
