import { createAction, props } from '@ngrx/store';

export const setRedeemType = createAction('[Redeem] Set Redeem Type', props<{ redeemType: any }>());
export const setRedeemDateType = createAction('[Redeem] Set Redeem Date Type', props<{ redeemDateType: any }>());
export const setRedeemStatus = createAction('[Redeem] Set Redeem Status', props<{ redeemStatus: any }>());
export const setRedeemStartDate = createAction('[Redeem] Set Start Date', props<{ redeemStartDate: any }>());
export const setRedeemEndDate = createAction('[Redeem] Set End Date', props<{ redeemEndDate: any }>());
export const setRedeemPage = createAction('[Redeem] Set Page', props<{ redeemPage: any }>());
export const setRedeemDataList = createAction('[Redeem] Set Data List', props<{ redeemDataList: any[] }>());
export const setRedeemSummaryList = createAction('[Redeem] Set Summary List', props<{ redeemSummaryList: any[] }>());
