import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { PageSize } from 'src/app/enumeration/pageSize';
import { RedeemApiService } from 'src/app/service/redeem/redeem-api.service';
import { TransactionApiService } from 'src/app/service/transaction/transaction-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';

@Component({
  selector: 'app-redeem-transaction-list',
  templateUrl: './redeem-transaction-list.component.html',
  styleUrl: './redeem-transaction-list.component.css'
})
export class RedeemTransactionListComponent implements OnInit {

  redeemId: string = '';
  type: string = '';
  loading: boolean = false;
  transactionList: any[] = [];
  pageSize: number = PageSize.REDEEM_LISTS;
  page: number = 0;
  allTotal: number = 0;
  private routeSubscription: Subscription = new Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: TransactionApiService,
    private checkAccessRoles: CheckRolesAccessService
  ) { }

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe(params => {
      this.redeemId = params.get('redeemId') || '';
      this.type = params.get('type') || '';

      if (this.type.includes('KBZ')) {
        this.getTransactionList('KBZ');
      } else {
        this.getTransactionList('BO');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  getTransactionList(type: string): void {
    this.loading = true;
    this.transactionList = [];
    let transactionListObservable;

    if (type === 'KBZ') {
      transactionListObservable = this.apiService.getRedeemedTransactionKBZById(this.redeemId);
    } else {
      transactionListObservable = this.apiService.getRedeemedTransactionBOById(this.redeemId);
    }

    transactionListObservable.subscribe({
      next: (transactions) => {
        this.transactionList = transactions;
      },
      error: (error) => {
        this.loading = false;
        console.error('Error fetching transaction list:', error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  goBack() {
    window.history.back();
  }

}
