import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RedeemState } from 'src/app/reducer/redeem.reducer';

export const selectRedeemState = createFeatureSelector<RedeemState>('redeem');

export const selectRedeemType = createSelector(
  selectRedeemState,
  (state: RedeemState) => state.redeemType
);

export const selectRedeemStatus = createSelector(
  selectRedeemState,
  (state: RedeemState) => state.redeemStatus
);

export const selectRedeemDateType = createSelector(
  selectRedeemState,
  (state: RedeemState) => state.redeemDateType
);

export const selectStartDate = createSelector(
  selectRedeemState,
  (state: RedeemState) => state.redeemStartDate
);

export const selectEndDate = createSelector(
  selectRedeemState,
  (state: RedeemState) => state.redeemEndDate
);

export const selectPage = createSelector(
  selectRedeemState,
  (state: RedeemState) => state.redeemPage
);

export const selectDataList = createSelector(
  selectRedeemState,
  (state: RedeemState) => state.redeemDataList
);

export const selectSummary = createSelector(
  selectRedeemState,
  (state: RedeemState) => state.redeemSummaryList
);
