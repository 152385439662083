import { CommonModule, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxPaginationModule } from 'ngx-pagination';
import { setTransactionEndDate, setTransactionPage, setTransactionResults, setTransactionStartDate, setTransactionValidateTransaction } from 'src/app/action/transaction.actions';
import { PageSize } from 'src/app/enumeration/pageSize';
import { selectEndDate, selectPage, selectResults, selectStartDate, selectValidateTransaction } from 'src/app/selector/transaction.selectors';
import { TransactionApiService } from 'src/app/service/transaction/transaction-api.service';
import { DownloadService } from 'src/app/utils/download/download.service';
import { ShareModule } from 'src/app/utils/share_module/share-module/share-module.module';
declare var $: any

@Component({
  selector: 'app-transaction',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ShareModule
  ],
  templateUrl: './transaction.component.html',
  styleUrl: './transaction.component.css'
})

export class TransactionComponent {

  kbzpayPaymentTransactionRespLst: any;
  pageSize: any = PageSize.TRANSACTION_LISTS
  page: any = 0
  allTotal: any = 0
  loading: any = false
  downloadFileName: any;
  apiDownloadLoading: boolean = false;
  results: any = "ALL";
  validateTransaction: any = "ALL";
  startDate: any = '';
  endDate: any = '';
  startDateString: any = null
  endDateString: any = null
  limit: any = 10000;

  constructor(
    private api_service: TransactionApiService,
    private datePipe: DatePipe,
    private downLoad_service: DownloadService,
    private store: Store
  ) {

  }

  ngOnInit(): void {
    this.getPreviousDataReloadWithAppState()
    this.getAllList()
  }

  getPreviousDataReloadWithAppState() {


    this.store.pipe(select(selectResults)).subscribe(results => {
      this.results = results
    });

    this.store.pipe(select(selectValidateTransaction)).subscribe(validateTransaction => {
      this.validateTransaction = validateTransaction
    });

    this.store.pipe(select(selectStartDate)).subscribe(startDate => {
      this.startDate = startDate
    });

    this.store.pipe(select(selectEndDate)).subscribe(endDate => {
      this.endDate = endDate
    });

    this.store.pipe(select(selectPage)).subscribe(page => {
      this.page = page
    });

  }

  getAllList() {
    this.loading = true
    this.api_service.getTransactionLists(this.results, this.validateTransaction, this.startDateString, this.endDateString, this.page).subscribe({
      next: (data) => {
        this.kbzpayPaymentTransactionRespLst = data.kbzpayPaymentTransactionRespLst;
        this.allTotal = data.allTotal;
      },
      error: (error) => {
        this.loading = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.loading = false
        this.saveNgrxStore()
      },
    });
  }

  saveFliter() {

    if (this.startDate.length < 1 && this.endDate.length > 0) {
      alert('Please select start date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length < 1) {
      alert('Please select end date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length > 0) {
      this.startDateString = this.datePipe.transform(this.startDate, 'dd/MM/yyyy');
      this.endDateString = this.datePipe.transform(this.endDate, 'dd/MM/yyyy');
    }

    this.page = 0

    this.getAllList()

  }

  downloadFile() {

    if (this.startDate.length < 1 && this.endDate.length > 0) {
      alert('Please select start date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length < 1) {
      alert('Please select end date');
      return
    } else if (this.startDate.length > 0 && this.endDate.length > 0) {
      this.startDateString = this.datePipe.transform(this.startDate, 'dd/MM/yyyy');
      this.endDateString = this.datePipe.transform(this.endDate, 'dd/MM/yyyy');
    }

    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }

    this.apiDownloadLoading = true

    this.api_service.getDownloadCSV(this.downloadFileName, this.results, this.validateTransaction, this.startDateString, this.endDateString, this.limit).subscribe({
      next: data => {
        this.downLoad_service.downloadCsvWithModifiedTime(data, this.downloadFileName)
      },
      error: (e) => {
        this.apiDownloadLoading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.apiDownloadLoading = false
        this.downloadFileName = ""
        $('#downloadCSVModal').modal('hide');
      }
    })
  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllList()
    }
  }

  saveNgrxStore() {
    // Dispatch actions to update the state in the NgRx Store
    this.store.dispatch(setTransactionResults({ transactionResults: this.results }));
    this.store.dispatch(setTransactionValidateTransaction({ transactionValidateTransaction: this.validateTransaction }));
    this.store.dispatch(setTransactionStartDate({ transactionStartDate: this.startDate }));
    this.store.dispatch(setTransactionEndDate({ transactionEndDate: this.endDate }));
    this.store.dispatch(setTransactionPage({ transactionPage: this.page }));
  }
}
