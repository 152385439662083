import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageSize } from 'src/app/enumeration/pageSize';
import { QrApiService } from 'src/app/service/qr/qr-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';
import { DownloadService } from 'src/app/utils/download/download.service';

@Component({
  selector: 'app-qr-upload',
  templateUrl: './qr-upload.component.html',
  styleUrl: './qr-upload.component.css'
})
export class QrUploadComponent {

  loading: any = false;
  page: any = 0
  pageSize: any = PageSize.QR_LIST
  allTotal: any = 0
  qrUploadList: any;
  apiLoading: any;
  createQrUploadForm: FormGroup<any>
  selectedFile: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api_service: QrApiService,
    private download: DownloadService,
    private checkAccessRoles: CheckRolesAccessService
  ) {
    this.createQrUploadForm = this.fb.group({
      serialStart: ['', Validators.required],
      serialEnd: ['', Validators.required],
      count: ['', Validators.required],
      file: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getQRUploadList()
  }

  getQRUploadList() {
    this.loading = true
    this.api_service.getQRUploadList(this.page).subscribe({
      next: (v) => {
        this.qrUploadList = v.uploadQrCodeRespLst
        this.allTotal = v.allTotal
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      }
    })
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]
  }
  uploadQRFile() {
    this.apiLoading = true
    var startSerial = this.createQrUploadForm.get('serialStart')?.value
    var endSerial = this.createQrUploadForm.get('serialEnd')?.value
    var count = this.createQrUploadForm.get('count')?.value

    this.api_service.getUploadFileCF(this.selectedFile, startSerial, endSerial, count).subscribe({
      next: (v) => {
        alert("Upload Success")
        this.qrUploadList = v
        this.allTotal = v.allTotal
      },
      error: (e) => {
        this.apiLoading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.apiLoading = false
        this.getQRUploadList()
      }
    })

  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.getQRUploadList();
    }
  }

}
