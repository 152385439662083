<div class="mt-2">
  <button class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#uploadCSVModal">
    <span class="d-none d-sm-inline">Upload </span>
    <span class="d-md-none d-lg-inline" title="Upload"><i class="fa-solid fa-cloud-arrow-up"
        style="color: #fafafa;"></i></span>
  </button>
</div>

<div class="table-responsive mt-2 me-3">
  <table class="table">
    <thead>
      <tr>
        <th>SrNo</th>
        <th>File Name</th>
        <th>Start Serial</th>
        <th>End Serial</th>
        <th>Count</th>
        <th>Status</th>
        <th>Reason</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of qrUploadList | paginate : {
        itemsPerPage: pageSize,
        currentPage: page + 1,
        totalItems: allTotal
        }">
        <td>{{ data.srNo }} </td>
        <td>{{ data.uploadFileName }}</td>
        <td>{{ data.startSerial }}</td>
        <td>{{ data.endSerial }}</td>
        <td>{{ data.count }}</td>
        <td>{{ data.status }}</td>
        <td>{{ data.reason }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>

<div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
  *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<!-- Upload CSV Model -->
<div class="modal" id="uploadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Qr Code Upload CSV File</h5>
      </div>
      <div class="modal-body">
        <form [formGroup]="createQrUploadForm">
          <div class="mb-3">
            <label class="form-label">Start Serial Number</label>
            <input type="text" class="form-control" formControlName="serialStart">
          </div>
          <div class="mb-3">
            <label class="form-label">End Serial Number</label>
            <input type="text" class="form-control" formControlName="serialEnd">
          </div>
          <div class="mb-3">
            <label class="form-label">Count</label>
            <input type="number" class="form-control" formControlName="count">
          </div>
          <div class="mb-3">
            <label class="form-label">Select File</label>
            <input type="file" class="form-control" formControlName="file" accept=".csv"
              (change)="onFileSelected($event)">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" [disabled]="!createQrUploadForm.valid" (click)="uploadQRFile()">
          <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!apiLoading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
