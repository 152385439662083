import { Store, select } from '@ngrx/store';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { CustomerApiService } from 'src/app/service/customer/customer-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';
import { DownloadService } from 'src/app/utils/download/download.service';
import { selectNrcStatus } from 'src/app/selector/customer.selectors';
import { selectPage } from 'src/app/selector/redeem.selectors';
import { setCustomerNrcStatus } from 'src/app/action/customer.actions';
import { setCustomerPage } from 'src/app/action/customer.actions';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent {

  phone: any = '959';

  nrcStatus: any = 'ALL';
  customerlist: any;

  total: any = 0;
  pageSize: any = PageSize.CUSTOMER_LIST
  page: any = 0
  allTotal: any = 0
  loading: any = false;
  isHeaderAccess: any = false

  public filterNRCStatus = [
    'ALL',
    'NRC_VERIFIED',
    'NRC_TO_VERIFY',
    'NRC_UNABLE_TO_VERIFY',
  ];

  constructor(
    private router: Router,
    private api_service: CustomerApiService,
    private download: DownloadService,
    private checkAccessRoles: CheckRolesAccessService,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.isHeaderAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.ADMIN])

    this.getPreviousDataReloadWithAppState()
    this.getCustomerList()

  }

  getPreviousDataReloadWithAppState() {

    this.store.pipe(select(selectNrcStatus)).subscribe(nrc => {
      this.nrcStatus = nrc
    });

    this.store.pipe(select(selectPage)).subscribe(page => {
      this.page = page
    });
  }


  getCustomerList() {

    this.loading = true;
    this.api_service.getCustomerList(this.nrcStatus, this.page).subscribe({
      next: (v: any) => {
        this.customerlist = v.customerInfoLst;
        this.total = v.total;
        this.allTotal = v.allTotal;
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false;
        this.saveNgrxStore()
      }
    });
  }

  SearchPhone(phone: any) {
    this.router.navigate(['nav/customer-info/', phone]);
  }

  UpdateRedeemLimitStatusForAllCustomer() {
    this.api_service.getStatusForUpdateRedeemLimit().subscribe({
      next: (v) => {
        console.log(v);
        if (v.Message.includes("Kong sync in progress")) {
          alert("Daily Redeem limit is running......")
        } else {
          this.UpdateRedeemLimitForAllCustomer()
        }
      },
      error: (e) => {
        if (e) {
          alert(e)
        }
      },
      complete: () => {

      }
    });
  }

  UpdateRedeemLimitForAllCustomer() {
    let inputvalue = prompt('Enter new redeemable limit');
    if (inputvalue) {
      let bar = confirm('Confirm: ' + inputvalue);
      if (bar == true) {
        this.api_service.getUpdateRedeemLimitForAllCustomer('ALL', String(inputvalue)).subscribe({
          next: (v: any) => {
            alert("Success, All Customer daily redeem limit are updating...")
          },
          error: (e) => {
            if (e) {
              alert(e)
            }
          },
          complete: () => {

          }
        });
      }
    }
  }

  goToCustomerUnVerifyList() {
    this.router.navigate(['/nav/customer-unverify-list']);
  }

  filterButton() {
    this.page = 0;
    this.getCustomerList()
  }

  goToProfile(phone: any) {
    this.router.navigate(['nav/customer-info/', phone]);
  }

  goToQrHistory(phone: any) {
    this.router.navigate(['nav/qr-code-history', phone]);
  }

  goToCustomerRedeem(phone: any) {
    this.router.navigate(['nav/customer-redeem-list', phone]);
  }

  saveNgrxStore() {
    // Dispatch actions to update the state in the NgRx Store
    this.store.dispatch(setCustomerNrcStatus({ customerNrcStatus: this.nrcStatus }));
    this.store.dispatch(setCustomerPage({ customerPage: this.page }));
  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.getCustomerList();
    }
  }

}
