<div class="container-fluid">
  <header class="d-flex align-items-end">
    <nav class="mt-2 me-2">
      <ul class="nav gap-3">
        <li>
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="goBack()"
          >
            Back
          </button>
        </li>
      </ul>
    </nav>
  </header>
  <div class="table-responsive mt-2 me-3">
    <table class="table">
      <thead>
        <tr>
          <th>SrNo</th>
          <th>File Name</th>
          <th>Redeemed Status</th>
          <th>Redeemed Type</th>
          <!-- <th>Redeemed ItemName</th> -->
          <th>Redeemed Date Type</th>
          <th>Redeemed Start Date</th>
          <th>Redeemed End Date</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let data of dataList
              | paginate
                : {
                    itemsPerPage: pageSize,
                    currentPage: page + 1,
                    totalItems: allTotal
                  };
            index as i
          "
        >
          <td>{{ data.srNo }}</td>
          <td>{{ data.downloadFileName }}</td>
          <td>{{ data.redeemedStatus }}</td>
          <td>{{ data.redeemedItemType }}</td>
          <!-- <td>{{ data.redeemedItemname }}</td> -->
          <td>{{ data.redeemedDateType }}</td>
          <td>{{ data.startDate ? (data.startDate | dateFormat) : '' }}</td>
          <td>{{ data.endDate ? (data.endDate | dateFormat) : '' }}</td>          
          <td
            [ngClass]="{
              'text-success': data.status === 'READY',
              'text-danger': data.status === 'FAILED',
              'text-primary': data.status === 'Processing'
            }"
          >
            {{ data.status }}
          </td>

          <td>
            <button
              class="btn btn-info btn-sm"
              title="Download"
              (click)="downloadFile(data.downloadFileName, i)"
              [disabled]="data.status != 'READY' || isDownloadBtnLoading[i]"
            >
              <span
                *ngIf="isDownloadBtnLoading[i]"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span *ngIf="!isDownloadBtnLoading[i]"
                ><i class="fa-solid fa-download"></i
              ></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div style="text-align: center">
  <pagination-controls
    [maxSize]="20"
    previousLabel="Prev"
    nextLabel="Next"
    [responsive]="true"
    (pageChange)="handlePageChange($event)"
  ></pagination-controls>
</div>

<div
  class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
  *ngIf="loading"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
