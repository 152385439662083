import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageSize } from 'src/app/enumeration/pageSize';
import { QrApiService } from 'src/app/service/qr/qr-api.service';

@Component({
  selector: 'app-qr-history',
  templateUrl: './qr-history.component.html',
  styleUrl: './qr-history.component.css'
})
export class QrHistoryComponent {

  qrcodes: any;
  textalert: any;
  loginphone: any

  pageSize: any = PageSize.QR_LIST
  page: any = 0
  allTotal: any = 0
  loading: any = false;
  qrType: any = 'claim'

  constructor(
    private route: ActivatedRoute,
    private api_service: QrApiService) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.loginphone = String(routeParams.get('phone'));
    this.listQrCodes('claim');
  }

  goBack() {
    window.history.back();
  }

  listQrCodes(type: string): void {
    if (type == 'illegal') {
      this.qrType = 'illegal'
      this.loading = true
      this.api_service.getQrCodeIllegalClaimsService(this.loginphone, this.page).subscribe({
        next: (v) => {
          this.textalert = `Login phone: ${this.loginphone} Claim type: illegal`;
          this.qrcodes = v.qrCodeInfoLst;
          this.allTotal = v.allTotal
        },
        error: (e) => {
          alert(e)
          this.loading = false
        },
        complete: () => {
          this.loading = false
        },
      });
    } else {
      this.qrType = 'claim'
      this.loading = true
      this.api_service.getQrCodeClaimsService(this.loginphone, this.page).subscribe({
        next: (v) => {
          this.textalert = `Login phone: ${this.loginphone} Claim type: normal`;
          this.qrcodes = v.qrCodeInfoLst;
          this.allTotal = v.allTotal
        },
        error: (e) => {
          alert(e)
          this.loading = false
        },
        complete: () => {
          this.loading = false
        },
      });
    }
  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.listQrCodes(this.qrType)
    }
  }

}
