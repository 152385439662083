import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';

@Injectable({
  providedIn: 'root'
})
export class RedeemApiService {

  constructor(
    private http: HttpClient,
    private store: DataStoreService,
    private api: ApiCallServiceService
  ) { }

  getRedeemItemsLists(page: any): Observable<any> {

    const url = 'api/redeemable/all/listitems' + '?page=' + page + '&pageSize=' + PageSize.REDEEM_ITEMS_LISTS

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getAddNewRedeemItem(body: any): Observable<any> {

    const url = 'api/redeemable/item/add'

    return this.api.postApiCall(url, JSON.stringify(body), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getRedeemItemInfo(name: any): Observable<any> {

    const url = 'api/redeemable/item?name=' + name

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getUpdateRedeemItem(body: any): Observable<any> {

    const url = 'api/redeemable/item/update'

    return this.api.postApiCall(url, JSON.stringify(body), ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getredeemEnableDisable(type: any, name: any): Observable<any> {

    const url = 'api/redeemable/item/' + type + '?name=' + name

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getEnabledRedeemableItemList(): Observable<any> {

    const url = 'api/redeemable/enabled/listitems' + '?page=0&pageSize=' + PageSize.REDEEM_ITEMS_LISTS

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }


  getRedeemedByCustomer(phone: any, status: any, page: any): Observable<any> {

    const url = 'api/redeemed/listitems/bycustomer?loginPhone=' +
      phone + '&redeemedStatus=' + status + '&page=' + page + '&pageSize=' + PageSize.REDEEM_LISTS

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getRedeemList(redeemType: any, redeemStatus: any, startDate: any, endDate: any, page: any): Observable<any> {

    var url = 'api/redeemed/listitems?redeemedStatus=' + redeemStatus + '&redeemedItemType=' + redeemType +
      '&page=' + page + '&pageSize=' + PageSize.REDEEM_LISTS

    if (startDate && endDate) {
      url = 'api/redeemed/listitems?redeemedStatus=' + redeemStatus + '&redeemedItemType=' + redeemType +
        '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + PageSize.REDEEM_LISTS
    }

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  RedeemedItemByStatusServiceSummaryWithDateFilter(redeemType: any, startDate: any, endDate: any, page: any): Observable<any> {

    var url = 'api/redeemed/summary?redeemedItemType=' + redeemType + '&page=' + page + '&pageSize=' + PageSize.REDEEM_LISTS

    if (startDate && endDate) {
      url = 'api/redeemed/summary?redeemedItemType=' + redeemType +
        '&startDate=' + startDate + '&endDate=' + endDate + '&page=' + page + '&pageSize=' + PageSize.REDEEM_LISTS
    }

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getRetryRedeemService(id: any): Observable<any> {

    const url = 'api/redeemed/item/retry/autopayment?id=' + id

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getRetryKBZRedeemService(id: string) {
    
    const url = 'api/redeemed/kbzpaypayment/retry?redeemId=' + id

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getBlockBORedeemService(id: string, reason: any) {
    
    const url = 'api/redeemed/billtopup/block?redeemId=' + id + '&reason=' + reason

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getBlockKBZRedeemService(id: string, reason: any) {
   
    const url = 'api/redeemed/kbzpaypayment/block?redeemId=' + id + '&reason=' + reason

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getQueryRedeemService(id: string) {
   
    const url = 'api/redeemed/kbzpaypayment/queryorder?redeemId=' + id

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getSignRedeemService(id: any): Observable<any> {

    const url = 'api/redeemed/item/processed?signedBy=' + this.store.getLoginName() + '&id=' + id

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getDownloadCSV(redeemType: any, redeemStatus: any, downloadFileName: any, startDate: any, endDate: any): Observable<any> {

    var url = 'api/redeemed/listitems/downloadcsv?fileName=' + downloadFileName + '&redeemedStatus=' + redeemStatus + '&redeemedItemType=' + redeemType

    if (startDate && endDate) {
      url = 'api/redeemed/listitems/downloadcsv?fileName=' + downloadFileName + '&redeemedStatus=' + redeemStatus + '&redeemedItemType=' + redeemType +
        '&startDate=' + startDate + '&endDate=' + endDate
    }

    return this.api.getApiCall(url, ResponseTypeCheck.TEXT, ResponseTypeCheck.CONT_JSON)

  }

}

