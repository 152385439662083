import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { LoginApiService } from 'src/app/service/login/login-api.service';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { RouteLinksByRolesService } from 'src/app/utils/route_links/route-links-by-roles.service';
declare var $: any

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {

  isUploading: any;
  public links: any
  apptitle!: string;
  login_user: any;
  isLoggedIn: any;
  changePasswordForm: FormGroup<any>;
  showNewPassword: any = false
  showConfirmPassword: boolean = false
  isError: any = false
  errorMessage: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api: LoginApiService,
    private dataStore: DataStoreService,
    private route_links: RouteLinksByRolesService
  ) {
    this.changePasswordForm = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    })
  }

  ngOnInit(): void {
    if (this.dataStore.getRole() === UserLoginRole.SUPER_ADMIN) {
      this.links = this.route_links.super_links
    } else if (this.dataStore.getRole() === UserLoginRole.ADMIN) {
      this.links = this.route_links.admin_links
    } else if (this.dataStore.getRole() === UserLoginRole.LUCKY_DRAW_ADMIN) {
      this.links = this.route_links.lucky_links
    } else if (this.dataStore.getRole() === UserLoginRole.REDEEM_PAYMENT_ADMIN) {
      this.links = this.route_links.redeem_links
    } else if (this.dataStore.getRole() === UserLoginRole.AUDIT_ADMIN) {
      this.links = this.route_links.audit_links
    }
    this.login_user = this.dataStore.getLoginName()
  }

  submitAdminLogout() {

    this.isUploading = true

    this.api.getLogout().subscribe({
      next: () => {
        sessionStorage.clear()
      },
      error: (e) => {
        this.isUploading = false
        alert(e)
      },
      complete: () => {
        this.isUploading = false
        this.router.navigate(['/login'])
          .then(() => {
            window.location.reload();
          });
      }
    })
  }

  submitChangePassword() {
    const newPass = this.changePasswordForm.get('newPassword')?.value
    const confirmPass = this.changePasswordForm.get('confirmPassword')?.value
    if (newPass !== confirmPass) {
      this.isError = true
      this.errorMessage = 'Password does not match'
    } else {
      this.isError = false
      this.updatePassword(confirmPass)
    }
  }

  updatePassword(newPassword: any) {
    this.isUploading = true
    this, this.api.getUpdatePassword(this.login_user, newPassword).subscribe({
      next: (value) => {
        var authorization = 'Basic ' + window.btoa(this.login_user + ':' + newPassword);
        this.dataStore.setAuth(authorization)

        alert("Success")
      },
      error: (e) => {
        if (e) {
          this.isError = true
          this.errorMessage = e
        }
        this.isUploading = false
      },
      complete: () => {
        this.isUploading = false
        $('#changePasswordModal').modal('hide');
        this.isError = false
        this.errorMessage = ''
      },
    })
  }


}
