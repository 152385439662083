<div class="container-fluid">
  <div class="d-flex flex-wrap bd-highlight align-items-center mt-2 gap-3">
    <div>
      <button *ngIf="isCustomerPage" type="button" class="btn btn-primary" (click)="goBack()">
        Back
      </button>
    </div>

    <div *ngIf="!isCustomerPage">
      <div class="h6">
        Search user exchange items
      </div>
      <div>
        <div class="form-check" *ngFor="let option of redeemTypesOption">
          <input class="form-check-input" type="radio" name="redeem" [id]="option.id" [(ngModel)]="redeemType"
            [value]="option.value" (change)="onChange($event)">
          <label class="form-check-label" [for]="option.id">
            {{ option.label }}
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="!isCustomerPage">
      <div class="fs-6">
        Choose a redeem status
      </div>
      <div class="mt-1">
        <select name="checkType" [(ngModel)]="redeemStatus" class="form-control">
          <!-- <option value="" disabled>Choose a redeem status</option> -->
          <option [value]="option" *ngFor="let option of redeemStatusOption">
            {{ option }}
          </option>
        </select>
      </div>
    </div>

    <div class="d-flex flex-wrap bd-highlight gap-3 align-items-end " *ngIf="!isCustomerPage">
      <div class="bd-highlight">
        Start Date
        <input type="date" [(ngModel)]="startDate" class="form-control" />
      </div>

      <div class="bd-highlight" *ngIf="!isCustomerPage">
        End Date
        <input type="date" [(ngModel)]="endDate" class="form-control" />
      </div>

      <div class="bd-highlight">
        Date Type
        <select class="form-select form-control" id="date_type" name="date_type" [(ngModel)]="dateType">
          <option value="REDEEMED_TS">REDEEMED_TS</option>
          <option value="PROCESSED_TS">PROCESSED_TS</option>
        </select>
      </div>

      <div class="mt-1" *ngIf="!isCustomerPage">
        <button type="submit" class="btn btn-info" (click)="searchByCustomer()">
          Check
        </button>
        <a class="btn btn-warning ms-2" (click)="resetForm()">Clear</a>
      </div>
    </div>

    <div class="ms-auto me-2" *ngIf="!isCustomerPage">
      <div *ngIf="bolDownloadForRedeem">

        <div>
          <button type="button" class="btn btn-primary" data-bs-toggle="dropdown" aria-expanded="false">
            Download
          </button>
          <ul class="dropdown-menu">
            <li>
              <button type="button" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#downloadCSVModal">
              Request
            </button>
          </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li><button class="dropdown-item" type="button" (click)="goToDownloadListPage()">List</button></li>
          </ul>
        </div>

      </div>
    </div>

  </div>

  <hr>

  <div class="me-2 mb-2">
    <div>
      <div class="d-flex justify-content-between flex-wrap bd-highlight gap-3" *ngIf="bolSummay">
        <div class="card bg-primary text-white rounded-4 flex-fill">
          <div class="card-body m-2">
            <div class="fs-6">
              TotalRedeemedAmount
            </div>
            <div class="mt-2 fs-5 fw-bolder">
              {{ totalRedeemedAmount}} MMK
            </div>
          </div>
        </div>

        <div class="card bg-primary text-white rounded-4 flex-fill">
          <div class="card-body m-2">
            <div class="fs-6">
              Pending Redeem Amount
            </div>
            <div class="mt-2 fs-5 fw-bolder">
              {{ totalPendingRedeemedAmount}} MMK
            </div>
          </div>
        </div>

        <div class="card bg-primary text-white rounded-4 flex-fill">
          <div class="card-body m-2">
            <div class="fs-6">
              Success Amount
            </div>
            <div class="mt-2 fs-5 fw-bolder">
              {{ totalProcessedRedeemedAmount}} MMK
            </div>
          </div>
        </div>

        <div class="card border-2 border-primary text-primary rounded-4 flex-fill" *ngIf="bolWKBZ">
          <div class="card-body m-2">
            <div class="fs-6">
              Wave
            </div>
            <div class="mt-2 fs-5 fw-bolder">
              {{ totalPaymentNameGroupWaveMon || 0}} MMK
            </div>
          </div>
        </div>

        <div class="card border-2 border-primary text-primary rounded-4 flex-fill" *ngIf="bolWKBZ">
          <div class="card-body m-2">
            <div class="fs-6">
              KBZPay
            </div>
            <div class="mt-2 fs-5 fw-bolder">
              {{ totalPaymentNameGroupKBZPay || 0}} MMK
            </div>
          </div>
        </div>

        <div class="card border-2 border-primary text-primary rounded-4 flex-fill" *ngIf="bolWKBZ">
          <div class="card-body m-2">
            <div class="fs-6">
              AYA Pay
            </div>
            <div class="mt-2 fs-5 fw-bolder">
              {{ totalPaymentNameGroupAYA || 0}} MMK
            </div>
          </div>
        </div>

        <div class="card border-2 border-primary text-primary rounded-4 flex-fill" *ngIf="bolPBTU">
          <div class="card-body m-2">
            <div class="fs-6">
              PHONE_BILL_TOPUP
            </div>
            <div class="mt-2 fs-5 fw-bolder">
              {{ totalPaymentNameGroupPBTU.toLocaleString()}} MMK
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="table-responsive me-2">
    <table class="table table-hover table-sm align-middle">
      <thead>
        <tr>
          <th>Sr No</th>
          <th>ID</th>
          <th>customer login Phone</th>
          <th>payment name</th>
          <th>payment phone</th>
          <th>redeem item Name</th>
          <th>redeem item Type</th>
          <th>redeemed TS</th>
          <th>redeem Status</th>
          <th>processed By</th>
          <th>processed TS</th>
          <th>signed By</th>
        </tr>
      </thead>
      <tbody>
        @if (results) {
        <tr *ngFor="let data of results | paginate : {
            itemsPerPage: pageSize,
            currentPage: page + 1,
            totalItems: allTotal } , index as i ">
          <td>{{ data.srNo }}</td>
          <!-- <td>{{ data.id }}</td> -->
          <td><a class="link-primary" (click)="goToTransactionList(data)" style="cursor: pointer;">{{data.id}}</a></td>
          <td>{{ data.customerLoginPhone }}</td>
          <td>{{ data.paymentName }}</td>
          <td>{{ data.paymentPhone }}</td>
          <td>{{ data.redeemableItemName }}</td>
          <td>{{ data.redeemableItemType }}</td>
          <td>{{ data.redeemedTS | dateFormat }}</td>
          <td>
            <div>
              {{ data.redeemableStatus }}
            </div>
            <div>
              <ng-container *ngIf="showRetryButton">
                <ng-container *ngIf="shouldRenderRetryButton(data)">
                  <button class="btn btn-info btn-sm" title="Retry" (click)="retryBORedeem(data.id, i)">
                    <span *ngIf="isRetryBtnLoading[i]" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span *ngIf="!isRetryBtnLoading[i]"><i class="bi bi-check"></i></span>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="showRetryButton">
                <ng-container *ngIf="shouldRenderAdminRetryButton(data)">
                  <button class="btn btn-info btn-sm" title="Retry" (click)="retryRedeem(data, i)">
                    <span *ngIf="isRetryBtnLoading[i]" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span *ngIf="!isRetryBtnLoading[i]"><i class="bi bi-check"></i></span>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="showBlockButton">
                <ng-container *ngIf="shouldRenderAdminBlockButton(data)">
                  <button class="btn btn-info btn-sm ms-2" title="Block" (click)="blockRedeem(data, i)">
                    <span *ngIf="isBlockBtnLoading[i]" class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                    <span *ngIf="!isBlockBtnLoading[i]"><i class="bi bi-ban"></i></span>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="shouldRenderQueryButton(data)">
                <button class="btn btn-info btn-sm" title="Retry" (click)="queryBORedeem(data.id, i)">
                  <span *ngIf="isRetryBtnLoading[i]" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
                  <span *ngIf="!isRetryBtnLoading[i]"><i class="bi bi-eye"></i></span>
                </button>
              </ng-container>
            </div>
          </td>
          <td>{{ data.processedBy }}</td>
          <td>
            {{ data.processedTS | dateFormat }}
          </td>
          <td>
            {{ data.signedBy }}
            <button class="btn btn-info btn-sm" title="Sign" (click)="signItem(data.id,i)" *ngIf="data.redeemableItemType === 'WALLET_CASH_TRANSFER' && 
              data.redeemableStatus === 'CUSTOMER_REDEEM' && isSignItemAccess">
              <span *ngIf="isSignBtnLoading[i]" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              <span *ngIf="!isSignBtnLoading[i]"><i class="bi bi-check"></i></span>
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="12">Total {{ total }}</td>
        </tr>
        }
      </tbody>
    </table>
  </div>

  <footer>
    <div class="text-center">
      <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
        (pageChange)="handlePageChange($event)"></pagination-controls>
    </div>
  </footer>

  <div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
    *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</div>

<!-- Download CSV Model -->
<div class="modal" id="downloadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Redeem Download Request CSV File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Enter file name" [(ngModel)]="downloadFileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="downloadRequestFile()"
          [disabled]="!downloadFileName || apiDownloadLoading">
          <span *ngIf="apiDownloadLoading" class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
          <span *ngIf="!apiDownloadLoading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>