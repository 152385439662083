import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageSize } from 'src/app/enumeration/pageSize';
import { RedeemApiService } from 'src/app/service/redeem/redeem-api.service';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';
import { DownloadService } from 'src/app/utils/download/download.service';

@Component({
  selector: 'app-redeem-download-list',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DateFormatPipe
  ],
  templateUrl: './redeem-download-list.component.html',
  styleUrl: './redeem-download-list.component.css'
})
export class RedeemDownloadListComponent {

  dataList: any[] = []
  pageSize: any = PageSize.REDEEM_LISTS
  page: any = 0
  allTotal: any = 0
  loading: any = false
  isDownloadBtnLoading: boolean[] = []

  constructor(
    private api_service: RedeemApiService,
    private downLoad_service: DownloadService,
  ) {

  }

  ngOnInit() {
    this.getAllList()
  }

  getAllList() {
    this.loading = true
    this.api_service.getDownloadCSVList(this.page).subscribe({
      next: (data) => {
        this.dataList = data.downloadRedeemRespLst;
        this.allTotal = data.allTotal;
      },
      error: (error) => {
        this.loading = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.loading = false
      },
    });
  }

  downloadFile(fileName: any, index : any) {

    this.isDownloadBtnLoading[index] = true
    this.api_service.getDownloadFile(fileName).subscribe({
      next: (data) => {
        this.downLoad_service.downloadCsvWithModifiedTime(data, fileName)
      },
      error: (error) => {
        this.isDownloadBtnLoading[index] = false
        if (error) {
          alert(error)
        }
      },
      complete: () => {
        this.isDownloadBtnLoading[index] = false
      },
    });
  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.getAllList()
    }
  }

  goBack() {
    window.history.back();
  }

}
