<div class="d-flex flex-wrap bd-highlight gap-3 mt-2">

  <div>
    <button class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#uploadCSVModal">
      <span class="d-none d-sm-inline">Upload </span>
      <span class="d-md-none d-lg-inline" title="Upload"><i class="fa-solid fa-cloud-arrow-up"
          style="color: #fafafa;"></i></span>
    </button>
  </div>
  <div>
    <button class="btn btn-primary " data-bs-toggle="modal" data-bs-target="#createAdminModal">
      <span class="d-none d-sm-inline">New </span>
      <span class="d-md-none d-lg-inline" title="New"><i class="fa-solid fa-plus" style="color: #fafafa;"></i></span>
    </button>
  </div>
  <div>
    <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#downloadCSVModal">
      <span class="d-none d-sm-inline">Download </span>
      <span class="d-md-none d-lg-inline" title="Download"><i class="fa-regular fa-circle-down"></i></span>
    </button>
  </div>

</div>

<div class="table-responsive mt-2 me-3">
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Sr No</th>
        <th>Login name</th>
        <th>Role</th>
        <th>Name</th>
        <th>Title</th>
        <th>Department</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Enable</th>
        <th>Total: {{ total }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let admin of admins">
        <td>{{ admin.srNo }}</td>
        <td>{{ admin.adminLoginName }}</td>
        <td>{{ admin.adminRole }}</td>
        <td>{{ admin.name | titlecase }}</td>
        <td>{{ admin.title }}</td>
        <td>{{ admin.department }}</td>
        <td>{{ admin.phone }}</td>
        <td>{{ admin.email }}</td>
        <td>
          <i class="bi bi-check-circle-fill" style="color: green" *ngIf="admin.enabled == true"></i>
          <i class="bi bi-x-circle-fill" style="color: red" *ngIf="admin.enabled == false"></i>
        </td>
        <td>
          <a class="btn btn-info btn-sm" title="Edit" data-bs-toggle="modal" data-bs-target="#adminUpdate"
            (click)="AdminSingleView(admin.adminLoginName,admin)"><i class="bi bi-pencil"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
  *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<!-- Create Admin Modal -->
<div class="modal" id="createAdminModal" tabindex="-1" aria-labelledby="createAdminModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="createAdminModalLabel">Create Admin Login User</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup]="adminLoginForm" *ngIf="isCreateAdmin">
          <div class="mb-3">
            <label class="form-label">Login Name</label>
            <input type="text" class="form-control" formControlName="username" placeholder="Set username min length 5">
          </div>
          <div class="mb-3">
            <label class="form-label">Role</label>
            <select formControlName="role" class="form-control">
              <option value="" disabled selected>Choose admin role</option>
              <option [value]="option" *ngFor="let option of roleOptions">
                {{ option }}
              </option>
            </select>
          </div>
        </form>
        <form [formGroup]="registerAdminForm" *ngIf="!isCreateAdmin">
          <div class="mb-3">
            <label class="form-label">Name</label>
            <input type="text" class="form-control" formControlName="name" placeholder="Set admin name">
          </div>
          <div class="mb-3">
            <label class="form-label">Title</label>
            <input type="text" class="form-control" formControlName="title" placeholder="Set admin title">
          </div>
          <div class="mb-3">
            <label class="form-label">Department</label>
            <input type="text" class="form-control" formControlName="department" placeholder="Set admin department">
          </div>
          <div class="mb-3">
            <label class="form-label">Phone</label>
            <input type="tel" class="form-control" formControlName="phone" placeholder="959">
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input type="email" class="form-control" formControlName="email" placeholder="a@gmail.com">
          </div>
        </form>
        <div *ngIf="isError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="createAdmin()"
          [disabled]="!adminLoginForm.valid || apiLoading" *ngIf="isCreateAdmin">
          <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!apiLoading">Next</span>
        </button>
        <button type="button" class="btn btn-primary" (click)="registerAdmin()" [disabled]="!registerAdminForm.valid"
          *ngIf="!isCreateAdmin">
          <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!apiLoading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Admin Update Modal -->
<div class="modal fade" id="adminUpdate" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <form [formGroup]="adminDataForm">
          <div class="row g-3 mt-2">
            <div class="col">Name</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="name" />
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="col">Title</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="title" />
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="col">Department</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="department" />
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="col">Phone</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="phone" />
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="col">Email</div>
            <div class="col">
              <input type="text" class="form-control" formControlName="email" />
            </div>
          </div>
          <div class="row g-3 mt-2">
            <div class="col"></div>
            <div class="col">
              <button type="button" class="btn btn-primary" [disabled]="!adminDataForm.valid || apiLoading"
                (click)="UpdateAdminData()">
                <!-- <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status"
                  aria-hidden="true"></span> -->
                <span>Submit</span>
              </button>
            </div>
          </div>
        </form>
        <div class="mt-2" [innerHTML]="htmlstring"></div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-info" [disabled]="apiLoading" (click)="ResetAdminPassword()">
          <!-- <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
          <span>Reset password</span>
        </button>
        <button class="btn btn-success" [disabled]="apiLoading" (click)="AdminLoginEnable()" *ngIf="!login_enable">
          <!-- <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
          <span>Login enable</span>
        </button>
        <button class="btn btn-danger" [disabled]="apiLoading" (click)="AdminLoginDisable()" *ngIf="login_enable">
          <!-- <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
          <span>Login disable</span>
        </button>
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Upload CSV Model -->
<div class="modal" id="uploadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Admin Upload CSV File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label class="form-label">Select File</label>
          <input type="file" class="file-input form-control" enctype="multipart/form-data" accept=".csv"
            (change)="onFileSelected($event)">
        </div>
        <div *ngIf="isUploadError">
          <div class="alert alert-danger" role="alert">{{ errorMessage }}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="uploadFile()">
          <span *ngIf="apiLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!apiLoading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Download CSV Model -->
<div class="modal" id="downloadCSVModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Admin Download CSV File</h5>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <input type="text" class="form-control" placeholder="Enter file name" [(ngModel)]="downloadFileName">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" (click)="downloadFile()"
          [disabled]="!downloadFileName || isDownloading">
          <span *ngIf="isDownloading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span *ngIf="!isDownloading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</div>
