import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class RouteGuardService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // You can add additional logic here if needed

    // Redirect to the default route (e.g., '/nav/home')
    this.router.navigate(['/nav/home']);
    return false;
  }
}
