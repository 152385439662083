<div class="container-fluid">

  <header class="d-flex align-items-end">
    <nav class="mt-2 me-2">
      <ul class="nav gap-3">
        <li>
          <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" class="btn-check" name="btnradio" id="btnradiokbz" value="{{BPTypeKBZ}}"
              autocomplete="off" (change)="onRadioChange($event)" checked>
            <label class="btn btn-outline-primary" for="btnradiokbz">KBZ</label>

            <input type="radio" class="btn-check" name="btnradio" id="btnradiobo" value="{{BPTypeBlueOcean}}"
              autocomplete="off" (change)="onRadioChange($event)">
            <label class="btn btn-outline-primary" for="btnradiobo">Blue Ocean</label>
          </div>
        </li>
      </ul>
    </nav>
  </header>

  <div class="table-responsive mt-2 me-2">
    <table class="table table-hover table-sm">
      <thead>
        <tr>
          <th>Sr No</th>
          <th>Payment Phone</th>
          <th *ngIf="type === BPTypeKBZ">Payment Name</th>
          <th>Login Phone</th>
          <th>Reason</th>
          <th>Block</th>
          <th class="custom-width"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of results | paginate : {
                itemsPerPage: pageSize,
                currentPage: page + 1,
                totalItems: allTotal } , index as i">
          <td>{{ data.srNo }}</td>
          <td *ngIf="type === BPTypeKBZ">{{ data.paymentPhone }}</td>
          <td *ngIf="type === BPTypeKBZ">{{ data.paymentName }}</td>
          <td *ngIf="type === BPTypeBlueOcean">{{ data.billTopUPhone }}</td>
          <td>{{ data.loginPhone }}</td>
          <td>{{ data.reason }}</td>
          <td>{{ data.isBlock }}</td>
          <td>
            <button class="btn btn-info btn-sm" title="Unblock" (click)="unBlockPhone(data,i)"
              *ngIf="data.isBlock === 'TRUE'">
              <span *ngIf="isUnBlockBtnLoading[i]" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              <span *ngIf="!isUnBlockBtnLoading[i]"><i class="bi bi-check-circle"></i></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div style="text-align: center">
    <pagination-controls [maxSize]="15" previousLabel="Prev" nextLabel="Next" [responsive]="true"
      (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>

  <div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
    *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

</div>