<div class="mt-2">
  <a class="btn btn-primary" routerLink="/nav/customer-list">Back</a>
</div>

<div class="table-responsive mt-2 me-3">
  <table class="table table-sm">
    <thead>
      <tr>
        <th>Sr No</th>
        <th>Phone</th>
        <th>ProfileName</th>
        <th>ProfilePhoto</th>
        <th>NRCFrontPhoto</th>
        <th>NRCBackPhoto</th>
        <th>NRCNumber</th>
        <th>unverifiedBy</th>
        <th class="w-25">UnverifiedNote</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of unverifiedList| paginate: {
           itemsPerPage: pageSize ,
           currentPage: page + 1 ,
           totalItems: allTotal }">
        <td>{{ user.srNo }}</td>
        <td>{{ user.loginPhone }}</td>
        <td>
          <i class="bi bi-x-lg" style="color: red" *ngIf="user.profileName == false"></i>
          <i class="bi bi-check-lg" style="color: blue" *ngIf="user.profileName == true"></i>
        </td>
        <td>
          <i class="bi bi-x-lg" style="color: red" *ngIf="user.profilePhoto == false"></i>
          <i class="bi bi-check-lg" style="color: blue" *ngIf="user.profilePhoto == true"></i>
        </td>
        <td>
          <i class="bi bi-x-lg" style="color: red" *ngIf="user.nrcFrontPhoto == false"></i>
          <i class="bi bi-check-lg" style="color: blue" *ngIf="user.nrcFrontPhoto == true"></i>
        </td>
        <td>
          <i class="bi bi-x-lg" style="color: red" *ngIf="user.nrcBackPhoto == false"></i>
          <i class="bi bi-check-lg" style="color: blue" *ngIf="user.nrcBackPhoto == true"></i>
        </td>
        <td>
          <i class="bi bi-x-lg" style="color: red" *ngIf="user.nrcNumber == false"></i>
          <i class="bi bi-check-lg" style="color: blue" *ngIf="user.nrcNumber == true"></i>
        </td>
        <td>{{user.unverifiedBy}}</td>
        <td>{{ user.unverifiedNote }}</td>
        <td>
          <a [routerLink]="['/nav/customer-info', user.loginPhone]" class="btn btn-info btn-sm" title="Profile">
            Profile
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div style="text-align: center">
  <pagination-controls [maxSize]="20" previousLabel="Prev" nextLabel="Next" [responsive]="true"
    (pageChange)="handlePageChange($event)"></pagination-controls>
</div>

<div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
  *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
