import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core'; // For wrapping module imports
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StoreModule } from '@ngrx/store';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app/app.component';
import { routes } from './app/app-routing.module';
import { authInterceptorProviders } from './app/utils/auth.interceptor';
import { reducers } from './app/utils/reducer';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      StoreModule.forRoot(reducers),
    ),
    provideRouter(routes), // Provides routing setup
    provideHttpClient(withInterceptorsFromDi()), // Enables HttpClient with interceptors
    authInterceptorProviders, // Custom interceptor provider
    DatePipe, // Needed if directly injecting DatePipe
  ],
}).catch(err => console.error(err));
