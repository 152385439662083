import { ActionReducerMap } from '@ngrx/store';
import { RedeemState, redeemReducer } from 'src/app/reducer/redeem.reducer';
import { CustomerState, customerReducer } from '../reducer/customer.reducer';
import { TransactionState, transactionReducer } from '../reducer/transaction.reducer';

export interface AppState {
  redeem: RedeemState; // 'redeem' is the feature key
  customer: CustomerState;
  transaction: TransactionState
}

export const reducers: ActionReducerMap<AppState> = {
  redeem: redeemReducer,
  customer: customerReducer,
  transaction: transactionReducer
  // Add other reducers here if you have them
};
