import { QrCodeDetailsComponent } from './component/qr/qr-code-details/qr-code-details.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './component/nav/nav.component';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { AdminListComponent } from './component/admin/admin-list/admin-list.component';
import { QrCodeListComponent } from './component/qr/qr-code-list/qr-code-list.component';
import { LuckyDrawListComponent } from './component/luckydraw/lucky-draw-list/lucky-draw-list.component';
import { CustomerRedeemListComponent } from './component/redeem/customer-redeem-list/customer-redeem-list.component';
import { CustomerListComponent } from './component/customer/customer-list/customer-list.component';
import { DatePipe } from '@angular/common';
import { QrHistoryComponent } from './component/qr/qr-history/qr-history.component';
import { QrIllegalclaimComponent } from './component/qr/qr-illegalclaim/qr-illegalclaim.component';
import { RedeemItemsListComponent } from './component/redeem/redeem-items-list/redeem-items-list.component';
import { CustomerInfoComponent } from './component/customer/customer-info/customer-info.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomerUnverifyListComponent } from './component/customer/customer-unverify-list/customer-unverify-list.component';
import { RedeemTransactionListComponent } from './component/redeem/redeem-transaction-list/redeem-transaction-list.component';
import { authInterceptorProviders } from './utils/auth.interceptor'
import { QrUploadComponent } from './component/qr/qr-upload/qr-upload.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from 'src/app/utils/reducer';
import { ShareModule } from './utils/share_module/share-module/share-module.module';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LoginComponent,
    HomeComponent,
    AdminListComponent,
    QrCodeListComponent,
    LuckyDrawListComponent,
    CustomerRedeemListComponent,
    CustomerListComponent,
    QrHistoryComponent,
    QrIllegalclaimComponent,
    QrCodeDetailsComponent,
    RedeemItemsListComponent,
    CustomerRedeemListComponent,
    CustomerListComponent,
    CustomerInfoComponent,
    CustomerUnverifyListComponent,
    RedeemTransactionListComponent,
    QrUploadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPaginationModule,
    DragDropModule,
    StoreModule.forRoot(reducers),
    ShareModule
  ],
  providers: [DatePipe,provideHttpClient(withInterceptorsFromDi()), authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
