import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageSize } from 'src/app/enumeration/pageSize';
import { ResponseTypeCheck } from 'src/app/enumeration/response-type';
import { DataStoreService } from 'src/app/utils/data_store/data-store.service';
import { ApiCallServiceService } from '../api_call_service/api-call-service.service';
import { BlockPhoneType } from 'src/app/enumeration/block-phone-type';

@Injectable({
  providedIn: 'root'
})
export class BlockPhoneApiService {

  constructor(
    private http: HttpClient,
    private store: DataStoreService,
    private api: ApiCallServiceService
  ) { }

  getBlockPhoneLists(type: any, page: any) {

    let url = 'api/redeemed/kbzpaypayment/block/list' + '?page=' + page + '&pageSize=' + PageSize.BLOCKPHONE_LIST

    if (type === BlockPhoneType.BLUE_OCEAN) {
      url = 'api/redeemed/billtopup/block/list' + '?page=' + page + '&pageSize=' + PageSize.BLOCKPHONE_LIST
    }

    return this.api.getApiCall(url, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

  getunBlockPhoneKBZService(phone: any, reason: any) {

    const url = 'api/redeemed/kbzpaypayment/unblock?blockPhone=' + phone + '&reason=' + reason

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }
  getUnBlockPhoneBOService(phone: any, reason: any) {

    const url = 'api/redeemed/billtopup/unblock?blockPhone=' + phone + '&reason=' + reason

    return this.api.postApiCall(url, null, ResponseTypeCheck.JSON, ResponseTypeCheck.CONT_JSON)

  }

}
