import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DataStoreService } from './utils/data_store/data-store.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterModule,
  ],
  providers: [DataStoreService],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'upg-rewards-admin-r2';

  constructor(
    private router: Router,
    private store: DataStoreService) {
  }

  ngOnInit(): void {
    console.log('Auth:', this.store.getAuth()); // Log auth token to check its value
    if (!this.store.getAuth()) {
      console.log('No auth, redirecting to login');
      this.router.navigate(['/login']);
    } else {
      console.log('Auth exists, redirecting to nav');
      this.router.navigate(['/nav']);
    }
  }
  
}
