import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { PageSize } from 'src/app/enumeration/pageSize';
import { UserLoginRole } from 'src/app/enumeration/roles';
import { QrApiService } from 'src/app/service/qr/qr-api.service';
import { CheckRolesAccessService } from 'src/app/utils/check_roles_access/check-roles-access.service';
import { DateFormatPipe } from 'src/app/utils/date-format.pipe';
import { DownloadService } from 'src/app/utils/download/download.service';
declare var $: any

@Component({
  selector: 'app-qr-code-list',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DateFormatPipe
  ],
  templateUrl: './qr-code-list.component.html',
  styleUrls: ['./qr-code-list.component.css']
})
export class QrCodeListComponent {

  fileName: any;
  checkSerial: any;
  checkPhone: any;
  qrcodes: any;
  pageSize: any = PageSize.QR_LIST;
  page: any = 0;
  allTotal: any = 0;
  loading: any;
  qrCodeDataFrom: FormGroup
  isApiError: any;
  errorMessage: any;
  apiLoading: any;
  downloadFileName: any;
  file!: File
  searchSerial: string = ''
  isUploadNewDownloadAccess: any = false
  isClaimRecordAccess: any = false
  isQREnableDisableAccess: any = false
  apiDownloading: any = false
  isEnableBtnLoading: boolean[] = []

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private api_service: QrApiService,
    private download: DownloadService,
    private checkAccessRoles: CheckRolesAccessService
  ) {
    this.qrCodeDataFrom = this.fb.group({
      serialNumber: ['', Validators.required],
      qrCode: ['', [
        Validators.required,
        Validators.minLength(13),
        Validators.pattern(/^\S*$/)
      ]],
      points: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]],
    })
  }

  ngOnInit(): void {
    this.isUploadNewDownloadAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN])
    this.isClaimRecordAccess = this.checkAccessRoles.check([UserLoginRole.SUPER_ADMIN, UserLoginRole.ADMIN])
    this.isQREnableDisableAccess = this.checkAccessRoles.check([UserLoginRole.LUCKY_DRAW_ADMIN])

    this.getQRCodeList()
  }

  textChange(value: any) {
    this.page = 0
    this.getQRCodeList()
  }

  searchQRListByNumber() {
    this.page = 0
    this.getQRCodeList()
  }

  clearSearchQRListByNumber() {
    this.searchSerial = ''
    this.page = 0
    this.getQRCodeList()
  }

  getQRCodeList() {
    this.loading = true
    this.api_service.getQRCodeList(this.searchSerial, this.page).subscribe({
      next: (v) => {
        this.qrcodes = v.qrCodeInfoLst;
        this.allTotal = v.allTotal;
      },
      error: (e) => {
        this.loading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.loading = false
      }
    })
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
    //this.fileName = this.fileName.name
  }

  uploadFile() {
    this.apiLoading = true
    this.api_service.getUploadCSVQRCode(this.file).subscribe({
      next: (value) => {
        alert("Success")
      },
      error: (e) => {
        this.isApiError = true
        this.errorMessage = e
        this.apiLoading = false
      },
      complete: () => {
        this.apiLoading = false
        $('#uploadCSVModal').modal('hide');
        this.getQRCodeList()
      },
    })
  }

  submitNewQrcode() {
    this.apiLoading = true
    this.api_service.getCreateQRCode(this.qrCodeDataFrom.value).subscribe({
      next: (value) => {
        alert("New QR code added")
      },
      error: (e) => {
        this.apiLoading = false
        if (e) {
          alert(e)
        }
      },
      complete: () => {
        this.apiLoading = false
        this.qrCodeDataFrom.reset()
        this.getQRCodeList()
      }

    })
  }

  checkQrClaimHistory() {
    this.router.navigate(['nav/qr-code-illegalclaim/', this.checkSerial]);
  }

  checkClaimPhoneHistory() {
    this.router.navigate(['nav/qr-code-history', this.checkPhone]);
  }

  qrCodeDetail(serialNumber: number) {
    this.router.navigate(['nav/qr-code-details', serialNumber]);
  }

  enableQrcode(serialNumber: number, type: string, index: any) {
    if (confirm(type)) {
      this.isEnableBtnLoading[index] = true
      this.api_service.getEnableQrcode(type, String(serialNumber))
        .subscribe({
          next: (value) => {
            alert("Success")
          },
          error: (e) => {
            this.isEnableBtnLoading[index] = false
            if (e) {
              alert(e)
            }
          },
          complete: () => {
            this.isEnableBtnLoading[index] = false
            this.getQRCodeList()
          }
        })
    } else {
      return
    }
  }

  handlePageChange(event: number) {
    if (!this.loading) {
      this.page = event - 1;
      this.getQRCodeList();
    }
  }

  downloadFile() {
    this.apiDownloading = true
    if (!this.downloadFileName.includes(".csv")) {
      this.downloadFileName = this.downloadFileName + ".csv"
    }
    this.api_service.getDownloadFile(this.downloadFileName).subscribe({
      next: (value) => {
        this.download.downloadFileInLocal(value, this.downloadFileName)
      },
      error: (e) => {
        this.apiDownloading = false
        if (e) {
          this.downloadFileName = ''
          alert(e)
        }
      },
      complete: () => {
        this.downloadFileName = ''
        this.apiDownloading = false
        $('#downloadCSVModal').modal('hide');
      },
    })
  }
}
