<div class="container-fluid">

  <header class="d-flex align-items-end">
    <nav class="mt-2 me-2">
      <ul class="nav gap-3">
        <li *ngIf="isAddNewUpdateAccess">
          <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addNew">Add new</button>
        </li>
        <li class="ms-2">
          <input type="radio" name="reply" checked (change)="listAll(0)" /> DEFAULT
          <br />
          <input type="radio" name="reply" (change)="ListAllEnabledRedeem()" />
          ENABLED
        </li>
      </ul>
    </nav>
  </header>

  <div class="table-responsive mt-2 me-2">
    <table class="table">
      <thead>
        <tr>
          <th>Sr No</th>
          <th>Name</th>
          <th>Points</th>
          <th>MMK value</th>
          <th>Service name</th>
          <th>Minutes value</th>
          <th>Redeemable type</th>
          <th>Enabled</th>
          <th>Total: {{ total }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of results | paginate : {
          itemsPerPage: pageSize,
          currentPage: page + 1,
          totalItems: allTotal }">
          <td>{{ data.srNo }}</td>
          <td>{{ data.name }}</td>
          <td>{{ data.points }}</td>
          <td>{{ data.mmkValue }}</td>
          <td>{{ data.serviceName }}</td>
          <td>{{ data.minutesValue }}</td>
          <td>{{ data.redeemableType }}</td>
          <td>
            <i class="bi bi-x-lg" style="color: red" *ngIf="data.enabled == false"></i>
            <i class="bi bi-check-lg" style="color: blue" *ngIf="data.enabled == true"></i>
          </td>
          <td class="text-end pe-3">
            <div *ngIf="isAddNewUpdateAccess">
              <button type="button" class="btn btn-info btn-sm me-1" data-bs-toggle="modal" data-bs-target="#updateData"
                (click)="GetSingleView(data.name)" *ngIf="!data.enabled">
                <i class="bi bi-pencil"></i></button>
              <button type="button" class="btn btn-info btn-sm" (click)="redeemEnableDisable('enabled', data.name)"
                *ngIf="!data.enabled">
                <i class="bi bi-check-lg"></i>
              </button>
              <button type="button" class="btn btn-info btn-sm" (click)="redeemEnableDisable('disabled', data.name)"
                *ngIf="data.enabled">
                <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div style="text-align: center">
    <pagination-controls [maxSize]="15" previousLabel="Prev" nextLabel="Next" [responsive]="true"
      (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>

  <div class="loading d-flex align-items-center justify-content-center position-fixed top-50 start-50 translate-middle"
    *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <div [innerHTML]="htmlstring"></div>

</div>

<!--Create New Modal -->
<div class="modal fade" id="addNew" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <form [formGroup]="newDataForm" (ngSubmit)="addNewRedeem()">
          <div class="col text-center">Add new</div>
          <p></p>
          <div class="row g-3">
            <div class="col">
              Name
              <span class="text-danger">* must be unique, cannot update after submit</span>
            </div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="name" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">Points</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="points" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">MMK value</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="mmkValue" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">Service name</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="serviceName" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">Minutes value</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="minutesValue" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">
              Redeemable type
              <span class="text-danger">* cannot update after submit</span>
            </div>
            <div class="col">
              <select formControlName="redeemableType" class="form-control">
                <option value="" disabled selected>Choose redeem type</option>
                <option [value]="option" *ngFor="let option of itemTypes">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col"></div>
            <div class="col">
              <button type="submit" class="btn btn-primary" [disabled]="!newDataForm.valid">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Update Modal -->
<div class="modal fade" id="updateData" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <form [formGroup]="updateDataForm" (ngSubmit)="updateRedeem()">
          <div class="row g-3">
            <div class="col">Name</div>
            <div class="col">{{ selectedName }}</div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">Points</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="points" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">MMK value</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="mmkValue" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">Service</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="serviceName" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col">Minute</div>
            <div class="col">
              <input type="text" class="form-control" placeholder="Enter value" formControlName="minutesValue" />
            </div>
          </div>
          <p></p>
          <div class="row g-3">
            <div class="col"></div>
            <div class="col">
              <button type="submit" class="btn btn-primary" [disabled]="!updateDataForm.valid">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>