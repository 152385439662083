import { HTTP_INTERCEPTORS, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ApiCallServiceService } from '../service/api_call_service/api-call-service.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  constructor(private router: Router,
    private api_service: ApiCallServiceService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = error.error.message;
        } else if (req.url.includes('/refreshtoken')) {
          alert("Your session has expired or another device has logged in with your account!")
          this.router.navigate(['/login']);
          sessionStorage.clear()

        } else if (req.url.includes('/login')) {
          if (error.error.Object) {
            if (error.error.Object.includes("Unauthorized")) {
              errorMessage = 'Your password is incorrect!'
            } else {
              errorMessage = error.error.Object
            }
          } else {
            errorMessage = error.error.Message
          }
        } else {
          // Server-side error
          if (error.status === 401 || error.status === 0) {
            //Call Refresh Api
            return this.handle401Error(req, next);
          } else {
            if (error.error.Message) {
              errorMessage = error.error.Message;
              console.log(`Error code: ${error.status}\n${error.error.Message}`);
            } else if (error.error.Object) {
              errorMessage = error.error.Object;
              console.log(`Error code: ${error.status}\n${error.error.Object}`);
            } else if (error.error.Status) {
              errorMessage = error.error.Status;
              console.log(`Error code: ${error.status}\n${error.error.Status}`);
            } else if (typeof error.error === 'string') {
              var jsonError = JSON.parse(error.error);
              errorMessage = jsonError.Object;
              console.log(`Error code: ${error.status}\n${jsonError.Object}`);
            } else if (error.error instanceof Blob) {
              errorMessage = "Data Not Found!!";
              console.log(`Error code: ${error.status}\n${error}`);
            } else {
              //errorMessage = 'Something went wrong!!'
              console.log("Something went wrong!!");     
            }
          }
        }

        return throwError(() => errorMessage);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      return this.api_service.refreshTokenApiCall().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          return next.handle(request);
        }),
        catchError((err) => {
          this.isRefreshing = false;

          return throwError(() => err);
        })
      );
    }

    return new Observable<HttpEvent<any>>();
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
